import request from '../utils/request';
//推荐师傅列表
export async function getMaster(state, info, skip, take) {
  return await request(`platbiz/workerpromote?state=${state}&info=${info}&skip=${skip}&take=${take}`, 'get');
}

//修改推荐状态
export async function editMaster(id, remark) {
  return await request(`platbiz/prostate?id=${id}&remark=${remark}`, 'put');
}
