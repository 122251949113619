import request from '../utils/request';
//师傅余额列表
export async function getWorkerflist({ info, skip, take }) {
  return await request(`platbiz/workerflist?info=${info}&skip=${skip}&take=${take}`, 'get');
}
//新增师傅提现申请
export async function postAddwidthdraw(worker, data) {
  return await request(`platbiz/addwidthdraw?worker=${worker}`, 'post', data);
}
//调整师傅余额
export async function putAdjworkerfin(worker, price, remark) {
  return await request(`platbiz/adjworkerfin?worker=${worker}&price=${price}&remark=${remark}`, 'put');
}
//师傅提现列表
export async function getWithdrawlist(info, staff, state, type, start, end, skip, take) {
  return await request(`platbiz/withdrawlist?info=${info}&staff=${staff}&state=${state}&type=${type}&start=${start}&end=${end}&skip=${skip}&take=${take}`, 'get');
}
//处理师傅提现
export async function putWithdraw(id, state, remark) {
  return await request(`platbiz/putwithdraw?id=${id}&state=${state}&remark=${remark}`, 'put');
}
//批量提现
export async function batchWithdraw(data) {
  return await request(`platbiz/batchwithdraw`, 'put', data);
}

//待收款订单列表
export async function getCollectlist({ info, order, start, end, skip, take }) {
  return await request(`platbiz/collectlist?info=${info}&order=${order}&start=${start}&end=${end}&skip=${skip}&take=${take}`, 'get');
}
//确认收款
export async function putCollect(orderid) {
  return await request(`platbiz/putcollect?orderid=${orderid}`, 'put');
}