import React, { Component } from 'react';
import { Modal, Upload, message, Input } from 'antd';
import { PlusOutlined, DeleteOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import styles from './index.module.css';
import { ossUpload, } from '../../../utils/ossUpload.js';
import { getDepartment_Data } from '../../../config/storage';
import { putBonusApply } from '../../../services/orderCore';


class BonusModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      orderInfo: {},
      pics: [],
      remark: '',
    };
    this.baseUrl = getDepartment_Data().service;
  }
  render() {
    const { visible, pics, remark } = this.state;
    return (
      <Modal
        title={'申请好评'}
        visible={visible}
        width={760}
        onCancel={this.onCancel}
        onOk={this.onOK}
        okText='确定'
        cancelText='取消'
      >
        <div className='col-t  vertical-lt'>
          <div className='row-l vertical-lt'>
            <div className='w90 tc margin-r10'>上传图片:</div>
            <div className='row-w w600'>
              {
                pics.map((item, index) => {
                  return (
                    <div key={index} className={`${styles['content']}`}>
                      <div className={`row ${styles['pic-icon']}`} onClick={this.delPic.bind(this, index)}><DeleteOutlined /></div>
                      <img
                        alt="暂无图片"
                        className={`${styles['pic']}`}
                        src={`${this.baseUrl}/upload/${item}`}
                      />
                      <div className={`row ${styles['content-div']}`}>
                        <EyeOutlined
                          className={`${styles['pic-icon-E']}`}
                          onClick={this.preview.bind(this, `${this.baseUrl}/upload/${item}`)}
                          theme="outlined"
                          title="预览"
                        />
                      </div>
                    </div>
                  );
                })
              }
              <Upload
                type='file'
                beforeUpload={this.onAddPic}
                fileList={[]}
                accept='image/*'
                multiple={true}
              >
                <div className={`row cursor-pointer ${styles['pic-div']}`}>
                  <div className="row h100">
                    <PlusOutlined />
                    <span>
                      新增图片
                    </span>
                  </div>
                </div>
              </Upload>
            </div>
          </div>
          <div className='row-l vertical-lt margin-t10'>
            <div className='w90 tc margin-r10'>好评说明:</div>
            <Input.TextArea value={remark} onChange={this.changeRemark} rows={3} className='w600' />
          </div>
        </div>
      </Modal>
    );
  }
  onAddPic = async (file, fileList) => {
    let pics = _.cloneDeep(this.state.pics)
    if (fileList[fileList.length - 1] !== file) {
      return;
    }
    let leng = pics.length;
    for (let i = 0; i < fileList.length; i++) {
      let name = await ossUpload(fileList[i], null);
      pics[leng + i] = name;
    }
    this.setState({
      pics
    })
  }
  delPic = (e) => {
    let pics = _.cloneDeep(this.state.pics)
    pics.splice(e, 1);
    this.setState({
      pics,
    });
  }
  changeRemark = (e) => {
    this.setState({
      remark: e.target.value
    });
  }
  preview = (url) => {
    let features = "height=500, width=800, top=100, left=100, toolbar=no, menubar=no,scrollbars=no,resizable=no, location=no, status=no";
    window.open(url, "newW", features);
  }
  onOK = async () => {
    const { orderInfo, pics, remark } = this.state;
    if (pics.length === 0) {
      message.warn('请上传图片');
      return;
    }
    if (remark === '') {
      message.warn('请填写好评说明');
      return;
    }
    let data = {
      pic: JSON.stringify(pics),
      remark: remark,
    };
    let res = await putBonusApply(orderInfo.id, data);
    if (res.status === 204) {
      message.success('申请成功')
      this.onCancel();
      this.props.update();
    }
  }
  onShow = (orderInfo) => {
    this.setState({
      visible: true,
      orderInfo,
    })
  }
  onCancel = () => {
    this.setState({
      visible: false,
      orderInfo: {},
      pics: [],
      remark: '',
    })
  }
}
export default BonusModal;