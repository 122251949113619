import React, { Component } from 'react';
import { Button, DatePicker, Input, message, Radio, Select, Table } from 'antd';
import { getReward, putReward } from '../../services/reward';
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment';

const dateFormat = 'YYYY-MM-DD';
moment.locale('zh-cn');

class Reward extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: {
        enable: '是',
        join_time: '2023-01-01',
        due_time: '2023-12-31',
      },
    }
  }
  componentDidMount() {
    this.getReward();
  }
  render() {
    const { current } = this.state;
    return (
      <div className='bar'>
        <div className='row-l margin-b10'>
          <div>是否启用：</div>
          <Radio.Group value={current.enable} onChange={this.changestate}>
            <Radio value={'是'}>是</Radio>
            <Radio value={'否'}>否</Radio>
          </Radio.Group>
        </div>
        <div className='row-l margin-b10'>
          <div>生效时间：</div>
          <DatePicker placeholder='生效时间' className='w198' value={moment(current.join_time, dateFormat)}
            onChange={this.onChangeDate.bind(this, 'join_time')} locale={locale} allowClear={false} />
        </div>
        <div className='row-l margin-b10'>
          <div>结束时间：</div>
          <DatePicker placeholder='生效时间' className='w198' value={moment(current.due_time, dateFormat)}
            onChange={this.onChangeDate.bind(this, 'due_time')} locale={locale} allowClear={false} />
        </div>
        <Button onClick={this.onOk} type='primary'>确认</Button>
      </div>
    );
  }
  onChangeDate = (type, e) => {
    let current = { ...this.state.current };
    current[type] = moment(e).format(dateFormat);
    this.setState({
      current
    });
  }
  changestate = (e) => {
    let current = { ...this.state.current };
    current.enable = e.target.value;
    this.setState({
      current
    });
  }
  getReward = async () => {
    let res = await getReward();
    this.setState({
      current: res.data,
    });
  }
  onOk = async () => {
    const { current } = this.state;
    let res = await putReward(current.enable, current.join_time, current.due_time);
    if (res.status === 204) {
      message.success('保存成功！');
    }
  }
}
export default Reward;