import { fetch } from 'whatwg-fetch';
import { message } from 'antd';
import { host } from '../config/baseurl';
import { getSession, removeSession, getUser, removeUser } from '../config/storage';
import getSearch from './getSearch';
import history from '../config/history';

/**
* 函数描述
* 
* @param {string} url 地址
* @param {string} method 方法
* @param {object} options body参数
* @return {Promise} 请求结果
*/
export default async function request(url, method, options, isVoid = false) {
  // let newUrl = getSearch(url);
  // url = newUrl ? newUrl : url;
  let opt = options || {};
  if (!url.startsWith('http')) {
    url = encodeURI(`${host}/${url}`);
  }
  // url = encodeURI(`${host}/${url}`);
  let Authorization = getSession();
  let user = getUser();
  try {
    method = method || 'get';
    let response = await fetch(url, {
      method: method,
      credentials: 'omit',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        // 'Content-Type': 'multipart/form-data',
        'DateTime': new Date().toUTCString(),
        'User': user,
        'Authorization': Authorization,
        // "Access-Control-Allow-Origin": "*"
      },
      body: method !== 'get' ? JSON.stringify(opt) : null
    });
    if (isVoid) {
      return;
    }
    let data = null;
    if (response.headers.map['content-type'] &&
      response.headers.map['content-type'].indexOf('application/json') > -1) {
      data = await response.json();
    }
    else {
      data = await response.text();
    }
    if (response.status === 401) {
      removeSession();
      removeUser();
      history.push('/login');
    }
    if (response.status >= 400 && data) {
      message.error(data);
    }
    response.data = data;
    return { ...response };
  }
  catch (e) {
    message.error('网络问题或服务器内部发生错误');
    return {
      status: 500,
      statusText: 'server error',
      data: '网络问题或服务器内部发生错误'
    };
  }
}
