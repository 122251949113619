import React, { useEffect, useState, Component, useRef } from 'react';
import styles from './modal.module.css';
import { Modal, Input, InputNumber, Radio, Image, Button, Select, Checkbox, Drawer, DatePicker, Table, } from 'antd';
import moment from 'moment';

const remarks = [
  '照片不按照顺序排列，无法区分房间名称',
  '数据潦草',
  '测量不拍照/漏拍',
  '数据过于简单，核对浪费大量时间精力',
  '测量水平不专业',
  '测量未落实签字政策',
];
const comefrom = [
  {
    value: false,
    key: '客服上传',
  },
  {
    value: true,
    key: '师傅自助上传',
  },
];
class MasterModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerShow: false,
      remark: '',
      isSelf: false,
      isasync: false,
      numbers: {
        dataNum: 0,
        cushionNum: 0,
      },
      dataRemark: '',
    };

  }
  render() {
    const { drawerShow, remark, isSelf, isasync, numbers, dataRemark, } = this.state;
    return (
      <Modal
        title='订单上传情况判断'
        visible={drawerShow}
        width={550}
        onCancel={this.hideDrawer}
        onOk={this.toUpload}
        okText='确认'
        cancelText='取消'
      >
        <div className='row-l margin-b10'>
          <div>有</div>
          <InputNumber value={numbers.dataNum} onChange={this.changeNum.bind(this, 'dataNum')} className='margin-lr5' />
          <div>个数据；</div>
          <div>有</div>
          <InputNumber value={numbers.cushionNum} onChange={this.changeNum.bind(this, 'cushionNum')} className='margin-lr5' />
          <div>个飘窗垫</div>

        </div>
        <div className='row-l margin-b10'>
          <div className='fz16'>当前订单数据来源：</div>
          <Radio.Group value={isSelf} onChange={this.changeSelf}>
            {
              comefrom.map((item, index) => {
                return (<Radio value={item.value} key={index}>{item.key}</Radio>);
              })
            }
          </Radio.Group>
        </div>
        <div className='row-l'>
          <div>是否投诉：</div>
          <Checkbox checked={isasync} onChange={this.changeAsync} />
        </div>
        {
          isasync &&
          <div className='col-t vertical-lt margin-t10'>
            <div className='row-l vertical-lt'>
              <div>投诉内容：</div>
              <Input.TextArea value={remark} onChange={this.changeRemark} className='w400' rows={4} />
            </div>
            <div className='row-w w500 margin-t10'>
              {
                remarks.map((item, index) => {
                  return (<div onClick={this.addRemark.bind(this, item)} className={styles['remark']} key={index}>{item}</div>);
                })
              }
            </div>
          </div>
        }
        <div className='row-l vertical-lt margin-t10'>
          <div>数据备注：</div>
          <Input.TextArea value={dataRemark} onChange={this.changeDataRe} className='w400' rows={4} />
        </div>
      </Modal >
    );
  }
  changeAsync = (e) => {
    this.setState({
      isasync: e.target.checked,
    });
  }
  changeSelf = (e) => {
    this.setState({
      isSelf: e.target.value,
    });
  }
  changeRemark = (e) => {
    this.setState({
      remark: e.target.value,
    });
  }
  addRemark = (e) => {
    let remark = this.state.remark;
    remark = remark ? remark + '；' + e : e;
    this.setState({
      remark
    });
  }
  changeNum = (type, e) => {
    let numbers = _.cloneDeep(this.state.numbers)
    numbers[type] = e ? e : 0;
    this.setState({
      numbers
    });
  }
  changeDataRe = (e) => {
    this.setState({
      dataRemark: e.target.value
    });
  }
  toUpload = () => {
    const { isSelf, isasync, remark, numbers, dataRemark } = this.state;
    let asyncRemark = isasync ? remark : '';
    let selfType = isSelf ? '同步' : '确定';
    let dataNUm = `有${numbers.dataNum}个数据；有${numbers.cushionNum}个飘窗垫`;
    this.props.upload(isSelf, selfType, isasync, asyncRemark, dataNUm, dataRemark);
    this.hideDrawer();
  }
  showDrawer = () => {
    this.setState({
      drawerShow: true,
    })
  }
  hideDrawer = () => {
    this.setState({
      drawerShow: false,
      remark: '',
      isSelf: false,
      isasync: false,
      dataRemark: '',
      numbers: {
        dataNum: 0,
        cushionNum: 0,
      },
    })
    // this.props
  }



}

export default MasterModal;