import request from '../utils/request';

//地址转经纬度
export async function toTitude(address) {
  return await request(`data/geo?address=${address}`, 'get');
}
//上传oss授权
export async function getMyOss(prefix) {
  return await request(`data/upload?prefix=${prefix}`, 'get');
}

//获取服务类别
export async function getService() {
  return await request(`open/catagory`, 'get');
}






