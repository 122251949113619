import request from '../utils/request';


export async function getMacList() {
  return await request(`data/maclist`, 'get');
}
export async function putMac(data) {
  return await request(`data/mac`, 'put', data);
}
export async function delMac(id) {
  return await request(`data/mac?id=${id}`, 'delete');
}
export async function getMacLog(id, start, end, skip, take) {
  return await request(`data/macloglist?id=${id}&start=${start}&end=${end}&skip=${skip}&take=${take}`, 'get');
}
export async function getMacLogs(user, start, end, skip, take) {
  return await request(`data/maclog?user=${user}&start=${start}&end=${end}&skip=${skip}&take=${take}`, 'get');
}



export async function getPermissInfo() {
  return await request(`open/macphone`, 'get');
}
export async function putPermissInfo(name, phone) {
  return await request(`data/macphone?name=${name}&phone=${phone}`, 'put');
}

export async function getSelfMac() {
  // return await request(`http://127.0.0.1:8192`, 'get');
  // return await request('http://192.168.1.77:8392', 'get');
  return await request('http://localhost:8392', 'get');
}
