import React, { useEffect, useState, Component } from 'react';
import styles from './index.module.css';
import Option from '../../components/option';
import { message, Table, Input, Modal } from 'antd';
import moment from 'moment';
import { getMaster, editMaster } from '../../services/recommendMaster';
import PageTurning from '../../components/pageTurning';

const { TextArea } = Input;
const title = ['全部', '待处理', '已处理'];
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigation: '全部',
      info: '',
      reconmmendList: [],
      currentMaster: '',
      remark: '',
      recommendModal: false,

      skip: 0,
      take: 10,
    };
    this.pageModal = React.createRef();
  }
  componentDidMount() {
    this.getData('全部', '', 0, 100);
  }

  render() {
    const { info, reconmmendList, recommendModal, remark } = this.state;
    const columns = [
      {
        title: '推荐人',
        align: 'center',
        dataIndex: 'pname',
        width: 100,
      },
      {
        title: '师傅姓名',
        align: 'center',
        dataIndex: 'name',
        width: 100,
      },
      {
        title: '师傅电话',
        align: 'center',
        dataIndex: 'phone',
        width: 120,
      },
      {
        title: '服务类型',
        align: 'center',
        dataIndex: '',
        width: 250,
      },
      {
        title: '师傅所在地',
        align: 'center',
        dataIndex: '',
        width: 200,
      },
      {
        title: '处理状态',
        align: 'center',
        dataIndex: 'state',
        width: 110,
        render: (text, record, index) => {
          return (
            record.state === '待处理' ? <div className='color2 back-color6'>未处理</div>
              : <div className='color6 back-color7'>已处理</div>
          );
        }
      },
      {
        title: '备注',
        align: 'center',
        dataIndex: 'content',
        width: 200,

      },
      {
        title: '添加时间',
        align: 'center',
        dataIndex: 'submit_time',
        width: 150,
        render: (text, record, index) => {
          return (<div >{moment(record.submit_time).format('YYYY-MM-DD HH:mm')}</div>);
        }
      },
      {
        title: '处理人',
        align: 'center',
        dataIndex: 'cname',
        width: 150,

      },
      {
        title: '处理时间',
        align: 'center',
        dataIndex: 'check_time',
        width: 150,
        render: (text, record, index) => {
          return (<div >{moment(record.check_time).format('YYYY-MM-DD HH:mm')}</div>);
        }
      },
      {
        title: '操作',
        align: 'center',
        dataIndex: 'operation',
        width: 140,
        render: (text, record, index) => {
          return (
            <div className='w100 row'>
              {
                record.state === '待处理' ?
                  <div className={`${styles['btn']} color0 back-color9`}>
                    <div onClick={this.showRecommendModal.bind(this, index)} className={styles['btn1']}>处理</div>
                    {/* <div onClick={this.showRecommendModal.bind(this, index)} className={`${styles['btn2']} cursor-pointer`}>数据整理</div> */}
                  </div>
                  : '/'
              }
            </div>
          );
        }
      },
    ]
    return (
      <div className='col-t'>
        <Option title={title} top={0} changeMyTop={this.changeSummary.bind(this, 'top')} className='margin-b10'>
          <div className='col-t vertical-lt padding25'>
            <div className=' row-l'>
              <div>推荐人：</div>
              <Input onChange={this.changeSummary.bind(this, 'info')} value={info} placeholder='请输入推荐人姓名' className={styles['input-service']} />
              <div onClick={this.searchMaster} className={`${styles['btn']} tc margin-l20 back-color5 color0 cursor-pointer`} >搜索</div>
            </div>
          </div>
        </Option>
        <div className=' back-color0 margin-t10'>
          <Table
            columns={columns}
            dataSource={reconmmendList}
            size={'small'}
            rowKey={record => record.id}
            bordered
            pagination={false}
          />
          <PageTurning pageMaps={this.pageMaps} ref={this.pageModal} />
        </div>
        <Modal
          title='师傅处理'
          visible={recommendModal}
          width={500}
          onCancel={this.hideRecommendModal}
          footer={
            <div className='w100 row-r'>
              <div onClick={this.hideRecommendModal} className={`${styles['btn-modal']} color6 fz17 margin-r20`} key='return'>取消</div>
              <div onClick={this.dealMaster} className={`${styles['btn-modal']} back-color9 color0 fz17 margin-r40`} key='save'>确认申请</div>
            </div>
          }
        >
          <div className='row-l margin-t20'>
            <div className={`${styles['property']} tr`}>备注说明：</div>
            <TextArea onChange={this.changeSummary.bind(this, 'remark')} value={remark} className={`${styles['input']} tl`} placeholder='备注' />
          </div>
        </Modal>
      </div>
    );
  }
  //换页
  pageMaps = ({ skip, take }) => {
    this.setState({
      skip: skip,
    }, () => this.getData())
  }
  getData = async () => {
    const { skip, take, navigation, info } = this.state;
    let res = await getMaster(navigation, info, skip, take);
    if (res.status === 200) {
      this.setState({
        reconmmendList: res.data
      })
    }
  }
  searchMaster = () => {
    this.setState({
      skip: 0,
    }, () => {
      this.getData()
      this.pageModal.current.returnFirst();
    })
  }
  dealMaster = async () => {
    const { currentMaster, info } = this.state;
    let res = await editMaster(currentMaster.id, info);
    if (res.status === 204) {
      this.hideRecommendModal()
      this.searchMaster()
    }
  }
  changeSummary = (type, e) => {
    switch (type) {
      case 'top':
        this.setState({
          navigation: title[e],
          skip: 0,
        }, async () => this.searchMaster());
        break;
      case 'remark':
        this.setState({
          remark: e.target.value
        });
        break;
      case 'info':
        this.setState({
          info: e.target.value
        });
        break;
    }
  }


  showRecommendModal = (e) => {
    const { reconmmendList } = this.state;
    this.setState({
      recommendModal: true,
      currentMaster: reconmmendList[e],
    })
  }
  hideRecommendModal = () => {
    this.setState({
      recommendModal: false
    })
  }

}

export default Index;