import React, { Component } from 'react';
import { Button, Modal, Input, message, InputNumber, Select, Table } from 'antd';
import styles from './index.module.css';
import { postOrderClaim } from '../../../services/orderCore';
import WorkModal from '../../../components/workModal.jsx';

class AfterSaleModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      type: '',
      shopPrice: 0,
      workerPrice: 0,
      remark: '',
      info: {},
      worker: 0,
      workerName: '',
    }
    this.workModal = React.createRef();
  }
  render() {
    const { visible, type, info, shopPrice, workerPrice, remark, workerName } = this.state;
    return (
      <Modal
        title={'商户售后'}
        visible={visible}
        width={700}
        onCancel={this.onCancel}
        onOk={this.onOk}
        okText='确定'
        cancelText='取消'
        confirmLoading={this.state.confirmLoading}
      >
        <div className='col vertical-lt'>
          <div className='row-l vertical-lt'>
            <div className={`${styles['title-order-cost']} margin-r10`}>售后类型:</div>
            {
              ['申请理赔', '售后服务'].map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`${styles['choiceType_div']} ${type === item && styles['choiceType_Color']}`}
                    onClick={this.onAfterSaleType.bind(this, item)}
                  >
                    {item}
                  </div>
                )
              })
            }
          </div>
          {
            type === '申请理赔' &&
            <div className='col-t vertical-lt margin-t10'>
              <div className='row-l vertical-lt'>
                <div className={`${styles['title-order-cost']} margin-r10`} >商户金额:</div>
                <InputNumber className={styles['w465']} value={shopPrice} mix={0} onChange={this.changeShop} />
              </div>
              <div className='row-l vertical-lt margin-t10'>
                <div className={`${styles['title-order-cost']} margin-r10`} >师傅金额:</div>
                <InputNumber className={styles['w465']} value={workerPrice} mix={0} onChange={this.changeWork} />
              </div>

            </div>
          }
          <div className='col-t vertical-lt margin-t10'>
            <div className='row-l'>
              <div className={`${styles['title-order-cost']} margin-r10`} >当前师傅:</div>
              <div className='w150 tc'>{workerName}</div>
              <Button onClick={this.showWorker} type='primary'>选择</Button>
            </div>
          </div>
          <div className='row-l vertical-lt margin-t10'>
            <div className={`${styles['title-order-cost']} margin-r10`} >备注:</div>
            <Input.TextArea value={remark} rows={4} onChange={this.changeRemark} className={styles['w465']} placeholder='输入备注' />
          </div>
        </div>
        <WorkModal ref={this.workModal} selectWorker={this.selectWorker} />
      </Modal>
    );
  }
  //售后类型
  onAfterSaleType = (type) => {
    this.setState({
      type: type,
    })
  }
  changeShop = (e) => {
    this.setState({
      shopPrice: e,
    })
  }
  changeWork = (e) => {
    this.setState({
      workerPrice: e,
    })
  }
  changeRemark = (e) => {
    this.setState({
      remark: e.target.value,
    })
  }
  showWorker = () => {
    this.workModal.current.onShow();
  }
  selectWorker = (selectedRows) => {
    this.setState({
      worker: selectedRows[0].id,
      workerName: selectedRows[0].name,
    });
  }
  onOk = async () => {
    const { info, type, shopPrice, workerPrice, remark, worker } = this.state;
    let data = {
      orderId: info.id,
      type: type,
      shopPrice: type === '申请理赔' ? shopPrice : 0,
      workerPrice: type === '申请理赔' ? workerPrice : 0,
      remark: remark,
      worker: worker,
    };
    let res = await postOrderClaim(data);
    if (res.status === 204) {
      message.success('提交成功');
      this.onCancel();
    }
  }
  onShow = (info) => {
    let type = '申请理赔';
    let worker = info.workerId;
    let workerName = info.workerName;
    this.setState({
      visible: true,
      info, type,
      worker, workerName,
    })
  }
  onCancel = () => {
    this.setState({
      visible: false,
      type: '',
      shopPrice: 0,
      workerPrice: 0,
      remark: '',
      info: {},
    })
  }
}
export default AfterSaleModal;