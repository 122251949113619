import React, { Component } from 'react';
import styles from './index.module.css';
import { message, Table, Input, Select, Modal, Button, DatePicker, } from 'antd';
import * as echarts from 'echarts';
import { getFinanceList, getServicerList } from '../../services/analysisCenter';
import { getDepartment_Data } from '../../config/storage';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';

moment.locale('zh-cn');
const dateFormat = 'YYYY-MM-DD'
const provincesList = ['北京市', '上海市', '天津市', '重庆市', '香港特别行政区', '澳门特别行政区', '黑龙江省', '辽宁省', '吉林省', '河北省', '河南省', '湖北省', '湖南省', '山东省', '山西省', '陕西省', '安徽省', '浙江省', '江苏省', '福建省', '广东省', '海南省', '四川省', '云南省', '贵州省', '青海省', '甘肃省', '江西省', '台湾省', '内蒙古自治区', '宁夏回族自治区', '新疆维吾尔自治区', '西藏自治区', '广西壮族自治区',];

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInfo: {
        servicer: '',
        start: '',
        end: '',
        provinces: [],
      },
      provinces: [],
      servicerList: [],
      orderNum: 0,
      source: [],
      modalShow: false,
      newProvince: '',
      quickBtn: 0,
      count: {
        '收入金额': 0,
        '支出金额': 0,
        '利润金额': 0,
        '理赔金额': 0,
      },
    };
    this.colums = [
      {
        title: '日期',
        align: 'center',
        width: 100,
        dataIndex: 'day',
      },
      {
        title: '商家消费',
        align: 'center',
        width: 100,
        dataIndex: 'income',
      },
      {
        title: '商家变更',
        align: 'center',
        width: 100,
        dataIndex: 'change',
      },
      {
        title: '商家好评',
        align: 'center',
        width: 100,
        dataIndex: 'bonus',
      },
      {
        title: '商家退款',
        align: 'center',
        width: 100,
        dataIndex: 'refound',
      },
      {
        title: '商家理赔',
        align: 'center',
        width: 100,
        dataIndex: 'aftersale',
      },
      {
        title: '商家修正',
        align: 'center',
        width: 100,
        dataIndex: 'fix',
      },
      {
        title: '师傅收入',
        align: 'center',
        width: 100,
        dataIndex: 'pay',
      },
      {
        title: '师傅理赔',
        align: 'center',
        width: 100,
        dataIndex: 'claim',
      },
      {
        title: '师傅返利',
        align: 'center',
        width: 100,
        dataIndex: 'rebate',
      },
      {
        title: '师傅好评返现',
        align: 'center',
        width: 100,
        dataIndex: 'praise',
      },
      {
        title: '师傅变更',
        align: 'center',
        width: 100,
        dataIndex: 'wchange',
      },
      {
        title: '收入金额',
        align: 'center',
        width: 100,
        dataIndex: '收入金额',
      },
      {
        title: '支出金额',
        align: 'center',
        width: 100,
        dataIndex: '支出金额',
      },
      {
        title: '利润金额',
        align: 'center',
        width: 100,
        dataIndex: '利润金额',
      },

    ];
  }
  componentDidMount() {
    this.getServicer();
    let res = getDepartment_Data();
    this.setState({
      provinces: provincesList,
      newProvince: provincesList[0],
    })
  }
  render() {
    const { option, source, searchInfo, servicerList, modalShow, newProvince, provinces, count,
      quickBtn, orderNum, } = this.state;
    return (
      <div className='col-t w100'>
        <div className='col-t vertical-lt padding20 back-color0 w100'>
          <div className='row-sb w100'>
            <div className='row-l'>
              <div>区域客服：</div>
              <Select value={searchInfo.servicer}
                onChange={this.changeSummary.bind(this, 'servicer')}
                className='w166'
              >
                {
                  servicerList.map((item, index) => {
                    return <Select.Option value={index} key={index}>{item.name}</Select.Option>;
                  })
                }
              </Select>
              <div className='margin-l20'>负责区域：</div>
              <div className={`${styles['provinces-box']} row-sb`}>
                <div className={`${styles['provinces']} row-w`}>
                  {
                    searchInfo.provinces.map((item, index) => {
                      return (
                        <div className={`${styles['btn']} row-sb`} key={index}>
                          <div className='oneline'>{item}</div>
                          <div onClick={this.delProvince.bind(this, index)} className='cursor-pointer padding-lr5'>X</div>
                        </div>
                      );
                    })
                  }
                </div>
                <div onClick={this.showModal} className={`${styles['add']} color6 fz15`}>添加</div>
              </div>
              <div className='margin-l20'>结算时间：</div>
              <DatePicker onChange={this.changeSummary.bind(this, 'start')} value={searchInfo.start} locale={locale} format={dateFormat} allowClear={false} />
              <div className='margin-l10 margin-r10'>至</div>
              <DatePicker onChange={this.changeSummary.bind(this, 'end')} value={searchInfo.end} locale={locale} format={dateFormat} allowClear={false} />
              <Button onClick={this.search} type='primary' className='margin-l20'>搜索</Button>
            </div>
            <div className='row'>
              {/* <Button onClick={this.toExcel} type='primary' className={`back-color0 color1 ${styles['reset']}`}>导出</Button> */}
              <Button onClick={this.reset} type='primary' className={`back-color0 color1 ${styles['reset']}`}>重置</Button>
            </div>
          </div>
        </div>
        <div className='back-color0 w100 margin-t20 padding20'>
          <div className='row-sb w100'>
            <div className='row-l'>
              <div className='fz18 bold'>每日金额统计</div>
              <div className={`${styles['selectDate']} color7`}>快速选择：</div>
              <div onClick={this.quickSelect.bind(this, 7)} className={quickBtn === 7 ? styles['quickly-select'] : styles['quickly']}>近7天</div>
              <div onClick={this.quickSelect.bind(this, 15)} className={quickBtn === 15 ? styles['quickly-select'] : styles['quickly']}>近15天</div>
              <div onClick={this.quickSelect.bind(this, 31)} className={quickBtn === 31 ? styles['quickly-select'] : styles['quickly']}>近31天</div>
            </div>
            <div className='row-r vertical-lt'>
              <div className='color7'>汇总：</div>
              <div className='col-t vertical-rb'>
                <div className={`${styles['summary-finance']} row`}>
                  <div className='bold'>{count['收入金额'].toFixed(1)}</div>
                  <div className='color5 margin-r5'> （收入）|</div>
                  <div className='bold'>{count['支出金额'].toFixed(1)}</div>
                  <div className='color5 margin-r5'> （支出）|</div>
                  <div className='bold'>{count['利润金额'].toFixed(1)}</div>
                  <div className='color5'> （利润）</div>
                  <div className='bold'>{count['收入金额'] ? (count['利润金额'] / count['收入金额'] * 100).toFixed(2) + '%' : 0}</div>
                  <div className='color5'> （利润率）</div>
                </div>
                <div className='row-r'>
                  <div className={`${styles['summary-finance']} row`}>
                    <div className='bold'>{orderNum}</div>
                    <div className='color9 margin-r5'> （总单量）|</div>
                    <div className='bold'>{Math.abs(Number(count['理赔金额'].toFixed(1)))}</div>
                    <div className='color5 margin-r5'> （理赔金额）|</div>
                    <div className='bold'>{orderNum ? Math.abs((count['理赔金额'] / orderNum).toFixed(1)) : 0}</div>
                    <div className='color5 margin-r5'> （理赔系数</div>
                  </div>
                </div>
              </div>

            </div>

          </div>
          <div>
            <div className={`${styles['count']}`} id='main'
            ></div>
          </div>
          <div className='w100 row margin-t20'>
            <div className={styles['box5']} />
            <div>收入金额</div>
            <div className={styles['box1']} />
            <div>支出金额</div>
            <div className={styles['box3']} />
            <div>利润金额</div>
          </div>
        </div>
        <div className='back-color0 w100 margin-t20 padding20'>
          <Table
            dataSource={source}
            columns={this.colums}
            size={'small'}
            rowKey={record => record.date}
            bordered
          />
        </div>
        <Modal
          title='选择区域'
          width={450}
          visible={modalShow}
          cancelText='取消'
          okText='确定'
          onCancel={this.hideModal}
          onOk={this.changeSummary.bind(this, 'newProvince')}
        >
          <div className='row-l'>
            <div>新增区域：</div>
            <Select value={newProvince}
              onChange={this.changeSummary.bind(this, 'province')}
              className='w166'
            >
              {
                provinces.map((item, index) => {
                  return <Select.Option value={item} key={index}>{item}</Select.Option>;
                })
              }
            </Select>
          </div>
        </Modal>
      </div >
    );

  }
  create = () => {
    const { count } = this.state;
    let option = {
      color: ['#1890FF', '#F41212', '#07C160'],
      tooltip: {
        trigger: 'axis',
        show: true,
        axisPointer: {
          type: 'line'
        }
      },
      xAxis: {
        type: 'category',
        axisTick: {
          alignWithLabel: true
        },
      },
      yAxis: {
        type: 'value'
      },
      grid: {
        left: '1%',
        right: '1%',
        bottom: '1%',
        containLabel: true
      },
      series: [
        {
          name: '收入金额',
          type: 'bar',
          barWidth: '25%',
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'top',
                textStyle: {
                  color: '#1890FF',
                  fontsize: 16
                }
              }
            }
          },
          // data: [1, 2, 3, 5, 7, 8, 8]
        },
        {
          name: '支出金额',
          type: 'bar',
          barWidth: '25%',
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'top',
                textStyle: {
                  color: '#F41212',
                  fontsize: 16
                }
              }
            }
          },
          // data: [1, 2, 3, 5, 7, 8, 8]
        },
        {
          name: '利润金额',
          type: 'bar',
          barWidth: '25%',
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'top',
                textStyle: {
                  color: '#07C160',
                  fontsize: 16
                }
              }
            }
          },
          // data: [1, 2, 3, 5, 7, 8, 8]
        },
      ],
      dataset: {
        dimensions: ['amount', '收入金额', '支出金额', '利润金额'],
        source: this.state.source
      },
      dataZoom: [{
        type: 'slider',//给x轴设置滚动条
        xAxisIndex: 0,
        bottom: 0,
        height: 7,
        showDetail: false,
        startValue: 0,//滚动条的起始位置
        endValue: 15 //滚动条的截止位置（按比例分割你的柱状图x轴长度）
      },]
    };
    let chartDom = document.getElementById('main');
    let myChart = echarts.init(chartDom);
    myChart.setOption(option)
  }
  getServicer = async () => {
    let res = await getServicerList()
    if (res.status === 200) {
      this.setState({
        servicerList: res.data
      }, () => this.reset());
    }
  }
  getData = async () => {
    const { searchInfo } = this.state;
    let start = moment(searchInfo.start).format(dateFormat)
    let end = moment(searchInfo.end).format(dateFormat)
    let res = await getFinanceList(start, end, searchInfo.provinces);
    if (res.status === 200) {
      let count = {
        '收入金额': 0,
        '支出金额': 0,
        '利润金额': 0,
        '理赔金额': 0,
      };
      let source = res.data.result;
      let orderNum = res.data.orderNum;
      source.map((item, index) => {
        item.income = - item.income;
        item.change = - item.change;
        item.refound = - item.refound;
        item.aftersale = - item.aftersale;
        item.fix = - item.fix;
        item.bonus = - item.bonus;

        item.pay = - item.pay;
        item.claim = - item.claim;
        item.rebate = - item.rebate;
        item.praise = - item.praise;
        item.wchange = - item.wchange;

        item.amount = moment(item.date).format('MM-DD');
        item.day = moment(item.date).format(dateFormat);
        item['收入金额'] = (item.income + item.change + item.refound + item.bonus + item.aftersale + item.fix).toFixed(1);
        item['支出金额'] = (item.pay + item.claim + item.rebate + item.praise + item.wchange).toFixed(1);
        item['利润金额'] = (Number(item['收入金额']) + Number(item['支出金额']) - item.present).toFixed(1);
        count['理赔金额'] += item.aftersale;
        count['收入金额'] += Number(item['收入金额']);
        count['支出金额'] += Number(item['支出金额']);
        count['利润金额'] += Number(item['利润金额']);
        return;
      })
      this.setState({
        source, count, orderNum,
      }, () => this.create());
    }
  }
  changeSummary = (type, e) => {
    const { servicerList, newProvince } = this.state;
    let searchInfo = { ...this.state.searchInfo };
    switch (type) {
      case 'start':
        searchInfo.start = e;
        this.setState({
          searchInfo
        }); break;
      case 'end':
        searchInfo.end = e;
        this.setState({
          searchInfo
        }); break;
      case 'servicer':
        searchInfo.servicer = servicerList[e].name;
        searchInfo.provinces = servicerList[e].areas.map(item => { return item.area; });
        this.setState({
          searchInfo
        }); break;
      case 'province':
        this.setState({
          newProvince: e
        }); break;
      case 'newProvince':
        searchInfo.provinces.push(newProvince);
        this.setState({
          searchInfo,
          modalShow: false
        }); break;
    }
  }
  search = () => {
    this.setState({
      quickBtn: 0
    }, () => this.getData())
  }
  quickSelect = (e) => {
    let searchInfo = {
      ...this.state.searchInfo,
      end: moment(new Date()),
      start: moment(new Date()).subtract(e, 'days'),
    }
    this.setState({
      quickBtn: e,
      searchInfo,
    }, () => this.getData())
  }
  delProvince = (e) => {
    let searchInfo = { ...this.state.searchInfo };
    searchInfo.provinces = searchInfo.provinces.filter((item, index) => index !== e)
    this.setState({
      searchInfo
    });
  }
  reset = () => {
    const { servicerList } = this.state;
    let searchInfo = {
      servicer: servicerList[0].name,
      provinces: servicerList[0].areas.map(item => { return item.area; }),
      end: moment(new Date()),
      start: moment(new Date()).subtract(31, 'days'),
    }
    this.setState({
      searchInfo,
      quickBtn: 0,
    }, () => this.getData());
  }
  showModal = () => {
    this.setState({
      modalShow: true
    });
  }
  hideModal = () => {
    this.setState({
      modalShow: false
    });
  }
  // ，收入和支出能导出，导出文件中包含订单号、客户信息、商户名称、师傅、商户金额、师傅金额、下单日期和结算日期
  toExcel = () => {
    const { } = this.state;
  }
}

export default Index;