import React, { useEffect, useState, Component } from 'react';
import styles from './index.module.css';
import Option from '../../components/option';
import InfoModal from './modal';
import { message, Table, Input, Radio, Image, Button, Select } from 'antd';
import Region from '../../components/regioRegister';
import person from '../../resource/png/masterManagement-person.png';
import link from '../../resource/png/masterManagement-link.png';
import approve from '../../resource/png/masterManagement-approve.png';
import signed from '../../resource/png/masterManagement-signed.png';
import close from '../../resource/png/masterManagement-close.png';
import see from '../../resource/png/masterManagement-see.png';
import PageTurning from '../../components/pageTurning';
import { getDepartment_Data } from '../../config/storage';
import moment from 'moment';
import { getLabel, } from '../../services/labelManagement';
import { postMaster, getMasterCount } from '../../services/masterManagement';
import { getServiceList } from '../../services/serviceManagement';
import { getService } from '../../services/general';
import WorkerModal from '../orderCore/allOrder/workerModal';

const titleList = ['全部', '待认证', '已认证'];
const statusList = ['全部', '启用', '关闭'];
const signList = ['全部', '已签约', '未签约'];//签约
const acceptList = ['全部', '接单中', '不接单'];
const qualityList = ['全部', '是', '否'];
const sorts = [
  {
    key: '',
    label: '默认',
  },
  {
    key: '保证金',
    label: '保证金',
  }
];
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      servicerList: [],
      servicers: [],
      manager: '',
      info: '',
      label: '全部',
      labelList: [],
      provinces: ['全部', '全部', '全部'],
      usingStatus: '启用',
      sign: '全部',
      check: '全部',
      order_status: '全部',
      typeWork: '全部',
      quanlity: '全部',
      serviceList: [],
      masterList: [],
      currentmaster: {},
      modalType: '',
      sort: '',
      skip: 0,
      take: 10,
      kefuData: {
        id: 0,
        name: '全部',
      },
      role: 0,
      balance: 0,
    };
    this.infoModal = React.createRef();
    this.workerModal = React.createRef();
    this.pageModal = React.createRef();
  }
  async componentDidMount() {
    let { roles, name, id } = getDepartment_Data();
    let kefuData = {
      id: 0,
      name: '全部',
    };
    if (roles[0] === 5) {
      kefuData.id = id;
      kefuData.name = name;
    }
    this.setState({
      role: roles[0],
      kefuData,
    }, () =>
      this.getData(kefuData.id, '全部', '全部', '全部', '全部', '', '不限', '不限', '不限', '全部', '全部')
    )
    this.getServiceList()
    this.getCount();
    this.getLabelList();
  }
  render() {
    const { servicerList, servicers, sort, serviceList, typeWork, quanlity, info, provinces, labelList, label,
      usingStatus, order_status, sign, masterList, modalType, kefuData, role, balance } = this.state;
    const columns = [
      {
        title: '师傅基本信息',
        align: 'center',
        dataIndex: 'info',
        width: 330,
        render: (text, record, index) => {
          return (
            <div className='w330 col-l fz14'>
              <div className='row-l'>
                <Image src={person} className={styles['img']} preview={false} />
                <div className={`${'w220'} tl margin-l5 white-space`}>{record.name}   {record.showPhone ? record.uid : record.hidePhone}（账号）</div>
                <Image onClick={this.showPhone.bind(this, index)} src={record.showPhone ? see : close} className={`${styles['img']} cursor-pointer`} preview={false} />
              </div>
              <div className='oneline tl'>{record.current_address}</div>
            </div>
          );
        }
      },
      {
        title: '认证状态',
        align: 'center',
        dataIndex: 'checked',
        width: 110,
        className: 'w110',
        render: (text, record, index) => {
          return (
            record.checked === '是' ? <Image src={approve} className={styles['img']} preview={false} />
              : <div className='color2 back-color6'>未认证</div>
          )
        }
      },
      {
        title: '启用状态',
        align: 'center',
        dataIndex: 'enable',
        width: 110,
        className: 'w110',
        render: (text, record, index) => {
          return (
            record.enable === '是' ? <div className='color6 back-color7'>已启用</div>
              : <div className='color2 back-color6'>已关闭</div>
          )
        }
      },
      {
        title: '签约状态',
        align: 'center',
        dataIndex: 'signed',
        width: 110,
        className: 'w110',
        render: (text, record, index) => {
          return (
            record.signed === '是' ? <Image src={signed} className={styles['img']} preview={false} />
              : <div className='color2 back-color6'>未签约</div>
          )
        }
      },
      {
        title: '接单状态',
        align: 'center',
        dataIndex: 'statement',
        width: 110,
        className: 'w110',
        render: (text, record, index) => {
          return (
            record.order_status === '接单中' ? <div className='color6 back-color7'>正常接单中...</div>
              : <div className='color2 back-color6'>无法接单</div>
          )
        }
      },
      {
        title: '保证金',
        align: 'center',
        dataIndex: 'bail',
        width: 110,
        className: 'w110',
        render: (text, record, index) => {
          return (<div >{record.deposit}</div>)
        }
      },
      {
        title: '负责人',
        align: 'center',
        dataIndex: 'revicer',
        width: 110,
        className: 'w140',
        render: (text, record, index) => {
          return (<div className='twoline'>{record.manager ? record.manager : ''}</div>)
        }
      },
      // {
      //   title: '备注信息',
      //   align: 'center',
      //   dataIndex: 'remark',
      //   width: 220,
      //   className: 'w220',
      //   render: (text, record, index) => {
      //     return (<div className='twoline'>{record.remark}</div>)
      //   }
      // },
      {
        title: '工种信息',
        align: 'center',
        dataIndex: 'profession',
        width: 220,
        className: 'w200',
        render: (text, record, index) => {
          return (<div className='twoline'>{record.work_type.length > 2 ? JSON.parse(record.work_type).join('，') : ''}</div>)
        }
      },
      {
        title: '加入时间',
        align: 'center',
        dataIndex: 'join',
        width: 110,
        className: 'w110',
        render: (text, record, index) => {
          return (<div >{moment(record.join_time).format('YYYY-MM-DD HH:mm')}</div>)
        }
      },
      {
        title: '操作',
        align: 'center',
        dataIndex: 'operation',
        width: 160,
        className: 'w250',
        render: (text, record, index) => {
          return (
            <div className='row'>
              <div onClick={this.showEdition.bind(this, record, 'edit')} className={`${styles['btn']} back-color8 color0 margin-r5`}>编辑</div>
              {/* <div onClick={this.showEdition.bind(this, record, 'inquire')} className={`${styles['btn']} back-color5 color0`}>查看师傅信息</div> */}
            </div>
          )
        }
      },
    ]
    return (
      <div className='col-t'>
        <Option title={titleList} top={0} changeMyTop={this.changeTop} className='margin-b10'>
          <div className='col-t vertical-lt padding20 w100'>
            <div className='row-l w100'>
              <span className={styles['title_style']}>负责客服：</span>
              <Input disabled={true} className='w198' value={kefuData.name} />
              {
                role === 5 ?
                  <div></div>
                  :
                  <Button type='primary' className='margin-l10' onClick={this.openModal.bind(this, '客服')}>选择</Button>
              }
              <div className='margin-l20'>所在区域：</div>
              {/* <AreaPicker value={provinces} onChange={this.changeSummary.bind(this, 'provinces')}></AreaPicker> */}
              <Region allPermiss={true} value={provinces} onChange={this.changeSummary.bind(this, 'provinces')}></Region>
              <div className={`${styles['title_style']} margin-l10`}>质保师傅：</div>
              <Radio.Group onChange={this.changeSummary.bind(this, 'quanlity')} value={quanlity} size='large'>
                {
                  qualityList.map((item, index) => {
                    return <Radio value={item} key={index} className='fz16'>{item}</Radio>;
                  })
                }
              </Radio.Group>
            </div>
            <div className='row-sb margin-t20 w100'>
              <div className='row-l'>
                <div>启用状态：</div>
                <Radio.Group onChange={this.changeSummary.bind(this, 'usingStatus')} value={usingStatus} size='large'>
                  {
                    statusList.map((item, index) => {
                      return <Radio value={item} key={index} checked={usingStatus === item ? true : false} className='fz16'>{item}</Radio>;
                    })
                  }
                </Radio.Group>
                <div className='margin-l20'>签约状态：</div>
                <Radio.Group onChange={this.changeSummary.bind(this, 'sign')} value={sign} size='large'>
                  {
                    signList.map((item, index) => {
                      return <Radio value={item} key={index} checked={sign === item ? true : false} className='fz16'>{item}</Radio>;
                    })
                  }
                </Radio.Group>
                <div className='margin-l20'>接单状态：</div>
                <Radio.Group onChange={this.changeSummary.bind(this, 'order_status')} value={order_status} size='large'>
                  {
                    acceptList.map((item, index) => {
                      return <Radio value={item} key={index} className='fz16'>{item}</Radio>;
                    })
                  }
                </Radio.Group>
                <div>师傅标签：</div>
                <Select value={label} onChange={this.changeSummary.bind(this, 'label')} className='w120'>
                  {
                    labelList.map((litem, lindex) => {
                      return (
                        <Select.Option value={litem.name} key={lindex}>{litem.name}</Select.Option>
                      );
                    })
                  }
                </Select>
              </div>

            </div>
            <div className='row-l margin-t20 w100'>
              <div className={styles['title_style']}>工种：</div>
              <Radio.Group onChange={this.changeSummary.bind(this, 'typeWork')} value={typeWork} size='large'>
                {
                  serviceList.map((item, index) => {
                    return <Radio value={item} key={index} className='fz16'>{item}</Radio>;
                  })
                }
              </Radio.Group>
            </div>
            <div className='row-sb margin-t20 w100'>
              <div className='row-l'>
                <div className={styles['title_style']}>排序：</div>
                <Select value={sort} onChange={this.changeSummary.bind(this, 'sort')} className='w120'>
                  {
                    sorts.map((item, index) => {
                      return (
                        <Select.Option value={item.key} key={index}>{item.label}</Select.Option>
                      );
                    })
                  }
                </Select>
                <div className='margin-l20'>师傅信息：</div>
                <Input onChange={this.changeSummary.bind(this, 'info')} value={info} placeholder='请输入姓名/手机号码' className={styles['input-service']} />
                <div onClick={this.newSearch} className={`${styles['btn']} tc margin-l20 back-color5 color0`} >搜索</div>
                <div onClick={this.showEdition.bind(this, {}, 'add')} className={`${styles['btn']} tc margin-l20 back-color5 color0`} >新增师傅</div>
              </div>
              <div onClick={this.reset} className={`${styles['btn']} tc margin-l20`}>重置</div>

            </div>
          </div>
        </Option>
        <div className=' back-color0 margin-t10'>
          <Table
            columns={columns}
            dataSource={masterList}
            size={'small'}
            rowKey={record => record.id}
            bordered
            pagination={false}
          />
          <div className='w100 row-sb'>
            {
              role === 9 || role === 11 ?
                <div className='row-l'>
                  <div className='margin-lr20 fz18'>师傅余额合计：{balance}</div>
                  <Button type='primary' className='margin-l40' onClick={this.getCount}>更新</Button>
                </div>
                : <div></div>
            }
            <PageTurning pageMaps={this.pageMaps} ref={this.pageModal} />
          </div>
        </div>
        <InfoModal ref={this.infoModal} updateMaster={this.update.bind(this)} />
        <WorkerModal
          ref={this.workerModal}
          updateTable={this.updateTable}
        />
      </div>
    )
  }
  getLabelList = async () => {
    let res = await getLabel('是', '师傅', 0, 100);
    if (res.status === 200) {
      let labelList = [
        {
          id: 0,
          name: '全部',
        },
        ...res.data,
      ];
      labelList.unshift();
      this.setState({
        labelList,
      });
    }
  }
  updateTable = (row, type) => {
    if (row) {
      this.setState({
        kefuData: row
      })
    }
  }
  //打开modal
  openModal = (e) => {
    this.workerModal.current.onShowModal(e);
  }
  //换页
  pageMaps = ({ skip, take }) => {
    this.setState({
      skip: skip,
    }, async () => this.toSearch())
  }
  getData = async (manager, check, sign, enable, status, info, province, city, district, worktype, ensured) => {
    const { sort, skip, take, label } = this.state;
    let data = {
      check: check,
      sign: sign,
      enable: enable,
      status: status,
      manager: manager,
      info: info,
      province: province,
      city: city,
      district: district,
      workerType: worktype,
      ensured: ensured,
      sort: sort,
      label: label,
      skip: skip,
      take: take,
    };
    let res = await postMaster(data);
    if (res.status === 200) {
      let masterList = [];
      for (let i = 0; i < res.data.length; i++) {
        let master = res.data[i];
        master.showPhone = false;
        master.hidePhone = this.hideTel(master.uid);
        masterList.push(master)
      }
      this.setState({
        masterList: res.data
      })
    }
  }
  getCount = async () => {
    let res = await getMasterCount();
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    this.setState({
      balance: res.data
    })
  }
  hideTel = (e) => {
    let reg = /^(\d{3})\d{4}(\d{4})$/;
    return e.replace(reg, '$1****$2');
  }
  newSearch = () => {
    this.setState({
      skip: 0,
    }, async () => {
      this.pageModal.current.returnFirst();
      this.toSearch()
    })
  }
  getServiceList = async () => {
    let res = await getService();
    if (res.status === 200) {
      let serviceList = res.data;
      serviceList.unshift('全部')
      this.setState({
        serviceList
      });
    }
  }
  toSearch = () => {
    const { manager, provinces, info, usingStatus, order_status, check, sign, servicers, servicerList, kefuData, typeWork, quanlity } = this.state;
    let myCheck = check === '全部' ? '全部' : check === '已认证' ? '是' : '否';
    let mySign = sign === '全部' ? '全部' : sign === '已签约' ? '是' : '否';
    let myEnable = usingStatus === '全部' ? '全部' : usingStatus === '启用' ? '是' : '否';
    let province = provinces[0] === '全部' ? '不限' : provinces[0]
    let city = provinces[1] === '全部' ? '不限' : provinces[1]
    let district = provinces[2] === '全部' ? '不限' : provinces[2]
    let myManager = kefuData.id;

    this.getData(myManager, myCheck, mySign, myEnable, order_status, info, province, city, district, typeWork, quanlity);

  }
  reset = () => {
    const { role } = this.state;
    let kefuData = { ...this.state.kefuData };
    if (role !== 5) {
      kefuData = {
        id: 0,
        name: '全部',
      }
    }
    this.setState({
      manager: '',
      provinces: ['全部', '全部', '全部'],
      info: '',
      usingStatus: '全部',
      check: '全部',
      sign: '全部',
      order_status: '全部',
      typeWork: '全部',
      quanlity: '全部',
      kefuData,
      sort: '',
      labe: '全部',
    }, () => this.newSearch())
  }
  changeSummary = (type, e) => {
    switch (type) {
      case 'sort':
        this.setState({
          sort: e
        });
        break;
      case 'typeWork':
        this.setState({
          typeWork: e.target.value
        });
        break;
      case 'quanlity':
        this.setState({
          quanlity: e.target.value
        });
        break;
      case 'manager':
        this.setState({
          manager: e.target.value
        });
        break;
      case 'provinces':
        this.setState({
          provinces: e
        });
        break;
      case 'info':
        this.setState({
          info: e.target.value
        });
        break;
      case 'usingStatus':
        this.setState({
          usingStatus: e.target.value
        });
        break;
      case 'sign':
        this.setState({
          sign: e.target.value
        });
        break;
      case 'order_status':
        this.setState({
          order_status: e.target.value
        });
        break;
      case 'label':
        this.setState({
          label: e
        });
        break;
    }

  }
  changeTop = (e) => {
    this.setState({
      check: titleList[e],
      skip: 0
    }, async () => this.toSearch());
  }

  //编辑
  showEdition = (e, type) => {
    let data = {
      type: type,
      id: e.id,
      manager: e.manager,
    }
    this.infoModal.current.showDrawer(data);
    this.setState({
      modalType: type,
      currentmaster: e,
    })
  }
  update = (e) => {
    this.toSearch();
  }
  showPhone = (e) => {
    let masterList = [...this.state.masterList];
    masterList[e].showPhone = masterList[e].showPhone ? false : true;
    this.setState({
      masterList
    });
  }

}



export default Index;