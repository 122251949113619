import request from '../utils/request';
//获取价格模板列表
export async function getTemplate(enable, skip, take, search = '') {
  return await request(`data/scriptlist?search=${search}&enable=${enable}&skip=${skip}&take=${take}`, 'get');
}
//新增价格模板列表
export async function addTemplate(body) {
  return await request(`data/addscriptlist`, 'post', body);
}
//修改价格模板列表
export async function editTemplate(id, body) {
  return await request(`data/mdyscript?id=${id}`, 'put', body);
}

//获取价目表详情
export async function getDetail(script, service, skip, take) {
  return await request(`data/scriptdetail?script=${script}&service=${service}&skip=${skip}&take=${take}`, 'get');
}
//新增价目表详情
export async function addDetail(body) {
  return await request(`data/adddetail`, 'post', body);
}
//修改价目表详情
export async function editDetail(id, body) {
  return await request(`data/mdydetail?id=${id}`, 'put', body);
}
//删除价目表详情
export async function delDetail(id) {
  return await request(`data/deldetail?id=${id}`, 'delete');
}

//获取服务产品列表
export async function getProduct(service) {
  return await request(`data/products?service=${service}`, 'get');
}


//获取对应价格模板
export async function getMasterTemplate(enable, skip, take, id) {
  return await request(`data/scriptList?enable=${enable}&skip=${skip}&take=${take}&id=${id}`, 'get');
}

//新增商户/师傅价格模板
export async function addMasterTemplate(type, script, id) {
  return await request(`data/addscript?type=${type}&script=${script}&id=${id}`, 'post');
}

//删除商户/师傅价格模板
export async function delPriceTemplate(type, id) {
  return await request(`data/delscript?type=${type}&id=${id}`, 'delete');
}

//获取起步价
export async function getStart(script, service, start, skip, take) {
  return await request(`data/scriptdetail?script=${script}&service=${service}&start=${start}&skip=${skip}&take=${take}`, 'get');
}

