import React, { useEffect, useState, Component } from 'react';
import { message, Table, Input, Radio, Modal, Button, Select } from 'antd';
import { postMaster } from '../../services/masterManagement';
import PageTurning from '../../components/pageTurning';

class WorkModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      info: '',
      skip: 0,
      take: 10,
      dataSource: [],
      selectedRowKeys: [],
      selectedRows: [],
    };
    this.pageModal = React.createRef();
    this.columns = [
      {
        title: '名称',
        align: 'center',
        dataIndex: 'name',
        width: 100,
      },
      {
        title: '电话',
        align: 'center',
        dataIndex: 'uid',
        width: 100,
      },
    ];
  }
  render() {
    const { visible, info, dataSource, selectedRowKeys, selectedRows } = this.state;
    const rowSelection = {
      columnTitle: '选择',
      selectedRowKeys: selectedRowKeys,
      type: 'radio',
      onChange: this.onSelectChange,
    };
    return (
      <Modal
        title='标签'
        visible={visible}
        width={500}
        onCancel={this.hide}
        onOk={this.onOk}
        okText='确定'
        cancelText='取消'
      >
        <div className='row-l margin-b10'>
          <Input value={info} onChange={this.changeInfo} className='w300' />
          <Button onClick={this.toSearch} type='primary'>搜索</Button>
        </div>
        <Table
          columns={this.columns}
          dataSource={dataSource}
          rowSelection={rowSelection}
          size={'small'}
          rowKey={record => record.id}
          bordered
          pagination={false}
        />
        <div className='w100 row-r'>
          <PageTurning pageMaps={this.pageMaps} ref={this.pageModal} />

        </div>
      </Modal>
    );
  }
  changeInfo = (e) => {
    this.setState({
      info: e.target.value
    })
  }
  toSearch = () => {
    this.pageModal.current.returnFirst();
    this.setState({
      skip: 0,
    }, () => this.getList())
  }
  getList = async () => {
    const { info, skip, take } = this.state;
    let data = {
      check: '全部',
      sign: '全部',
      enable: '是',
      status: '全部',
      manager: 0,
      info: info,
      province: '不限',
      city: '不限',
      district: '不限',
      workerType: '全部',
      ensured: '全部',
      sort: '',
      label: '全部',
      skip: skip,
      take: take,
    };
    let res = await postMaster(data);
    if (res.status === 200) {
      this.setState({
        dataSource: res.data
      });
    }
  }
  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedRowKeys, selectedRows
    });
  }
  onOk = () => {
    const { selectedRowKeys, selectedRows } = this.state;
    if (selectedRowKeys.length === 0) {
      message.warn('未选择师傅');
      return;
    }
    this.props.selectWorker(selectedRows);
    this.hide();
  }
  //换页
  pageMaps = ({ skip, take }) => {
    this.setState({
      skip: skip,
    }, async () => this.getList())
  }
  onShow = () => {
    this.getList();
    this.setState({
      visible: true,
    })
  }
  hide = () => {
    this.setState({
      visible: false,
      info: '',
      skip: 0,
      selectedRowKeys: [],
      selectedRows: [],
    })
  }
}
export default WorkModal;