import React, { Component } from 'react';
import { Select, Row, Col } from 'antd';
import adocde from './adocde';
import styles from './index.module.css';

const { Option } = Select;

class Region extends Component {
  constructor(props) {
    super(props);
    this.state = {
      provinces: this.getProvinces(),
      citys: [],
      districts: [],
    };
  }

  componentDidMount() {
    const citys = this.getCity(this.props.value[0]);
    const districts = this.getDistrict(this.props.value[1]);
    this.setState({
      citys: citys,
      districts: districts
    });
  }
  componentWillReceiveProps(nextProps) {
    let isRestart = this.props.value[0] === nextProps.value[0] && this.props.value[1] === nextProps.value[1] && this.props.value[2] === nextProps.value[2]
    if (!isRestart) {
      const citys = this.getCity(nextProps.value[0]);
      const districts = this.getDistrict(nextProps.value[1]);
      this.setState({
        citys: citys,
        districts: districts,
      });
    }
  }

  render() {
    const { value } = this.props;
    const { provinces, citys, districts } = this.state;
    return (
      <Row >
        <Col span={8}>
          <Select value={value[0]} onChange={this.onProvinceChange} size={'large'} className={styles['register-select']}>
            {
              provinces.map((item, index) => {
                return (
                  <Option
                    key={index}
                    value={item.name}
                  >
                    {item.name}
                  </Option >
                );
              })
            }
          </Select>
        </Col>
        <Col span={8}>
          <Select value={value[1]} onChange={this.onCityChange} size={'large'} className={styles['register-select']}>
            {
              citys.map((item, index) => {
                return (
                  <Option
                    key={index}
                    value={item.name}
                  >
                    {item.name}
                  </Option >
                );
              })
            }
          </Select>
        </Col>
        <Col span={8}>
          <Select value={value[2]} onChange={this.onDistrictChange} size={'large'} className={styles['register-select']}>
            {
              districts.map((item, index) => {
                return (
                  <Option
                    key={index}
                    value={item.name}
                  >
                    {item.name}
                  </Option >
                );
              })
            }
          </Select>
        </Col>
      </Row>
    );
  }

  getProvinces = () => {
    let provinces = adocde.filter(item => item.id.substr(item.id.length - 4) === '0000');
    if (this.props.allPermiss) {
      provinces.unshift({ id: '100000', name: '全部' });
    }
    return provinces;
  }

  getCity = (e) => {
    if (!e || e && e.length === 0) {
      return [];
    }
    const province = this.state.provinces.filter(item => item.name === e)[0];
    const reg = new RegExp(`^${province.id[0]}${province.id[1]}[0-9]{2}00$`);
    let citys = adocde.filter(r => reg.test(r.id));
    if (citys.length !== 1) {
      const cityReg = new RegExp(`^${province.id[0]}${province.id[1]}0000$`);
      citys = citys.filter(r => !cityReg.test(r.id));
    }
    if (this.props.allPermiss) {
      citys.unshift({ id: '100000', name: '全部' });
    }
    return citys;
  }

  getDistrict = (e) => {
    if (!e || e && e.length === 0) {
      return [];
    }
    let city = adocde.filter(item => item.name === e)[0];
    if (!city && this.props.allPermiss) {
      city = { id: '100000', name: '全部' };
    }
    const cityReg = new RegExp(`^${city.id[0]}${city.id[1]}0000$`);
    if (cityReg.test(city.id)) {
      const districtReg = new RegExp(`^${city.id[0]}${city.id[1]}[0-9]{4}$`);
      let districts = adocde.filter(r => districtReg.test(r.id));
      if (districts.length !== 1) {
        districts = districts.filter(r => !cityReg.test(r.id));
      }
      if (this.props.allPermiss) {
        districts.unshift({ id: '100000', name: '全部' });
      }
      return districts;
    }
    else {
      const reg = new RegExp(`^${city.id[0]}${city.id[1]}${city.id[2]}${city.id[3]}[0-9]{2}$`);
      let districts = adocde.filter(r => reg.test(r.id));
      if (districts.length !== 1) {
        const districtReg = new RegExp(`^${city.id[0]}${city.id[1]}${city.id[2]}${city.id[3]}00$`);
        districts = districts.filter(r => !districtReg.test(r.id));
      }
      if (this.props.allPermiss) {
        districts.unshift({ id: '100000', name: '全部' });
      }
      return districts;
    }
  }

  onProvinceChange = (e) => {
    let newCity = this.getCity(e);
    let newDistrict = this.getDistrict(newCity[0].name);
    this.setState({
      citys: newCity,
      districts: newDistrict,
    });
    this.props.onChange && this.props.onChange([e, newCity[0].name, newDistrict[0].name]);
  }

  onCityChange = (e) => {
    const value = [...this.props.value];
    let newDistrict = this.getDistrict(e);
    value[1] = e;
    value[2] = newDistrict[0].name;
    this.setState({
      districts: newDistrict,
    });
    this.props.onChange && this.props.onChange(value);
  }

  onDistrictChange = (e) => {
    const value = [...this.props.value];
    value[2] = e;
    this.props.onChange && this.props.onChange(value);
  }
}

export default Region;