import request from '../utils/request';
import convert from './convert';
import { message } from 'antd';
import { fetch } from 'whatwg-fetch';
import ImageCompressor from 'image-compressor.js';
import { getOssBaseAuth, setOssBaseAuth, getUser, getOssBaseAuthNotice, setOssBaseAuthNotice } from '../config/storage';

const ossAuth = async function () {
  let ossBaseAuth = getOssBaseAuth();
  if (ossBaseAuth && ossBaseAuth.expiration && new Date(ossBaseAuth.expiration) > new Date()) {
    return ossBaseAuth;
  }
  let rsp = await request('data/upload?prefix=upload', 'get');
  if (rsp.status === 200) {
    ossBaseAuth = {
      ...ossBaseAuth,
      ...rsp.data
    }
    setOssBaseAuth(ossBaseAuth);
    return ossBaseAuth;
  }
  return null;
};
export const ossAuthNotice = async function () {
  let ossBaseAuth = getOssBaseAuthNotice();
  if (ossBaseAuth && ossBaseAuth.expire && new Date(ossBaseAuth.expire) > new Date()) {
    return ossBaseAuth;
  }
  let rsp = await request('data/upload?prefix=notification', 'get');
  if (rsp.status === 200) {
    ossBaseAuth = {
      ...ossBaseAuth,
      ...rsp.data
    }
    setOssBaseAuthNotice(ossBaseAuth);
    return ossBaseAuth;
  }
  return null;
};

export const checkedImg = (info, requirement, isCompress = false) => {
  const { width, height, size } = info;
  if (requirement.width && width !== requirement.width) {
    message.warn(`该图片尺寸宽:${width}px,图片要求宽${requirement.width}px`);
    return false;
  }
  if (requirement.height && height !== requirement.height) {
    message.warn(`该图片尺寸高:${height}px,图片要求高${requirement.height}px`);
    return false;
  }
  if (requirement.equal && info.width !== info.height) {
    message.warn('图片宽高不相等');
    return false;
  }
  if (!isCompress && requirement.size && size > requirement.size) {
    message.warn(`该图片超过${requirement.size}M`);
    return false;
  }
  return true;
};

export const getImageInfo = (file) => {
  const promise = new Promise(function (resolve, reject) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      let image = new Image();
      image.onload = function () {
        let size = {
          width: this.width,
          height: this.height,
          size: file.size / (1024 * 1024),
          src: this.src
        };
        resolve(size);
      };
      image.src = e.target.result;
    };
  });
  return promise;
};

export const ossUpload = async (file, name = null, compression = true, options = {}) => {
  let oss = await ossAuth();
  if (!oss) { throw new Error('OSS获取权限失败'); }
  let curDate = new FormData();
  let _fileTag = file.name.split('.');
  let fileTag = _fileTag[_fileTag.length - 1];
  if (!name) {
    let decimal = (new Date()).valueOf();
    let user = getUser();
    name = `${user}${convert.toString36(decimal)}`.replace(/:/g, '-');
  }
  let nameSuffix = `${name}.${fileTag}`.toLocaleLowerCase();
  let key = `${oss.prefix}/${nameSuffix}`;
  let blobFile;
  if (compression) {
    const imageCompressor = new ImageCompressor();
    blobFile = await imageCompressor.compress(file, { quality: 0.8, ...options });
  } else {
    blobFile = file;
  }
  curDate.append('OSSAccessKeyId', oss.key);
  curDate.append('policy', oss.policy);
  curDate.append('Signature', oss.signature);
  curDate.append('key', key);
  curDate.append('success_action_status', '200');
  curDate.append('file', blobFile);
  let res = await fetch(oss.host, {
    method: 'post',
    body: curDate
  });
  if (res.status !== 200) {
    throw new Error('OSS上传图片失败');
  }
  return nameSuffix;
};
export const ossUploadNotice = async (file, name = null, compression = false, options = {}) => {
  let oss = await ossAuthNotice();
  if (!oss) { throw new Error('OSS获取权限失败'); }
  let curDate = new FormData();
  let _fileTag = file.name.split('.');
  let fileTag = _fileTag[_fileTag.length - 1];
  if (!name) {
    let decimal = (new Date()).valueOf();
    let user = getUser();
    name = `${user}${convert.toString36(decimal)}`.replace(/:/g, '-');
  }
  let nameSuffix = `${name}.${fileTag}`.toLocaleLowerCase();
  let key = `${oss.prefix}/${nameSuffix}`;
  curDate.append('OSSAccessKeyId', oss.key);
  curDate.append('policy', oss.policy);
  curDate.append('Signature', oss.signature);
  curDate.append('key', key);
  curDate.append('success_action_status', '200');
  curDate.append('file', file);
  let res = await fetch(oss.host, {
    method: 'post',
    body: curDate
  });
  if (res.status !== 200) {
    throw new Error('OSS上传图片失败');
  }
  return nameSuffix;
};

export const ossUploadBolb = async (blob, options = {}) => {
  let oss = await ossAuth();
  if (!oss) { throw new Error('OSS获取权限失败'); }
  let curDate = new FormData();
  let _fileTag = blob.type.split('/');
  let fileTag = _fileTag[_fileTag.length - 1];
  const imageCompressor = new ImageCompressor();
  let blobFile = await imageCompressor.compress(blob, { quality: 0.8, width: 800, ...options });
  let decimal = (new Date()).valueOf();
  let user = getUser();
  let name = `${user}${convert.toString36(decimal)}`.replace(/:/g, '-');
  let nameSuffix = `${name}.${fileTag}`.toLocaleLowerCase();
  let key = `${oss.dir}/${nameSuffix}`;
  curDate.append('OSSAccessKeyId', oss.accessid);
  curDate.append('policy', oss.policy);
  curDate.append('Signature', oss.signature);
  curDate.append('key', key);
  curDate.append('success_action_status', '200');
  curDate.append('file', blobFile);
  let res = await fetch(oss.host, {
    method: 'post',
    body: curDate
  });
  if (res.status !== 200) {
    throw new Error('OSS上传图片失败');
  }
  return nameSuffix;
};