import React, { Component } from 'react';
import { Input, Modal, Button, message, Checkbox, Table, Upload, Image, InputNumber } from 'antd';
import styles from './index.module.css';

class MsgModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      phone: '',
      id: 0,
    };
  }
  render() {
    const { phone, visible } = this.state;
    return (
      <Modal
        title={'发送短信'}
        visible={visible}
        width={450}
        onCancel={this.onCancel}
        onOk={this.onOK}
        okText='确定'
        cancelText='取消'
      >
        <div className='row-l'>
          <div>客户手机号：</div>
          <InputNumber value={phone} onChange={this.chagePhone} className='w250' controls={false} />
        </div>
      </Modal>
    );
  }
  chagePhone = (e) => {
    this.setState({
      phone: e
    })
  }
  onShow = (e) => {
    this.setState({
      phone: e.phone,
      id: e.id,
      visible: true,
    })
  }
  onOK = () => {
    const { phone, id } = this.state;
    let msg = {
      phone: phone,
      id: id
    };
    this.props.sendMessage(msg);
  }
  onCancel = () => {
    this.setState({
      visible: false,
      phone: '',
      id: 0,
    })
  }
};
export default MsgModal;