import React, { Component } from 'react';
import styles from './index.module.css';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { message } from 'antd';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageIndex: 1,
    };
    this.pageSize = this.props.pageSize ? this.props.pageSize : 10;
  }
  render() {
    const { total, } = this.props;
    return (
      <div className={'row-r padding10'}>
        <div className={`${styles['pageStyle']} margin-r10 none-select`} onClick={this.changePageIndex.bind(this, '-')} ><LeftOutlined />上一页</div>
        <div className={`${styles['pageStyle']} none-select`} onClick={this.changePageIndex.bind(this, '+')} >下一页<RightOutlined /></div>
        {
          total &&
          <div className='margin-l10 row'>
            合计：
            <div className='fz20 margin-r5'>{Math.floor(total / this.pageSize)}</div>
            页
          </div>
        }
        <div className='row-l margin-l10 fz18 none-select'>
          向第
          {
            this.state.pageEdit ?
              <input className={`${styles['width-50']} margin-r5 margin-l5`}
                onFocus={this.inputOnFocus}
                onBlur={this.inputOnBlur}
                onChange={this.onChange}
                value={this.state.pageIndex}
              />
              :
              <div className={`${styles['width-50']} ${styles['inline']} margin-r5 margin-l5`} onClick={this.onChangeDiv}>{this.state.pageIndex}</div>
          }
          页跳转
        </div>
      </div>
    );
  }

  //换页
  onChangeDiv = () => {
    this.setState({
      pageEdit: true,
    });
  }

  pageMap = () => {
    return ({
      skip: (this.state.pageIndex - 1) * this.pageSize,
      take: this.pageSize
    });
  }

  onChange = (e) => {
    let value = e.target.value;
    if (value === '') {
      this.setState({
        pageIndex: ''
      });
      return;
    }
    if (isNaN(value)) {
      return;
    } else {
      let number = Number(value);
      if (number < 1 || number % 1 !== 0) {
        return;
      }
    }
    this.setState({
      pageIndex: Number(e.target.value)
    });
  }

  inputOnBlur = (e) => {
    this.setState({
      pageEdit: false,
    });
    if (this.state.pageIndex === '') {
      this.setState({
        pageIndex: this.tempPageIndex,
      });
      return;
    }
    this.props.pageMaps({ ...this.pageMap(), pageIndex: this.tempPageIndex });
  }

  inputOnFocus = (e) => {
    this.tempPageIndex = e.target.value;
  }

  changePageIndex = async (type) => {
    let pageIndex = _.cloneDeep(this.state.pageIndex);
    if (type === '-' && pageIndex > 1) {
      pageIndex--;
      await this.setState({
        pageIndex: pageIndex
      });
    } else if (type === '+') {
      pageIndex++;
      await this.setState({
        pageIndex: pageIndex
      });
    } else {
      message.warn('当前为第一页');
    }
    this.props.pageMaps({ ...this.pageMap(), pageIndex });
  }

  returnFirst = () => {
    this.setState({
      pageIndex: 1
    });
  }
}

export default Index;