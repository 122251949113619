import { Table, Modal, Input, Button, Popconfirm } from 'antd';
import React, { Component } from 'react';
import { getMacList, delMac } from '../../services/whiteList';
import MacDrawer from './macDrawer';
import RecordDrawer from './recordDrawer';
import moment from 'moment';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    }
    this.macDrawer = React.createRef();
    this.recordDrawer = React.createRef();
    this.columns = [
      {
        title: '申请人',
        dataIndex: 'name',
        align: 'center',
        width: 100,
      },
      {
        title: '申请说明',
        dataIndex: 'remark',
        align: 'center',
        width: 200,
      },
      {
        title: '申请时间',
        dataIndex: 'time',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return (<div>{moment(record.time).format('YYYY/MM/DD-HH:mm:ss')}</div>);
        }
      },
      {
        title: 'mac地址',
        dataIndex: 'address',
        align: 'center',
        width: 100,
      },
      {
        title: '是否通过',
        dataIndex: 'enable',
        align: 'center',
        width: 100,
      },
      {
        title: '编辑',
        dataIndex: 'edit',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return (<div className='row'>
            <Button onClick={this.toEdit.bind(this, record)} type='primary' className='margin-r10'>编辑</Button>
            <Popconfirm
              title="您确定要删除吗?"
              onConfirm={this.delMac.bind(this, record)}
              okText="确认"
              cancelText="取消"
            >
              <Button type='danger'>删除</Button>
            </Popconfirm>
            <Button onClick={this.toRecord.bind(this, record)} type='primary' className='margin-l10'>记录</Button>
          </div>);
        }
      },
    ];
  }
  componentDidMount() {
    this.getList();
  }
  render() {
    const { data } = this.state;
    return (
      <div>
        <div className='bar margin-b10'>
          <Button onClick={this.getList} type='primary'>刷新</Button>
        </div>
        <div className='bar'>
          <Table
            columns={this.columns}
            dataSource={data}
            size={'small'}
            rowKey={'id'}
            bordered
          />
          <MacDrawer ref={this.macDrawer} update={this.getList} />
          <RecordDrawer ref={this.recordDrawer} />
        </div>
      </div>
    );
  }
  getList = async () => {
    let res = await getMacList()
    if (res.status === 200) {
      this.setState({
        data: res.data
      });
    }
  }
  toEdit = (e) => {
    this.macDrawer.current.onShow(e);
  }
  delMac = async (e) => {
    let res = await delMac(e.id)
    if (res.status === 204) {
      this.getList();
    }
  }
  toRecord = (e) => {
    this.recordDrawer.current.onShow(e);
  }
}
export default Index;