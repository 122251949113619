import React, { Component } from 'react';
import styles from './index.module.css';
import { message, Table, Input, Radio, Image, Select, Modal, Button, Popconfirm, Tag, Checkbox } from 'antd';
import {
  getServiceList, modifyInfo, delArea, addArea, delPermission, addPermission, addService,
  getPermission,
} from '../../services/serviceManagement';
import { getDepartment_Data } from '../../config/storage';
import { CloseOutlined } from '@ant-design/icons';
import PageTurning from '../../components/pageTurning';

const { Option } = Select;
import _ from 'lodash';
const provincesList = ['北京市', '上海市', '天津市', '重庆市', '香港特别行政区', '澳门特别行政区', '黑龙江省', '辽宁省', '吉林省', '河北省', '河南省', '湖北省', '湖南省', '山东省', '山西省', '陕西省', '安徽省', '浙江省', '江苏省', '福建省', '广东省', '海南省', '四川省', '云南省', '贵州省', '青海省', '甘肃省', '江西省', '台湾省', '内蒙古自治区', '宁夏回族自治区', '新疆维吾尔自治区', '西藏自治区', '广西壮族自治区',];
const permissions = ['订单中心', '售后中心', '测量数据', '师傅维护', '商户维护', '财务中心', '分析中心', '客服维护',];
const statusList = ['全部', '启用', '关闭'];
const enableList = ['启用', '关闭'];
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      provinces: [],
      permissionList: [],
      searchValue: '',
      status: '全部',
      serviceList: [],
      currentService: {
        id: 0,
        name: '',
        uid: '',
        pwd: '',
        enable: '启用',
        remark: '',
        phone: '',
        account: 0,
        roles: [],
        areas: [],
      },
      index: 0,
      province: [],
      permission: 0,
      serviceModal: false,
      areaModal: false,
      permissionModal: false,
      skip: 0,
      take: 10,
      modalType: 'add',
      mdypwd: false,
      pwd: '',
    };
    this.pageModal = React.createRef();

  }
  componentDidMount() {
    this.getData()
    // let res = getDepartment_Data();
    this.setState({
      provinces: provincesList
    })
    this.getPermissionList();
  }
  render() {
    const { provinces, searchValue, status, serviceList, index, serviceModal, currentService,
      areaModal, permissionModal, permissionList, mdypwd, pwd, province,
    } = this.state;
    this.colums = [
      {
        title: '客服姓名',
        align: 'center',
        width: 180,
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '系统账号',
        align: 'center',
        width: 120,
        dataIndex: 'uid',
        key: 'uid',
      },
      {
        title: '手机',
        align: 'center',
        width: 120,
        dataIndex: 'phone',
        key: 'phone',
      },
      {
        title: '账号启用状态',
        align: 'center',
        width: 120,
        dataIndex: 'enable',
        key: 'enable',
      },
      {
        title: '用户权限',
        align: 'center',
        width: 180,
        dataIndex: 'roles',
        key: 'roles',
        render: (text, record, index) => {
          return (
            <div className='row-w'>
              {
                record.roles.map((item, indexs) => {
                  let name = this.getPermissionName(item.role);
                  return (
                    <div className={` ${styles['permiss']} row`} key={indexs}>
                      <div className='margin-r5'>{name}</div>
                      <Popconfirm
                        title='确认是否删除'
                        onConfirm={this.deletePermission.bind(this, record, indexs)}
                        okText="确认"
                        cancelText="取消"
                      >
                        <CloseOutlined />
                      </Popconfirm>
                    </div>
                  );
                })
              }
              {record.roles.length < 1 &&
                <Button onClick={this.showPermissionModal.bind(this, record, index)} type='primary' size={'small'} key={index}>新增权限</Button>
              }
            </div>
          )
        }
      },
      {
        title: '负责区域',
        align: 'center',
        width: 780,
        dataIndex: 'areas',
        key: 'areas',
        render: (text, record, index) => {
          return (
            <div className='row-w'>
              {
                record.areas.map((item, indexs) => {
                  return (
                    <div className={` ${styles['permiss']} row`} key={indexs}>
                      <div>{item.area}</div>
                      <Popconfirm
                        title='确认是否删除'
                        onConfirm={this.deleteArea.bind(this, record, indexs)}
                        okText="确认"
                        cancelText="取消"
                      >
                        <CloseOutlined className='padding-lr3' />
                      </Popconfirm>
                    </div>
                  );
                })
              }
              <Button onClick={this.showAreaModal.bind(this, record, index)} type='primary' size={'small'} key={index}>新增区域</Button>
            </div>
          )
        }
      },
      {
        title: '操作',
        align: 'center',
        width: 130,
        dataIndex: 'operate',
        key: 'operate',
        render: (text, record, index) => {
          return (
            <div className='row'>
              <div className={styles['columns2-copy-button']} onClick={this.showEdiModal.bind(this, record, index)}>编辑</div>
            </div>
          );
        }
      },
    ];
    const areaSelectList = provincesList.map((item, index) => {
      let locate = currentService.areas.findIndex(aitem => aitem.area === item);
      let option = {
        label: item,
        value: item,
        disabled: locate < 0 ? false : true,
      }
      return option;
    })
    return (
      <div className='col-t w100'>
        <div className='col-t vertical-lt padding20 back-color0 w100'>
          <div className=' row-l '>
            <div>客服信息:</div>
            <Input onChange={this.changeSummary.bind(this, 'searchValue')} value={searchValue} placeholder='请输入客服信息' className={styles['input-service']} />
            <div className='margin-l20'>启用状态:</div>
            <Radio.Group onChange={this.changeSummary.bind(this, 'status')} value={status} size='large'>
              {
                statusList.map((item, index) => {
                  return <Radio value={item} key={index} checked={status === item ? true : false} className='fz16 margin-l5'>{item}</Radio>;
                })
              }
            </Radio.Group>
            <Button type='primary' onClick={this.toSearch} className='margin-l20'>搜索</Button>
            <Button type='primary' onClick={this.addServiceModal} className='margin-l20'>新增</Button>
          </div>
        </div>
        <div className=' back-color0 margin-t10'>
          <Table
            columns={this.colums}
            dataSource={serviceList}
            size={'small'}
            rowKey={record => record.id}
            bordered
            pagination={false}
          />
          <PageTurning pageMaps={this.pageMaps} ref={this.pageModal} />
        </div>
        <Modal
          title='客服信息'
          visible={serviceModal}
          width={700}
          onCancel={this.hideModal}
          onOk={this.upLoad}
          okText='确定'
          cancelText='取消'
        >
          <div className='col-t vertical-lt'>
            <div className='row-l padding-tb10'>
              <div className={styles['property']}>客服姓名:</div>
              <Input onChange={this.changeSummary.bind(this, 'name')} value={currentService.name} placeholder='请输入客服姓名' className={styles['input-service']} />
            </div>
            <div className='row-l padding-tb10'>
              <div className={styles['property']}>系统账号:</div>
              <Input onChange={this.changeSummary.bind(this, 'uid')} value={currentService.uid} placeholder='请输入系统账号' className={styles['input-service']} />
            </div>
            <div className='row-l padding-tb10'>
              <div className={styles['property']}>手机:</div>
              <Input onChange={this.changeSummary.bind(this, 'phone')} value={currentService.phone} placeholder='请输入客服手机' className={styles['input-service']} />
            </div>
            <div className='row-l padding-tb10'>
              <div className={styles['property']}>账号启用状态:</div>
              <Radio.Group onChange={this.changeSummary.bind(this, 'enable')} value={currentService.enable} size='large'>
                {
                  enableList.map((item, index) => {
                    return <Radio value={item} key={index} checked={currentService.enable === item ? true : false} className='fz16'>{item}</Radio>;
                  })
                }
              </Radio.Group>
            </div>
            <div className='row-l padding-tb10'>
              <div className={styles['property']}>是否重置密码:</div>
              <Checkbox checked={mdypwd} onChange={this.changeSummary.bind(this, 'reset')} />
              <Input disabled={!mdypwd} onChange={this.changeSummary.bind(this, 'repwd')} value={pwd} placeholder='请输入密码' className={styles['input-service2']} />
            </div>
          </div>
        </Modal>
        <Modal
          title='新增负责区域'
          visible={areaModal}
          width={700}
          onCancel={this.hideModal}
          onOk={this.addMyArea}
          okText='确定'
          cancelText='取消'
        >
          <div className='col-t vertical-lt'>
            <div className='margin-b10 fz18'>选择区域：</div>
            <div className='row-w'>
              {
                provincesList.map((item, index) => {
                  let isDisabled = currentService.areas.findIndex(aitem => aitem.area === item) < 0 ? false : true;
                  let select = province.indexOf(item) < 0 ? false : true;
                  let isCheck = isDisabled ? true : select
                  return (
                    <Checkbox
                      disabled={isDisabled}
                      checked={isCheck}
                      key={index}
                      onChange={this.selectArea.bind(this, item)}
                    >
                      {item}
                    </Checkbox>
                  );
                })
              }
            </div>
          </div>
        </Modal>
        <Modal
          title='新增权限'
          visible={permissionModal}
          width={700}
          onCancel={this.hideModal}
          onOk={this.addMyPerssion}
          okText='确定'
          cancelText='取消'
        >
          <div className='col-t vertical-lt'>
            <div className='row-l'>
              <div>新增权限:</div>
              <Select onChange={this.changeSummary.bind(this, 'permission')} className={styles['input-service']} placeholder='请选择新增权限'>
                {
                  permissionList.map((item, index) => {
                    return (
                      <Option key={index} value={item.id}>{item.name}</Option>
                    );
                  })
                }
              </Select>
            </div>
          </div>
        </Modal>
      </div >
    );

  }
  //换页
  pageMaps = ({ skip, take }) => {
    this.setState({
      skip: skip,
    }, async () => this.getData())
  }
  toSearch = () => {
    this.setState({
      skip: 0
    }, () => {
      this.getData()
      this.pageModal.current.returnFirst();
    })
  }
  getData = async () => {
    let { searchValue, status, skip, take } = this.state;
    let enable = status === '全部' ? status : (status === '启用' ? '是' : '否');
    let res = await getServiceList(searchValue, enable, skip, take);
    if (res.status === 200) {
      this.setState({
        serviceList: res.data
      })
    }
  }
  getPermissionList = async () => {
    let res = await getPermission();
    if (res.status === 200) {
      this.setState({
        permissionList: res.data
      })
    }
  }
  changeSummary = (type, e) => {
    switch (type) {
      case 'searchValue':
        this.setState({
          searchValue: e.target.value,
        });
        break;
      case 'status':
        this.setState({
          status: e.target.value,
        });
        break;
      case 'enable':
        this.setState({
          currentService: {
            ...this.state.currentService,
            enable: e.target.value
          }
        });
        break;
      case 'name':
        this.setState({
          currentService: {
            ...this.state.currentService,
            name: e.target.value
          }
        });
        break;
      case 'uid':
        this.setState({
          currentService: {
            ...this.state.currentService,
            uid: e.target.value
          }
        });
        break;
      case 'phone':
        this.setState({
          currentService: {
            ...this.state.currentService,
            phone: e.target.value
          }
        });
        break;
      case 'permission':
        this.setState({
          permission: e
        })
        break;
      case 'reset':
        this.setState({
          mdypwd: e.target.checked
        })
      case 'repwd':
        this.setState({
          pwd: e.target.value

        });
        break;
    }
  }
  addServiceModal = () => {
    this.setState({
      serviceModal: true,
      modalType: 'add',
    })
  }
  showEdiModal = (e, index) => {
    this.setState({
      currentService: {
        ...e,
        enable: e.enable === '全部' ? e.enable : e.enable === '是' ? '启用' : '关闭'
      },
      index,
      serviceModal: true,
      modalType: 'edit',
    })
  }
  showAreaModal = (e, index) => {
    this.setState({
      areaModal: true,
      index,
      currentService: e,
    })
  }
  showPermissionModal = (e, index) => {
    this.setState({
      permissionModal: true,
      index,
      currentService: e,
    })
  }
  hideModal = () => {
    this.setState({
      serviceModal: false,
      areaModal: false,
      permissionModal: false,
      currentService: {
        id: 0,
        name: '',
        uid: '',
        pwd: '',
        enable: '启用',
        remark: '',
        phone: '',
        account: 0,
        roles: [],
        areas: [],
      },
      mdypwd: false,
      pwd: '',
      province: [],
    })
  }
  upLoad = async () => {
    const { currentService, index, modalType, mdypwd, pwd } = this.state;
    let myservice = { ...currentService };
    myservice.enable = myservice.enable === '启用' ? '是' : '否';
    if (myservice.name === '') {
      message.error('姓名未填写');
      return;
    } else if (myservice.uid === '') {
      message.error('账号未填写');
      return;
    } else if (mdypwd === true && pwd === '') {
      message.error('密码未填写');
      return;
    }
    if (mdypwd) {
      myservice.pwd = pwd;
    }
    if (modalType === 'edit') {
      let res = await modifyInfo(myservice.id, mdypwd, myservice)
      if (res.status === 204) {
        this.hideModal()
        this.getData()
      }
    } else {
      let res = await addService(myservice)
      if (res.status === 200) {
        this.hideModal()
        this.getData()
      }
    }
  }
  selectArea = (select, e) => {
    let province = [...this.state.province];
    if (e.target.checked) {
      province.push(select)
    } else {
      province = this.state.province.filter(item => item !== select)
    }
    this.setState({
      province,
    });
  }
  deleteArea = async (e, index) => {
    let serviceList = _.cloneDeep(this.state.serviceList)
    let res = await delArea(e.id, e.areas[index].id)
    if (res.status === 204) {
      let line = 0;
      serviceList.map((item, indexs) => {
        if (item.id === e.id) {
          line = indexs;
        }
      })
      serviceList[line].areas.splice(index, 1)
      this.setState({
        serviceList
      })
    }

  }
  addMyArea = async () => {
    let serviceList = _.cloneDeep(this.state.serviceList);
    const { index, currentService, province } = this.state;
    let res = await addArea(currentService.id, province);
    if (res.status === 200) {
      serviceList[index].areas = serviceList[index].areas.concat(res.data);
      this.setState({
        serviceList
      });
      this.hideModal();
    }
  }
  deletePermission = async (e, index) => {
    let res = await delPermission(e.id, e.roles[index].id)
    if (res.status === 204) {
      this.getData()
    }
  }
  addMyPerssion = async () => {
    const { index, currentService, permission } = this.state;
    let isNewPermission = true;
    currentService.roles.map(item => {
      if (item.role === permission) {
        isNewPermission = false;
        message.error('权限已存在');
      }
    })
    if (isNewPermission) {
      let res = await addPermission(currentService.id, permission);
      if (res.status === 200) {
        this.hideModal();
        this.getData()
      }
    }
  }
  getPermissionName = (e) => {
    let permissionList = [...this.state.permissionList];
    let name = '';
    permissionList.map(item => {
      if (item.id === e) {
        name = item.name;
      }
    })
    return name;
  }
}

export default Index;