import React, { useEffect, useState, Component } from 'react';
import styles from './modal.module.css';
import { message, Modal, Input, Button, Select, Drawer, DatePicker, Table, InputNumber, Popconfirm, } from 'antd';
import Options from '../../components/option';
import PageTurning from '../../components/pageTurning';
import { getService } from '../../services/general';
import { getDetail, addDetail, editDetail, delDetail, getProduct, getStart, } from '../../services/templateManagement';
import { getServiceList } from '../../services/optionManagement';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';
const { TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker
moment.locale('zh-cn');


class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerShow: false,
      title: [],
      navigation: {},
      navigationList: [],
      currentTemplate: {},
      currentDetail: {
        id: 0,
        script: 0,
        service: '',
        product: 0,
        name: '',
        rate: 100,
        add: 0,
        effective_date: '',
        expiry_date: '',
      },
      startingFare: {
        id: 0,
        product: 0,
        script: 0,
        service: '',
        rate: 100,
        add: 0,
        effective_date: '',
        expiry_date: '',
      },
      detailList: [],
      skip: 0,
      take: 10,
      addModal: false,
      startModal: false,
      modalType: 'add',
      startType: 'edit',
      productList: [],
    };
    this.optionRef = React.createRef();
  }
  componentDidMount() {
    this.getTitle();
  }
  render() {
    const {
      drawerShow, title, currentDetail, detailList, addModal, currentTemplate, productList,
      navigation, startingFare, startModal,
    } = this.state;
    const colums = [
      {
        title: '分类',
        align: 'center',
        width: 250,
        dataIndex: 'category',
        key: 'service',
        render: (text, record, index) => {
          return (
            <div>
              {record.mCatagroy ? record.mCatagroy + '-' + record.mType : record.group ? record.group + '-' + record.name : record.name}
            </div>);
        }
      },
      {
        title: '产品',
        align: 'center',
        width: 200,
        dataIndex: 'name',
        key: 'name',
        render: (text, record, index) => {
          return (<div>{record.mCatagroy ? record.group ? record.group + '-' + record.name : record.name : ''}</div>);
        }
      },
      {
        title: '折扣',
        align: 'center',
        width: 120,
        dataIndex: 'rate',
        key: 'rate',
      },
      {
        title: '附加',
        align: 'center',
        width: 120,
        dataIndex: 'add',
        key: 'add',
      },
      {
        title: '生效时间',
        align: 'center',
        width: 180,
        dataIndex: 'effective_date',
        key: 'effective_date',
        render: (text, record, index) => {
          return (<div>{moment(record.effective_date).format('YYYY-MM-DD')}</div>);
        }
      },
      {
        title: '失效时间',
        align: 'center',
        width: 180,
        dataIndex: 'expiry_date',
        key: 'expiry_date',
        render: (text, record, index) => {
          return (<div>{moment(record.expiry_date).format('YYYY-MM-DD')}</div>);
        }
      },
      {
        title: '操作',
        align: 'center',
        width: 260,
        dataIndex: 'weixin',
        key: 'weixin',
        render: (text, record, index) => {
          return (
            <div className='row w100'>
              <div onClick={this.editAddModal.bind(this, record)} className={`${styles['btn']} back-color8 color0 margin-r5 tc cursor-pointer`}>编辑</div>
              <Popconfirm
                title='是否删除当前产品'
                onConfirm={this.delProduct.bind(this, record)}
                okText='确认'
                cancelText='取消'
              >
                <div className={`${styles['btn']} back-color11 color0 tc cursor-pointer`}>删除</div>
              </Popconfirm>
            </div>);
        }
      },
    ];

    return (
      <Drawer
        title={`${currentTemplate.name}    价目表`}
        style={{ position: 'fixed', right: 0 }}
        visible={drawerShow}
        onClose={this.hideDrawer}
        width={1300}
      >
        <div></div>
        <Options title={title} top={0} changeMyTop={this.changeSummary.bind(this, 'navigation')} ref={this.optionRef} className='margin-b10 '>
          <div className='col-t vertical-lt margin-r20'>
            <div className='row-l margin-t20'>
              <div onClick={this.showAddModal} className={`${styles['btn']} margin-b10 back-color5 color0 tc cursor-pointer`}>新增</div>
            </div>
            <div className='col-t w100 margin-b5'>
              <div className='row-l w100 back-color2 padding-tb10'>
                <div className='w166 tc fz16'>起步价</div>
                <div className='w166 tc fz16'>折扣</div>
                <div className='w166 tc fz16'>附加</div>
                <div className='w166 tc fz16'>生效时间</div>
                <div className='w166 tc fz16'>失效时间</div>
                <div className='w166 tc fz16'>操作</div>
              </div>
              <div className='row-l w100'>
                <div className='w166 tc fz20 bold'>{startingFare.service}</div>
                <div className='w166 tc fz20 '>{startingFare.rate}%</div>
                <div className='w166 tc fz20 '>{startingFare.add}</div>
                <div className='w166 tc fz20 '>{startingFare.effective_date ? moment(startingFare.effective_date).format('YYYY-MM-DD') : ''}</div>
                <div className='w166 tc fz20 '>{startingFare.expiry_date ? moment(startingFare.expiry_date).format('YYYY-MM-DD') : ''}</div>
                <div className='w166 row fz20 '>
                  <div onClick={this.showStartModal} className={`${styles['btn']} back-color5 color0 margin-r5 tc cursor-pointer`}>编辑</div>
                </div>

              </div>
            </div>
            <Table
              columns={colums}
              dataSource={detailList}
              size={'small'}
              rowKey={record => record.id}
              bordered
              pagination={false}
            />
            <div className='back-color0 w100'>
              <PageTurning pageMaps={this.pageMaps.bind(this)} />
            </div>
          </div>
        </Options>
        <Modal
          title='产品价格'
          visible={addModal}
          width={500}
          cancelText='取消'
          okText='确认'
          onCancel={this.hideAddModal}
          onOk={this.addProductPrice}
        >
          <div className='row-l margin-t10 w100'>
            <div className='w80 tc'>服务类型：</div>
            <Select disabled onSelect={this.changeSummary.bind(this, 'product')} value={navigation.name} className={`${styles['input']} w300`} bordered={false}>
            </Select>
          </div>
          <div className='row-l margin-t10 w100'>
            <div className='w80 tc'>产品：</div>
            <Select onSelect={this.changeSummary.bind(this, 'product')} value={currentDetail.before} className={`${styles['input']} w300`} bordered={false}>
              {
                productList.map((item, index) => {
                  return (
                    <Option key={index} value={index}>
                      {item.mcatagory ? item.mcatagory + '-' : ''}{item.mtype ? item.mtype + '-' : ''}{item.group ? item.group + '-' : ''}{item.name}
                    </Option>
                  );
                })
              }
            </Select>
          </div>
          <div className='row-l margin-t10'>
            <div className='w80 tc'>折扣：</div>
            <InputNumber value={currentDetail.rate} onChange={this.changeSummary.bind(this, 'rate')} min={0} max={100} className={styles['inputs']} />%
          </div>
          <div className='row-l margin-t10'>
            <div className='w80 tc'>附加：</div>
            <InputNumber value={currentDetail.add} onChange={this.changeSummary.bind(this, 'add')} className={styles['inputs']} />
          </div>
          <div className='row-l margin-t10'>
            <div className='w80 tc'>生效时间：</div>
            <DatePicker locale={locale} value={currentDetail.effective_date} onChange={this.changeSummary.bind(this, 'effective_date')} placeholder='生效时间' className={styles['input']} />
          </div>
          <div className='row-l margin-t10'>
            <div className='w80 tc'>失效时间：</div>
            <DatePicker locale={locale} value={currentDetail.expiry_date} onChange={this.changeSummary.bind(this, 'expiry_date')} placeholder='失效时间' className={styles['input']} />
          </div>
        </Modal>

        <Modal
          title='通用配置'
          visible={startModal}
          width={450}
          cancelText='取消'
          okText='确认'
          onCancel={this.hideStartModal}
          onOk={this.editStart}
        >
          <div className='row-l margin-t10 w100'>
            <div className='w80 tc'>通用类型：</div>
            <Select disabled value={startingFare.service} className={styles['input']} bordered={false}>
            </Select>
          </div>
          <div className='row-l margin-t10'>
            <div className='w80 tc'>折扣：</div>
            <InputNumber value={startingFare.rate} onChange={this.changeSummary.bind(this, 'rates')} min={0} max={100} className={styles['inputs']} />%
          </div>
          <div className='row-l margin-t10'>
            <div className='w80 tc'>附加：</div>
            <InputNumber value={startingFare.add} onChange={this.changeSummary.bind(this, 'adds')} className={styles['inputs']} />
          </div>
          <div className='row-l margin-t10'>
            <div className='w80 tc'>生效时间：</div>
            <DatePicker locale={locale} value={startingFare.effective_date} onChange={this.changeSummary.bind(this, 'effective_dates')} placeholder='生效时间' className={styles['input']} />
          </div>
          <div className='row-l margin-t10'>
            <div className='w80 tc'>失效时间：</div>
            <DatePicker locale={locale} value={startingFare.expiry_date} onChange={this.changeSummary.bind(this, 'expiry_dates')} placeholder='失效时间' className={styles['input']} />
          </div>
        </Modal>
      </Drawer >
    );
  }
  getTitle = async () => {
    let res = await getServiceList();
    if (res.status === 200) {
      let title = res.data.map(item => {
        return item.name
      })
      this.setState({
        title,
        navigationList: res.data,
        navigation: res.data[0],
      })
    }
  }
  getDetailList = async () => {
    const { currentTemplate, navigation, skip, take } = this.state;
    let res = await getDetail(currentTemplate.id, navigation.name, skip, take)
    if (res.status === 200) {
      this.setState({
        detailList: res.data,
      })
    }
    this.getProducrList()
    this.getStartDetail()
  }
  getProducrList = async () => {
    const { navigation } = this.state;
    let res = await getProduct(navigation.id);
    if (res.status === 200) {
      this.setState({
        productList: res.data
      })
    }
  }
  getStartDetail = async () => {
    const { currentTemplate, navigation, skip, take } = this.state;
    let startingFare = {
      id: 0,
      product: 0,
      script: 0,
      service: '',
      rate: 100,
      add: 0,
      effective_date: '',
      expiry_date: '',
    };
    startingFare.service = navigation.name;
    startingFare.script = currentTemplate.id;
    let res = await getStart(currentTemplate.id, navigation.name, true, 0, 10)
    if (res.status === 200) {
      if (res.data.length > 0) {
        let start = res.data[0]
        start.effective_date = moment(start.effective_date)
        start.expiry_date = moment(start.expiry_date)
        start.rate = start.rate * 100
        startingFare = start
      }
      this.setState({
        startingFare
      })

    }
  }

  changeSummary = (type, e) => {
    const { title, navigationList, productList, } = this.state;
    let currentDetail = { ...this.state.currentDetail };
    let startingFare = { ...this.state.startingFare };
    switch (type) {
      case 'navigation':
        this.setState({
          navigation: navigationList[e],
        }, () => {
          this.getDetailList()
        });
        break;
      case 'product':
        currentDetail.product = productList[e].id;
        currentDetail.name = productList[e].name;
        let mcatagory = productList[e].mcatagory ? productList[e].mcatagory + '-' + productList[e].mtype + '-' : '';
        let group = productList[e].group ? productList[e].group + '-' : '';

        currentDetail.before = mcatagory + group + productList[e].name;
        this.setState({
          currentDetail
        });
        break;
      case 'rate':
        currentDetail.rate = e ? e : 0;
        this.setState({
          currentDetail
        });
        break;
      case 'add':
        currentDetail.add = e ? e : 0;
        this.setState({
          currentDetail
        });
        break;
      case 'effective_date':
        currentDetail.effective_date = e;
        this.setState({
          currentDetail
        });
        break;
      case 'expiry_date':
        currentDetail.expiry_date = e;
        this.setState({
          currentDetail
        });
        break;
      case 'rates':
        startingFare.rate = e ? e : 0;;
        this.setState({
          startingFare
        });
        break;
      case 'adds':
        startingFare.add = e ? e : 0;;
        this.setState({
          startingFare
        });
        break;
      case 'effective_dates':
        startingFare.effective_date = e;
        this.setState({
          startingFare
        });
        break;
      case 'expiry_dates':
        startingFare.expiry_date = e;
        this.setState({
          startingFare
        });
        break;
    }
  }
  addProductPrice = async () => {
    const { currentTemplate, navigation, modalType } = this.state
    let currentDetail = { ...this.state.currentDetail };
    if (currentDetail.product === 0 || currentDetail.effective_date === '' || currentDetail.expiry_date === '') {
      message.error('内容不完整');
      return
    }
    currentDetail.rate = currentDetail.rate / 100
    currentDetail.effective_date = moment(currentDetail.effective_date).format()
    currentDetail.expiry_date = moment(currentDetail.expiry_date).format()
    if (modalType === 'add') {
      currentDetail.script = currentTemplate.id
      currentDetail.service = navigation.name
      let res = await addDetail(currentDetail);
      if (res.status === 200) {
        this.hideAddModal()
        this.getDetailList()
      }
    } else {
      let res = await editDetail(currentDetail.id, currentDetail);
      if (res.status === 204) {
        this.hideAddModal()
        this.getDetailList()
      }
    }
  }
  delProduct = async (e) => {
    let res = await delDetail(e.id);
    if (res.status === 204) {
      this.getDetailList()
    }
  }
  editStart = async () => {
    const { startType } = this.state
    let startingFare = { ...this.state.startingFare };
    startingFare.rate = startingFare.rate / 100;
    startingFare.effective_date = moment(startingFare.effective_date).format()
    startingFare.expiry_date = moment(startingFare.expiry_date).format()
    if (startType === 'edit') {
      let res = await editDetail(startingFare.id, startingFare);
      if (res.status === 204) {
        this.hideStartModal()
        this.getStartDetail()
      }
    } else {
      let res = await addDetail(startingFare);
      if (res.status === 200) {
        this.hideStartModal()
        this.getStartDetail()
      }
    }
  }

  showDrawer = (e) => {
    this.setState({
      drawerShow: true,
      currentTemplate: e,
    }, () => this.getDetailList())
  }
  hideDrawer = () => {
    this.optionRef.current.returnFirst();
    this.setState({
      drawerShow: false,
      startingFare: {
        id: 0,
        product: 0,
        script: 0,
        service: '',
        rate: 100,
        add: 0,
        effective_date: '',
        expiry_date: '',
      },
      navigation: this.state.navigationList[0]
    })

  }
  editAddModal = (e) => {
    let currentDetail = { ...e };
    currentDetail.rate = currentDetail.rate * 100
    currentDetail.effective_date = moment(currentDetail.effective_date)
    currentDetail.expiry_date = moment(currentDetail.expiry_date)
    this.setState({
      currentDetail,
      addModal: true,
      modalType: 'edit',
    });
  }
  showAddModal = () => {
    this.setState({
      addModal: true,
      modalType: 'add',
    });
  }
  hideAddModal = () => {
    this.setState({
      addModal: false,
      currentDetail: {
        id: 0,
        script: 0,
        service: '',
        product: 0,
        name: '',
        rate: 100,
        add: 0,
        effective_date: '',
        expiry_date: '',
        before: '',
      },
    });
  }
  showStartModal = () => {
    const { startingFare } = this.state;
    let startType = 'edit';
    if (startingFare.id === 0) {
      startType = 'add';
    }
    this.setState({
      startModal: true,
      startType,
    });
  }
  hideStartModal = () => {
    this.setState({
      startModal: false
    });
  }


  //换页
  pageMaps = ({ skip, take }) => {
    this.setState({
      skip: skip,
    }, () => this.getDetailList())
  }

}

export default Index;