import React, { useEffect } from 'react';
import { Router, Redirect, Switch, Route } from 'dva/router';
import { renderRouteConfig } from '../utils/createRoot';
import Login from '../routes/login/login';
import BasicLayout from '../layouts/BasicLayout';
import { getSession } from './storage';
import _ from 'lodash';
import {
  ContainerOutlined,
  CloudServerOutlined,
  CloudDownloadOutlined,
  CustomerServiceOutlined,
  AccountBookOutlined,
  InsertRowLeftOutlined,
  SendOutlined,
  TagsOutlined
} from '@ant-design/icons';
import { KeepaliveRouterSwitch, KeepaliveRoute, addKeeperListener } from 'react-keepalive-router';


export const menuGlobal = [
  {
    path: '/',
    component: BasicLayout,
    childRoutes: [
      // {
      //     path: '/orderRenter',
      //     name: '订单中心',
      //     icon: <ContainerOutlined />,
      //     component: OrderRenter,
      // },
      // {
      //     path: 'newOrder',
      //     name: '新建订单',
      //     icon: <CloudServerOutlined />,
      //     component: NewOrder,
      // },
      // {
      //     path: 'measureData',
      //     name: '测量数据',
      //     icon: <CloudDownloadOutlined />,
      //     component: MeasureData,
      // },
      // {
      //     path: 'afterSale',
      //     name: '售后列表',
      //     icon: <CustomerServiceOutlined />,
      //     component: AfterSale,
      // },
      // {
      //     path: 'finance',
      //     name: '财务中心',
      //     icon: <AccountBookOutlined />,
      //     component: Finance,
      // },
      // {
      //     path: 'storeManagement',
      //     name: '店铺管理',
      //     icon: <InsertRowLeftOutlined />,
      //     component: StoreManagement,
      // },
      // {
      //     path: 'serviceArea',
      //     name: '服务区域',
      //     icon: <SendOutlined />,
      //     component: ServiceArea,
      // },
      // {
      //     path: 'shoppingMall',
      //     name: '墨墨商城',
      //     icon: <TagsOutlined />,
      //     component: ShoppingMall,
      // },
    ]
  }
];

export function RouterConfig({ history, app }) {
  let route = renderRouteConfig(menuGlobal, '/');
  // useEffect(() => {
  //   addKeeperListener((history, cacheKey) => {
  //     if (history) console.log('当前组件激活缓存组件' + cacheKey)
  //   })
  // }, [])
  return (
    <Router history={history} basename={'/'}>
      {/* <KeepaliveRouterSwitch> */}
      <Switch>
        <Route exact path="/login" component={Login} />
        {/* <KeepaliveRoute path="/" render={() => { return route }} /> */}
        <Route path="/" render={() => { return route }} />
        {/* <Route path="/" render={() => {
                    return getSession() ? route : <Redirect to="/login" />;
                }} /> */}
        {/* </KeepaliveRouterSwitch> */}
      </Switch>
    </Router>
  );
}
export function MenuConfig(role) {
  return menuGlobal;
}
