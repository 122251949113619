import React, { Component, } from 'react';
import { Table, Drawer, DatePicker, Button } from 'antd';
import { getMacLog } from '../../services/whiteList';
import PageTurning from '../../components/pageTurning';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';

const dateFormat = 'YYYY-MM-DD';
const today = moment().format(dateFormat);
const monthAgo = moment().subtract(1, 'month').format(dateFormat);
class RecordDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      user: {},
      info: {
        start: monthAgo,
        end: today,
      },
      data: [],
      skip: 0,
      take: 15,
    };
    this.pageTurning = React.createRef();
    this.columns = [
      {
        title: '登录账号',
        dataIndex: 'name',
        align: 'center',
        width: 200,
      },
      {
        title: '登录时间',
        dataIndex: 'time',
        align: 'center',
        width: 200,
        render: (text, record, index) => {
          return (<div>{moment(record.time).format('YYYY/MM/DD-HH:mm:ss')}</div>);
        }
      },
      {
        title: '登录状况',
        dataIndex: 'state',
        align: 'center',
        width: 150,
      },
      {
        title: '登录内容',
        dataIndex: 'content',
        align: 'center',
        width: 300,
      },
    ];
  }
  render() {
    const { visible, info, data, user, take } = this.state;
    return (
      <Drawer
        title={`${user.name} 的登录记录`}
        style={{ position: 'fixed', right: 0 }}
        visible={visible}
        onClose={this.hideDrawer}
        width={800}
        footer={
          <div className='w100 row-l'>
            <Button onClick={this.hideDrawer} type='ghost' className='margin-r20'>关闭</Button>
          </div>
        }
      >
        <div className='col-t vertical-lt'>
          <div className='row-l margin-b10'>
            <div>时间选择：</div>
            <DatePicker placeholder='开始时间' className='w198' locale={locale} format={dateFormat}
              value={moment(info.start)} onChange={this.onChangeDate.bind(this, 'start')} />
            <div className='margin-l5 margin-r5'>至</div>
            <DatePicker placeholder='结束时间' className='w198' locale={locale} format={dateFormat}
              value={moment(info.end)} onChange={this.onChangeDate.bind(this, 'end')} />
            <Button onClick={this.toSearch} type='primary'>搜索</Button>
          </div>
          <Table
            columns={this.columns}
            dataSource={data}
            size={'small'}
            rowKey={'id'}
            bordered
            pageSize={take}
            pagination={false}
          />
          <div className='w100 row-r'>
            <PageTurning pageMaps={this.pageMaps} ref={this.pageTurning} />
          </div>
        </div>
      </Drawer>
    );
  }
  onChangeDate = (type, e) => {
    let info = { ...this.state.info };
    if (e) {
      info[type] = moment(e).format(dateFormat)
      this.setState({
        info,
      });
    }
  }
  toSearch = () => {
    this.setState({
      skip: 0,
    }, async () => this.getList())
  }
  getList = async () => {
    const { user, info, skip, take } = this.state;
    let res = await getMacLog(user.id, info.start, info.end, skip, take);
    if (res.status === 200) {
      this.setState({
        data: res.data
      });
    }
  }
  hideDrawer = () => {
    this.setState({
      visible: false,
      user: {},
      data: [],
      skip: 0,
    });
    this.pageTurning.current.returnFirst();
  }
  onShow = (e, name) => {
    this.setState({
      visible: true,
      user: e,
    }, () => this.getList())
  }
  pageMaps = ({ skip, take }) => {
    this.setState({
      skip: skip,
    }, async () => this.getList())
  }
}
export default RecordDrawer;