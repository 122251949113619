import React from 'react';
import { Route } from 'dva/router';
// import dynamic from 'dva/dynamic'
import { KeepaliveRouterSwitch, KeepaliveRoute, addKeeperListener } from 'react-keepalive-router';

export function renderRouteConfig(routes, contextPath) {
  const children = [];
  const renderRoute = (item, routeContextPath) => {
    let newContextPath;
    if (/^\//.test(item.path)) {
      newContextPath = item.path;
    } else {
      newContextPath = `${routeContextPath}/${item.path}`;
    }
    newContextPath = newContextPath.replace(/\/+/g, '/');
    if (item.component && item.childRoutes) {
      const childRoutes = renderRouteConfig(item.childRoutes, newContextPath);
      children.push(
        <Route
          key={newContextPath}
          render={props => <item.component {...props}>{childRoutes}</item.component>}
          path={newContextPath}
        />
      );
    } else if (item.component) {
      children.push(
        // <KeepaliveRoute
        <Route
          key={newContextPath}
          component={item.component}
          path={newContextPath}
          exact={item.exact === null ? true : item.exact}
        />
      );
    } else if (item.childRoutes) {
      item.childRoutes.forEach(r => renderRoute(r, newContextPath));
    }
  };
  routes.forEach(item => renderRoute(item, contextPath));
  return children;
}