import React, { Component } from 'react';
import { Input, InputNumber, Modal, Radio, Button } from 'antd';
import { putDealAll } from '../../services/afterSales';
import { getDepartment_Data } from '../../config/storage';
import styles from './index.module.css';
import { getVideoPlay, } from '../../services/newOder';
import { EyeOutlined, } from '@ant-design/icons';

const mode = {
  orderId: 0,
  type: '',
  state: '待处理',
  remark: '',
  price: 0,
};
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      confirmLoading: false,
      orderInfo: {},
      data: { ...mode },
    }
    this.baseUrl = getDepartment_Data().service;
  }
  render() {
    const { confirmLoading, visible, data, orderInfo, } = this.state;
    return (
      <Modal
        title={'处理投诉'}
        visible={visible}
        width={650}
        onCancel={this.onCancel}
        onOk={this.onOk}
        okText='确定'
        cancelText='取消'
        confirmLoading={confirmLoading}
      >
        <div className='margin-b10 fz16 bold'>{data.type}</div>
        <div className='row-l margin-b10'>
          <div className='w80'>处理状态：</div>
          <Radio.Group value={data.state} onChange={this.changeInfo.bind(this, 'state')}>
            <Radio value='待处理'>待处理</Radio>
            <Radio value='已处理'>已处理</Radio>
          </Radio.Group>
        </div>
        {
          data.type === '投诉师傅' &&
          <div className='row-l margin-b10'>
            <div className='w80'>理赔金额：</div>
            <InputNumber min={0} value={data.price} onChange={this.changeInfo.bind(this, 'price')} />
          </div>
        }
        <div className='row-l'>
          <div className='w80'>备注：</div>
          <Input.TextArea rows={3} value={data.remark} onChange={this.changeInfo.bind(this, 'remark')} />
        </div>
        {
          orderInfo.pic && orderInfo.pic.length > 0 &&
          <div className='row-l vertical-lt margin-t10'>
            <div className='w80'>图片备注：</div>
            <div className='w500 row-w'>
              {
                orderInfo.pic.map((item, index) => {
                  return (
                    <div key={index} className={`${styles['content']}`}>
                      <img
                        alt="暂无图片"
                        className={`${styles['pic']}`}
                        src={`${this.baseUrl}/upload/${item}`}
                      />
                      <div className={`row ${styles['content-div']}`}>
                        <EyeOutlined
                          className={`${styles['pic-icon-E']}`}
                          onClick={this.preview.bind(this, `${this.baseUrl}/upload/${item}`)}
                          theme="outlined"
                          title="预览"
                        />
                      </div>
                    </div>
                  );
                })
              }
            </div>
          </div>

        }

        {
          orderInfo.vod && orderInfo.vod.length > 0 &&
          <div className='row-l vertical-lt margin-t10'>
            <div className='w80'>视频备注：</div>
            <div className='w500 row-w'>
              {
                orderInfo.vod.map((item, index) => {
                  return (
                    <div className={`${styles['video-box']} col-t vertical-lt`} key={index}>
                      <div className={`${styles['content-video']}`}>
                        <video controls='controls' className={styles['content-image']} src={item} />
                      </div>
                    </div>
                  );
                })

              }
            </div>
          </div>
        }
      </Modal>
    );
  }
  changeInfo = (type, e) => {
    let data = { ...this.state.data };
    switch (type) {
      case 'state':
      case 'remark':
        data[type] = e.target.value;
        break;
      case 'price':
        data[type] = e ? Number(e) : 0;
        break;
    }
    this.setState({
      data
    });
  }
  onShowModal = (e) => {
    let data = { ...mode };
    data.type = e.order_type;
    data.orderId = e.id;
    let orderInfo = e;
    orderInfo.pic = e.service_worker ? JSON.parse(e.service_worker) : [];
    orderInfo.vod = e.service_shop ? JSON.parse(e.service_shop) : [];
    let that = this;
    orderInfo.vod.map((item, index) => {
      (async () => {
        let local = index;
        let rsp = await getVideoPlay(item);
        if (rsp.status === 200) {
          orderInfo.vod[local] = rsp.data.playURL;
          that.setState({
            orderInfo
          });
        }
      })()
    })
    this.setState({
      orderInfo, data,
      visible: true
    });
  }

  onOk = async () => {
    this.setState({
      confirmLoading: true
    });

    let res = await putDealAll(this.state.data);
    this.setState({
      confirmLoading: false,
    })
    if (res.status !== 204) {
      message.error(res.data);
      return;
    }
    this.props.updateTable();
    this.onCancel();
  }
  onCancel = () => {
    this.setState({
      visible: false,
      orderInfo: {},
      data: { ...mode },
    })
  }
  preview = (url) => {
    let features = "height=500, width=800, top=100, left=100, toolbar=no, menubar=no,scrollbars=no,resizable=no, location=no, status=no";
    window.open(url, "newW", features);
  }
}

export default Index;