import React, { Component } from 'react';
import { Button, Input, message, } from 'antd';
import { getPermissInfo, putPermissInfo } from '../../services/whiteList';
import { getDepartment_Data } from '../../config/storage';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      phone: '',
    };
    this.editPermiss = getDepartment_Data().id === 87 ? true : false;
  }
  async componentDidMount() {
    let res = await getPermissInfo();
    if (res.status === 200) {
      this.setState({
        name: res.data.contact,
        phone: res.data.phone,
      })
    }
  }
  render() {
    const { name, phone } = this.state;
    return (
      <div className='back-color0 padding20'>
        <div className='row-l'>
          <div>接收人名称：</div>
          <Input disabled={!this.editPermiss} value={name} onChange={this.changeName} className='w200' />
        </div>
        <div className='row-l margin-t10'>
          <div>接收人电话：</div>
          <Input disabled={!this.editPermiss} value={phone} onChange={this.changePhone} className='w200' />
        </div>
        <Button disabled={!this.editPermiss} onClick={this.confirm} type='primary' className='w90 margin-t10'>提交</Button>
      </div>
    );
  }
  changeName = (e) => {
    this.setState({
      name: e.target.value,
    });
  }
  changePhone = (e) => {
    this.setState({
      phone: e.target.value,
    });
  }
  confirm = async () => {
    const { name, phone } = this.state;
    let res = await putPermissInfo(name, phone);
    if (res.status === 204) {
      message.success('保存成功');
    }
  }
}
export default Index