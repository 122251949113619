import request from '../utils/request';

//售后列表
export async function getServiceList({ info, state, type, sort, workt, shopt, myarea, start, end, skip, take }) {
  return await request(`platbiz/servicelist?info=${info}&state=${state}&type=${type}&sort=${sort}&workt=${workt}&shopt=${shopt}&myarea=${myarea}&start=${start}&end=${end}&skip=${skip}&take=${take}`, 'get');
}
//处理售后
export async function putDealsale(id, data) {
  return await request(`platbiz/dealsale?id=${id}`, 'put', data);
}
export async function putDealclaim(id, data) {
  return await request(`platbiz/dealclaim?id=${id}`, 'put', data);
}
export async function getDealclaim(id,) {
  return await request(`platbiz/claim?id=${id}`, 'get');
}

//处理投诉
export async function putDealAll(data) {
  return await request('platbiz/appeal', 'put', data);
}