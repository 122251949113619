import React, { Component } from 'react';
import styles from './index.module.css';
import { message, Table, Input, Select, Modal, Button, DatePicker } from 'antd';
import { getAfterSaleList } from '../../services/analysisCenter';
import * as echarts from 'echarts';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';

moment.locale('zh-cn');
const dateFormat = 'YYYY-MM-DD'

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInfo: {
        start: '',
        end: '',
      },
      source: [],
      count: [0, 0],
      quickBtn: 0,
    };
    this.colums = [
      {
        title: '日期',
        align: 'center',
        width: 800,
        dataIndex: 'day',
        key: 'day',
      },
      {
        title: '商户售后',
        align: 'center',
        width: 800,
        dataIndex: '商户售后',
        key: 'shop',
      },
      {
        title: '平台售后',
        align: 'center',
        width: 800,
        dataIndex: '平台售后',
        key: 'plat',
      },
    ];
  }
  componentDidMount() {
    this.reset()
  }
  render() {
    const { option, source, searchInfo, count, quickBtn, } = this.state;
    return (
      <div className='col-t w100'>
        <div className='col-t vertical-lt padding20 back-color0 w100'>
          <div className='row-sb w100'>
            <div className='row-l'>
              <div>下单时间：</div>
              <DatePicker onChange={this.changeSummary.bind(this, 'start')} value={searchInfo.start} locale={locale} format={dateFormat} />
              <div className='margin-l10 margin-r10'>至</div>
              <DatePicker onChange={this.changeSummary.bind(this, 'end')} value={searchInfo.end} locale={locale} format={dateFormat} />
              <Button onClick={this.search} type='primary' className='margin-l20'>搜索</Button>
            </div>
            <Button onClick={this.reset} type='primary' className={`back-color0 color1 ${styles['reset']}`}>重置</Button>
          </div>
        </div>
        <div className='back-color0 w100 margin-t20 padding20' >
          <div className='row-sb w100'>
            <div className='row-l'>
              <div className='fz18 bold'>每日售后统计</div>
              <div className={`${styles['selectDate']} color7`}>快速选择：</div>
              <div onClick={this.quickSelect.bind(this, 7)} className={quickBtn === 7 ? styles['quickly-select'] : styles['quickly']}>近7天</div>
              <div onClick={this.quickSelect.bind(this, 15)} className={quickBtn === 15 ? styles['quickly-select'] : styles['quickly']}>近15天</div>
              <div onClick={this.quickSelect.bind(this, 31)} className={quickBtn === 31 ? styles['quickly-select'] : styles['quickly']}>近31天</div>
            </div>
            <div className='row-r'>
              <div className='color7'>汇总：</div>
              <div className={`${styles['summary-finance']} row`}>
                <div className='bold'>{count[0]}</div>
                <div className='color5 margin-r5'> （商户）|</div>
                <div className='bold'>{count[1]}</div>
                <div className='color5 margin-r5'> （平台）</div>
              </div>
            </div>
          </div>
          <div>
            <div className={`${styles['count']}`} id='main'
            ></div>
          </div>
          <div className='w100 row margin-t20'>
            <div className={styles['box1']} />
            <div>商户售后</div>
            <div className={styles['box2']} />
            <div>师傅售后</div>

          </div>
        </div>
        <div className='back-color0 w100 margin-t20 padding20'>
          <Table
            dataSource={source}
            columns={this.colums}
            size={'small'}
            rowKey={record => record.date}
            bordered
          />
        </div>
      </div >
    );
  }
  create = () => {
    const { source } = this.state;
    let option = {
      color: ['#F41212', '#ECD206'],
      tooltip: {
        trigger: 'axis',
        show: true,
        axisPointer: {
          type: 'line'
        }
      },
      xAxis: {
        type: 'category',
        axisTick: {
          alignWithLabel: true
        },
      },
      yAxis: {
        type: 'value',
        max: this.state.count[0] > this.state.count[1] ? this.state.count[0] : this.state.count[1]
      },
      grid: {
        left: '1%',
        right: '1%',
        bottom: '1%',
        containLabel: true
      },
      series: [
        {
          name: '商户售后',
          type: 'bar',
          barWidth: '30%',
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'top',
                textStyle: {
                  color: '#F41212',
                  fontsize: 16
                }
              }
            }
          },
        },
        {
          name: '平台售后',
          type: 'bar',
          barWidth: '30%',
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'top',
                textStyle: {
                  color: '#ECD206',
                  fontsize: 16
                }
              }
            }
          },
        },
      ],
      dataset: {
        dimensions: ['amount', '商户售后', '平台售后'],
        source: this.state.source
      },
      dataZoom: [{
        type: 'slider',//给x轴设置滚动条
        xAxisIndex: 0,
        bottom: 0,
        height: 7,
        showDetail: false,
        startValue: 0,//滚动条的起始位置
        endValue: 31 //滚动条的截止位置（按比例分割你的柱状图x轴长度）
      },]
    };
    let chartDom = document.getElementById('main');
    let myChart = echarts.init(chartDom);
    myChart.setOption(option)
  }
  getData = async () => {
    const { searchInfo } = this.state;
    let start = moment(searchInfo.start).format(dateFormat)
    let end = moment(searchInfo.end).format(dateFormat)
    let res = await getAfterSaleList(start, end);
    if (res.status === 200) {
      let source = res.data.shopSale
      let count = [0, 0];
      source.map((item, index) => {
        item['商户售后'] = item.number;
        item['平台售后'] = res.data.workerSale[index] ? res.data.workerSale[index].number : 0;
        item.amount = moment(item.date).format('MM-DD');
        item.day = moment(item.date).format(dateFormat);
        count[0] += item.number;
        count[1] += res.data.workerSale[index] ? res.data.workerSale[index].number : 0;
        return;
      });
      this.setState({
        source, count,
      }, () => this.create());
    }
  }
  changeSummary = (type, e) => {
    let searchInfo = { ...this.state.searchInfo };
    switch (type) {
      case 'start':
        searchInfo.start = e;
        this.setState({
          searchInfo
        }); break;
      case 'end':
        searchInfo.end = e;
        this.setState({
          searchInfo
        }); break;
    }
  }
  search = () => {
    this.setState({
      quickBtn: 0
    }, () => this.getData())
  }
  quickSelect = (e) => {
    let searchInfo = {
      ...this.state.searchInfo,
      end: moment(new Date()),
      start: moment(new Date()).subtract(e, 'days'),
    }
    this.setState({
      quickBtn: e,
      searchInfo,
    }, () => this.getData())
  }
  reset = () => {
    const { } = this.state;
    let searchInfo = {
      end: moment(new Date()),
      start: moment(new Date()).subtract(31, 'days'),
    }
    this.setState({
      searchInfo,
      quickBtn: 0,
    }, () => this.getData());
  }
}

export default Index;