import React, { Component } from 'react';
import { Button, DatePicker, Input, message, Radio, Select, Table } from 'antd';
import PageTurning from '../../components/pageTurning';
import { getMacLogs } from '../../services/whiteList';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';

const dateFormat = 'YYYY-MM-DD';
const today = moment().format(dateFormat);
const monthAgo = moment().subtract(1, 'month').format(dateFormat);
class LoginHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: 0,
      start: monthAgo,
      end: today,
      skip: 0,
      take: 10,
      data: [],
    };
    this.pageTurning = React.createRef();
    this.columns = [
      {
        title: '登录账号',
        dataIndex: 'name',
        align: 'center',
        width: 200,
      },
      {
        title: '登录时间',
        dataIndex: 'time',
        align: 'center',
        width: 200,
        render: (text, record, index) => {
          return (<div>{moment(record.time).format('YYYY/MM/DD-HH:mm:ss')}</div>);
        }
      },
      {
        title: '登录状况',
        dataIndex: 'state',
        align: 'center',
        width: 150,
      },
      {
        title: '登录内容',
        dataIndex: 'content',
        align: 'center',
        width: 300,
      },
    ];
  }
  componentDidMount() {
    this.getList();
  }
  render() {
    const { start, end, data } = this.state;
    return (
      <div className=' '>
        <div className='row-l back-color0 padding10'>
          <div>时间选择：</div>
          <DatePicker placeholder='开始时间' allowClear={false} className='w198' locale={locale} format={dateFormat}
            value={moment(start)} onChange={this.onChangeDate.bind(this, 'start')} />
          <div className='margin-l5 margin-r5'>至</div>
          <DatePicker placeholder='结束时间' allowClear={false} className='w198' locale={locale} format={dateFormat}
            value={moment(end)} onChange={this.onChangeDate.bind(this, 'end')} />
          <Button onClick={this.getList} type='primary'>搜索</Button>
        </div>
        <div className='back-color0 margin-t10'>
          <Table
            columns={this.columns}
            dataSource={data}
            size={'small'}
            rowKey={'id'}
            bordered
            pagination={false}
          />
          <PageTurning pageMaps={this.pageMaps} ref={this.pageTurning} />
        </div>
      </div>
    );
  }
  onChangeDate = (type, e) => {
    if (type === 'start') {
      this.setState({
        start: moment(e).format(dateFormat),
      });
    } else {
      this.setState({
        end: moment(e).format(dateFormat),
      });
    }
    this.pageTurning.current.returnFirst();
  }
  getList = async () => {
    const { user, start, end, skip, take } = this.state;
    let res = await getMacLogs(user, start, end, skip, take);
    if (res.status === 200) {
      this.setState({
        data: res.data
      });
    }
  }
  pageMaps = ({ skip, take }) => {
    this.setState({
      skip: skip,
    }, async () => this.getList())
  }
}
export default LoginHistory;