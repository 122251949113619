import React, { Component } from 'react';
import styles from './index.module.css';
import { Message, Table, Input, InputNumber, Radio, Image, Select, Modal, Button, Popconfirm, message, } from 'antd';
import MyOption from '../../components/option';
import PageTurning from '../../components/pageTurning';
import { getService, } from '../../services/general';
import { getCoupons, addCoupons, editCoupons } from '../../services/couponsManagement';
const { Option } = Select;
const categoryList = ['安装', '测量'];
const enableList = ['启用', '禁用'];

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enable: '启用',
      skip: 0,
      take: 10,
      couponsList: [],
      setviceList: [],
      newCoupons: {
        id: 0,
        category: '',
        service: '',
        name: '',
        price: 0,
        enable: '是',
        remark: '',
      },
      modalType: 'add',
      addModal: false,
    };
    this.pageModal = React.createRef();
  }
  componentDidMount() {
    this.getServiceList();
    this.getCouponsList();
  }
  render() {
    const { enable, addModal, setviceList, newCoupons, couponsList, modalType } = this.state
    const colums = [
      {
        title: '名称',
        align: 'center',
        width: 200,
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '启用状态',
        align: 'center',
        width: 200,
        dataIndex: 'enable',
        key: 'enable',
        render: (text, record, index) => {
          return record.enable === '是' ? '启用中' : '禁用';
        }
      },
      {
        title: '类别',
        align: 'center',
        width: 200,
        dataIndex: 'service',
        key: 'service',
      },
      {
        title: '类型',
        align: 'center',
        width: 200,
        dataIndex: 'category',
        key: 'category',
      },
      {
        title: '金额',
        align: 'center',
        width: 200,
        dataIndex: 'price',
        key: 'price',
      },
      {
        title: '备注',
        align: 'center',
        width: 400,
        dataIndex: 'remark',
        key: 'remark',
      },
      {
        title: '操作',
        align: 'center',
        width: 220,
        dataIndex: 'operate',
        key: 'operate',
        render: (text, record, index) => {
          return (
            <div className='row'>
              <div onClick={this.editCoupons.bind(this, record)} className={`${styles['btn']} back-color8 color0 margin-r5 cursor-pointer`}>编辑</div>
            </div>
          )
        }
      },
    ];
    return (
      <div className='col-t w100'>
        <MyOption title={enableList} top={0} changeMyTop={this.changeSummary.bind(this, 'enable')} className='margin-b10'>
          <div className='row-l vertical-lt padding20 back-color0 w100'>
            <div onClick={this.showCouponsModal.bind(this, 'add')} className={`${styles['btn']} tc back-color5 color0 cursor-pointer`} >新增</div>
            <div onClick={this.getCouponsList} className={`${styles['btn']} tc margin-l20 back-color5 color0 cursor-pointer`} >刷新</div>
          </div>
        </MyOption>
        <Table
          columns={colums}
          dataSource={couponsList}
          size={'small'}
          rowKey={record => record.id}
          bordered
          pagination={false}
        />
        <div className='back-color0 w100'>
          <PageTurning pageMaps={this.pageMaps.bind(this)} ref={this.pageModal} />
        </div>
        <Modal
          title='新增优惠券'
          visible={addModal}
          width={500}
          okText='确认'
          cancelText='取消'
          onCancel={this.hideCouponsModal}
          onOk={this.addMyCoupons}
        >
          <div className='col-t vertical-lt'>
            <div className='row-l margin-b20'>
              <div className='w80'>名称：</div>
              <Input disabled={modalType !== 'add'} onChange={this.changeSummary.bind(this, 'name')} value={newCoupons.name} placeholder='请输入名称' className='w166' />
            </div>
            <div className='row-l margin-b20'>
              <div className='w80'>服务类型：</div>
              <Select disabled={modalType !== 'add'} onChange={this.changeSummary.bind(this, 'category')} value={newCoupons.category} className='w166' placeholder='请选择服务类型'>
                {
                  setviceList.map((item, index) => {
                    return (
                      <Option key={index} value={item}>{item}</Option>
                    );
                  })
                }
              </Select>
            </div>
            <div className='row-l margin-b20'>
              <div className='w80'>类别：</div>
              <Select disabled={modalType !== 'add'} onChange={this.changeSummary.bind(this, 'service')} value={newCoupons.service} className='w166' placeholder='请选择类别'>
                {
                  categoryList.map((item, index) => {
                    return (
                      <Option key={index} value={item}>{item}</Option>
                    );
                  })
                }
              </Select>
            </div>
            <div className='row-l margin-b20'>
              <div className='w80'>优惠金额：</div>
              <InputNumber onChange={this.changeSummary.bind(this, 'price')} min={0} value={newCoupons.price} placeholder='请输入优惠金额' className='w166' />
            </div>
            <div className='row-l margin-b20'>
              <div className='w80'>启用状态：</div>
              <Radio.Group disabled={modalType === 'add'} onChange={this.changeSummary.bind(this, 'addEnable')} value={newCoupons.enable === '是' ? '启用' : '禁用'} size='large'>
                {
                  enableList.map((item, index) => {
                    return <Radio value={item} key={index} checked={newCoupons.enable === '是' && item === '启用' ? true : false} className='fz16'>{item}</Radio>;
                  })
                }
              </Radio.Group>
            </div>
            <div className='row-l margin-b20'>
              <div className='w80'>备注：</div>
              <Input onChange={this.changeSummary.bind(this, 'remark')} value={newCoupons.remark} placeholder='备注说明' className='w166' />
            </div>
          </div>
        </Modal>
      </div >
    );
  }
  getCouponsList = async () => {
    const { enable, skip, take } = this.state;
    let newEnable = enable === '启用' ? '是' : '否';
    let res = await getCoupons(newEnable, skip, take);
    if (res.status === 200) {
      this.setState({
        couponsList: res.data
      })
    }
  }
  getServiceList = async () => {
    let res = await getService();
    if (res.status === 200) {
      this.setState({
        setviceList: res.data
      })
    }
  }
  changeSummary = (type, e) => {
    let newCoupons = { ...this.state.newCoupons };
    switch (type) {
      case 'enable':
        this.setState({
          enable: enableList[e],
          skip: 0,
        }, () => {
          this.getCouponsList();
          this.pageModal.current.returnFirst();
        });
        break;
      case 'addEnable':
        newCoupons.enable = e.target.value === '启用' ? '是' : '否'
        this.setState({
          newCoupons
        });
        break;
      case 'category':
        newCoupons.category = e
        this.setState({
          newCoupons
        });
        break;
      case 'service':
        newCoupons.service = e
        this.setState({
          newCoupons
        });
        break;
      case 'name':
        newCoupons.name = e.target.value
        this.setState({
          newCoupons
        });
        break;
      case 'price':
        newCoupons.price = e
        this.setState({
          newCoupons
        });
        break;
      case 'remark':
        newCoupons.remark = e.target.value
        this.setState({
          newCoupons
        });
        break;

    }
  }

  addMyCoupons = async () => {
    let newCoupons = { ...this.state.newCoupons };
    const { modalType } = this.state;
    if (newCoupons.category === '' || newCoupons.service === '' || newCoupons.name === '' || newCoupons.price === null) {
      message.error('数据不完整');
      return;
    }
    let res
    if (modalType === 'add') {
      res = await addCoupons(newCoupons);
    } else {
      res = await editCoupons(newCoupons.id, newCoupons.enable, newCoupons.price, newCoupons.remark);
    }
    if (res.status === 200 || res.status === 204) {
      this.hideCouponsModal()
      this.getCouponsList()
    }
  }
  editCoupons = (e) => {
    this.showCouponsModal('edit')
    this.setState({
      newCoupons: e,
    })
  }

  showCouponsModal = (type) => {
    this.setState({
      addModal: true,
      modalType: type,
    })
  }
  hideCouponsModal = () => {
    this.setState({
      addModal: false,
      newCoupons: {
        id: 0,
        category: '',
        service: '',
        name: '',
        price: 0,
        enable: '启用',
        remark: '',
      },
    })
  }
  pageMaps = ({ skip, take }) => {
    this.setState({
      skip: skip,
    }, () => this.getCouponsList())
  }
}

export default Index;