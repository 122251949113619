import request from '../utils/request';

export async function analysisAddress(address) {
  return await request(`open/address?address=${address}`, 'get');
}
export async function getCategory(category, shop) {
  return await request(`data?category=${category}&shop=${shop}`, 'get');
}
export async function getPrice(itemid, workid, shopid) {
  return await request(`data/price?itemid=${itemid}&workid=${workid}&shopid=${shopid}`, 'get');
}
//代下单
export async function postOrder(methed, orderid, shop, couponid, data) {
  return await request(`platbiz?methed=${methed}&orderid=${orderid}&shop=${shop}&couponid=${couponid}`, 'post', data);
}

//新建产品订单
export async function postNewProduct(data) {
  return await request(`lj/neworder`, 'post', data)
}
//绑定店铺
export async function putBindShop(id, data) {
  return await request(`lj/bindshop?id=${id}`, 'put', data)
}
//视频参数
export async function getVideoInfo(title, name) {
  return await request(`data/uploadvod?title=${title}&name=${name}`, 'get')
}
export async function getVideoPlay(videoId) {
  return await request(`data/playvod?videoId=${videoId}`, 'get')
}
export async function getVideoSub(videoId) {
  return await request(`data/subvod?videoId=${videoId}`, 'get')
}
//商户视频
export async function getShopVideos(id) {
  return await request(`platBiz/getvideo?id=${id}`, 'get');
}
export async function putShopVideos(id, data) {
  return await request(`platBiz/putvideo?id=${id}`, 'put', data);
}
