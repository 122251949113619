import React, { useEffect, useState, Component } from 'react';
import styles from './index.module.css';
import MyOption from '../../components/option';
import Drawer from './modal';
import { message, Table, Input, Modal, Select, InputNumber, } from 'antd';
import { getServiceList, getProductList, addProduct, editProduct } from '../../services/optionManagement';
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;
// const title = ['窗帘安装', '窗帘测量', '墙布墙纸安装', '墙布测量', '晾衣架安装', '其他安装', '其他测量', '综合费用'];
const typeList = ['品类', '独立', '其他'];
const enableList = ['启用', '禁用'];
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: [],
      navigationList: [],
      currentNavigation: {},
      groupList: [],
      type: '品类',

      group: '布帘',
      productList: [],
      currentProduct: {
        id: 0,
        service: 0,
        type: '品类',
        group: '布帘',
        name: '',
        enable: '是',
        remark: '',
        price_app: 0,
        price_oms: 0,
      },
      productModal: false,
      modalType: 'add',
    };
    this.addsModal = React.createRef();
  }
  componentDidMount() {
    this.getTitle()
  }

  render() {
    const { title, navigationList, currentNavigation, groupList, type, group, productList, currentProduct, productModal } = this.state;
    const columns = [
      {
        title: '序号',
        align: 'center',
        dataIndex: 'index',
        width: 150,
        render: (text, record, index) => {
          return <div>{index + 1}</div>;
        }
      },
      {
        title: '名称',
        align: 'center',
        dataIndex: 'name',
        width: 220,
      },
      {
        title: '启用状态',
        align: 'center',
        dataIndex: 'enable',
        width: 220,
        render: (text, record, index) => {
          return <div>{record.enable === '是' ? '启用' : '禁用'}</div>;
        }
      },
      {
        title: '备注',
        align: 'center',
        dataIndex: 'remark',
        width: 300,
      },
      {
        title: '商户价格',
        align: 'center',
        dataIndex: 'price_app',
        width: 220,
      },
      {
        title: '师傅价格',
        align: 'center',
        dataIndex: 'price_oms',
        width: 220,
      },

      {
        title: '操作',
        align: 'center',
        dataIndex: 'operation',
        width: 400,
        render: (text, record, index) => {
          return (
            <div className='w100 row'>
              <div onClick={this.editProductModal.bind(this, record)} className={`${styles['btn']} tc back-color8 color0 cursor-pointer`} >编辑</div>
              {
                currentNavigation.name === '窗帘安装' && record.type === '品类'
                  || currentNavigation.name === '墙布墙纸安装' && record.type === '品类'
                  || currentNavigation.name === '晾衣架安装' && record.type === '品类' ?
                  <div onClick={this.showDrawer.bind(this, record)} className={`${styles['btn']} tc margin-l20 back-color9 color0 cursor-pointer`} >查看叠加产品</div>
                  : ''
              }
            </div>
          );
        }
      },
    ]
    return (
      <div className='col-t'>
        <MyOption title={title} changeMyTop={this.changeSummary.bind(this, 'navigation')} className='margin-b10'>
          <div className='col-t vertical-lt padding25'>
            <div className=' row-l'>
              <div>种类：</div>
              <Select onSelect={this.changeSummary.bind(this, 'type')} value={type} className={styles['select']} bordered={false}>
                {
                  typeList.map((item, indexs) => {
                    return (
                      <Option key={indexs} value={item}>{item}</Option>
                    );
                  })
                }
              </Select>
              {
                groupList.length > 0 ?
                  <div className='row-l'>
                    <div className='margin-l20'>类型：</div>
                    <Select onSelect={this.changeSummary.bind(this, 'group')} value={group} className={styles['select']} bordered={false}>
                      {
                        groupList.map((item, indexs) => {
                          return (
                            <Option key={indexs} value={item}>{item}</Option>
                          );
                        })
                      }
                    </Select>
                  </div>
                  : ''
              }
              <div onClick={this.getProduct} className={`${styles['btn']} tc margin-l20 back-color5 color0 cursor-pointer`} >搜索</div>
              <div onClick={this.showProductModal.bind(this, 'add')} className={`${styles['btn']} tc margin-l20 back-color5 color0 cursor-pointer`} >新增</div>
            </div>
          </div>
        </MyOption>
        <div className=' back-color0 margin-t10'>
          <Table
            columns={columns}
            dataSource={productList}
            size={'small'}
            rowKey={record => record.id}
            bordered
            pagination={false}
          />
        </div>
        <Modal
          title='产品信息'
          visible={productModal}
          width={500}
          cancelText='取消'
          okText='确认'
          onCancel={this.hideProductModal}
          onOk={this.uploadProduct}
        >
          <div className='col w100'>
            <div className='row-l margin-t20 w100'>
              <div className='w80 tc'>名称 ：</div>
              <Input onChange={this.changeSummary.bind(this, 'name')} value={currentProduct.name} className={styles['input']} />
            </div>
            <div className='row-l margin-t20 w100'>
              <div className='w80 tc'>种类：</div>
              <Select disabled={true} onSelect={this.changeSummary.bind(this, 'addType')} value={currentProduct.type} className={styles['select']} bordered={false} >
                {
                  typeList.map((item, index) => {
                    return (
                      <Option key={index} value={item}>{item}</Option>
                    );
                  })
                }
              </Select>
            </div>
            {
              currentNavigation.name === '窗帘安装' && type === '品类' || currentNavigation.name === '墙布墙纸安装' && type === '品类' || currentNavigation.name === '晾衣架安装' && type === '品类' ?
                <div className='row-l margin-t20 w100'>
                  <div className='w80 tc'>类型：</div>
                  <Select disabled={true} onSelect={this.changeSummary.bind(this, 'addGroup')} value={currentProduct.group} className={styles['select']} bordered={false}>
                    {
                      groupList.map((item, indexs) => {
                        return (
                          <Option key={indexs} value={item}>{item}</Option>
                        );
                      })
                    }
                  </Select>
                </div>
                : ''
            }
            <div className='row-l margin-t20 w100'>
              <div className='w80 tc'>启用状态：</div>
              <Select onSelect={this.changeSummary.bind(this, 'enable')} value={currentProduct.enable === '是' ? '启用' : '禁用'} className={styles['select']} bordered={false}>
                {
                  enableList.map((item, indexs) => {
                    return (
                      <Option key={indexs} value={item}>{item}</Option>
                    );
                  })
                }
              </Select>
            </div>
            <div className='row-l margin-t20 w100'>
              <div className='w80 tc'>商户价格 ：</div>
              <InputNumber onChange={this.changeSummary.bind(this, 'price_app')} value={currentProduct.price_app} className={styles['input']} />
            </div>
            <div className='row-l margin-t20 w100'>
              <div className='w80 tc'>师傅价格 ：</div>
              <InputNumber onChange={this.changeSummary.bind(this, 'price_oms')} value={currentProduct.price_oms} className={styles['input']} />
            </div>
            <div className='row-l margin-t20 w100'>
              <div className='w80 tc'>备注 ：</div>
              <Input onChange={this.changeSummary.bind(this, 'remark')} value={currentProduct.remark} className={styles['input']} />
            </div>
          </div>
        </Modal>
        <Drawer ref={this.addsModal} />
      </div>
    );
  }
  getTitle = async () => {
    let res = await getServiceList();
    if (res.status === 200) {
      let title = res.data.map(item => {
        return item.name;
      })
      this.setState({
        title,
        navigationList: res.data,
        currentNavigation: res.data[0],
      }, () => {
        this.judgeGroup()
      })
    }
  }
  getProduct = async () => {
    const { currentNavigation, type, group } = this.state;
    let res = await getProductList(currentNavigation.id, type, group);
    if (res.status === 200) {
      this.setState({
        productList: res.data,
      })
    }
  }
  judgeGroup = () => {
    const { currentNavigation } = this.state;
    let group = JSON.parse(JSON.stringify(this.state.group));
    let groupList = [];
    if (currentNavigation.name === '窗帘安装') {
      groupList = ['布帘', '电动布帘', '成品帘', '电动成品帘'];
      group = groupList[0];
    } else if (currentNavigation.name === '墙布墙纸安装') {
      groupList = ['墙布', '墙纸'];
      group = groupList[0];
    } else if (currentNavigation.name === '晾衣架安装') {
      groupList = ['晾衣架'];
      group = groupList[0];
    } else if (currentNavigation.name === '窗帘测量' || currentNavigation.name === '墙布测量'
      || currentNavigation.name === '其他安装' || currentNavigation.name === '其他测量'
      || currentNavigation.name === '综合费用'
    ) {
      groupList = [];
      group = '';
    }
    this.setState({
      groupList,
      group,
    }, () => this.getProduct())
  }
  typeToGroup = (e) => {
    const { currentNavigation } = this.state;
    let group = JSON.parse(JSON.stringify(this.state.group));
    let groupList = [];
    if (e === '品类') {
      if (currentNavigation.name === '窗帘安装') {
        groupList = ['布帘', '电动布帘', '成品帘', '电动成品帘'];
        group = groupList[0];
      } else if (currentNavigation.name === '墙布墙纸安装') {
        groupList = ['墙布', '墙纸'];
        group = groupList[0];
      } else if (currentNavigation.name === '晾衣架安装') {
        groupList = ['晾衣架'];
        group = groupList[0];
      } else if (currentNavigation.name === '窗帘测量' || currentNavigation.name === '墙布测量'
        || currentNavigation.name === '其他安装' || currentNavigation.name === '其他测量'
        || currentNavigation.name === '综合费用'
      ) {
        groupList = [];
        group = '';
      }
      this.setState({
        groupList,
        group,
      })
    } else {
      groupList = [];
      group = '';
      this.setState({
        groupList,
        group,
      })
    }
  }
  changeSummary = (type, e) => {
    const { navigationList, title, currentNavigation } = this.state;
    let currentProduct = { ...this.state.currentProduct };
    switch (type) {
      case 'navigation':
        this.setState({
          currentNavigation: navigationList[e],
        }, () => {
          this.judgeGroup()
        });
        break;
      case 'type':
        this.setState({
          type: e,
        }, () => {
          this.typeToGroup(e)
        });
        break;
      case 'group':
        this.setState({
          group: e,
        });
        break;
      case 'name':
        currentProduct.name = e.target.value;
        this.setState({
          currentProduct
        });
        break;
      case 'addType':
        currentProduct.type = e;
        this.setState({
          currentProduct
        });
        break;
      case 'addGroup':
        currentProduct.group = e;
        this.setState({
          currentProduct
        });
        break;
      case 'enable':
        currentProduct.enable = e === '启用' ? '是' : '否';
        this.setState({
          currentProduct
        });
        break;
      case 'price_app':
        currentProduct.price_app = e;
        this.setState({
          currentProduct
        });
        break;
      case 'price_oms':
        currentProduct.price_oms = e;
        this.setState({
          currentProduct
        });
        break;
      case 'remark':
        currentProduct.remark = e.target.value;
        this.setState({
          currentProduct
        });
        break;
    }
  }
  uploadProduct = async () => {
    const { currentProduct, modalType, } = this.state;
    if (currentProduct.name === '') {
      message.error('名称未填写');
      return;
    }
    if (modalType === 'add') {
      let res = await addProduct(currentProduct);
      if (res.status === 200) {
        this.hideProductModal()
        this.getProduct()
      }
    } else {
      let res = await editProduct(currentProduct.id, currentProduct);
      if (res.status === 204) {
        this.hideProductModal()
        this.getProduct()
      }
    }
  }

  showDrawer = (e) => {
    const { currentNavigation } = this.state;
    this.addsModal.current.showDrawer(currentNavigation, e);
  }
  editProductModal = (e) => {
    this.setState({
      currentProduct: e,
      modalType: 'edit',
      productModal: true,
    })
  }
  showProductModal = (e) => {
    let currentProduct = { ...this.state.currentProduct };
    const { currentNavigation, type, group, } = this.state;
    currentProduct.service = currentNavigation.id;
    currentProduct.type = type;
    currentProduct.group = group;
    this.setState({
      productModal: true,
      currentProduct,
      modalType: e,
    });
  }
  hideProductModal = () => {
    const { type, group, } = this.state;
    let currentProduct = {
      id: 0,
      service: 0,
      type: type,
      group: group,
      name: '',
      enable: '是',
      remark: '',
      price_app: 0,
      price_oms: 0,
    };
    this.setState({
      productModal: false,
      currentProduct,
    });
  }

}

export default Index;