import request from '../utils/request';
//获取优惠券列表
export async function getCoupons(enable, skip, take) {
  return await request(`data/couponlist?enable=${enable}&skip=${skip}&take=${take}`, 'get');
}
//新增优惠券
export async function addCoupons(body) {
  return await request(`data/addcoupon`, 'post', body);
}
//修改优惠券
export async function editCoupons(id, enable, price, remark) {
  return await request(`data/mdycoupon?id=${id}&enable=${enable}&price=${price}&remark=${remark}`, 'put');
}





