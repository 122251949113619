import React, { Component } from 'react';
import { Button, message, Image, Select, DatePicker, Modal } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import styles from './index.module.css';
// import { Calendar } from 'react-h5-calendar'
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import panel from '../../resource/png/panel.png';
import panelt from '../../resource/png/panel-t.png';
import { getCount } from '../../services/panels';
import { getDepartment_Data } from '../../config/storage';
import { getServiceList } from '../../services/serviceManagement';
import { connect } from 'dva';
import 'moment/locale/zh-cn';

moment.locale('zh-cn');
const dateFormat = 'YYYY-MM-DD';
const date = moment(new Date()).format(dateFormat);

class Panels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      servicerName: '',
      servicerId: 0,
      today: date,
      mine: {
        id: 0,
        name: '',
        roles: 0,
      },
      selectPermiss: true,
      servicerAll: [],
      servicerSelect: [],
      titList: [],
      dataSource: {},
      visible: false,
      selectTime: date,
    }
  }
  componentDidMount() {
    this.getServicer();
  }
  render() {
    const { visible, selectTime, servicerId, today, servicerSelect, titList, dataSource, selectPermiss } = this.state;
    return (
      <div>
        <div className='row-sb padding10 back-color0 w100'>
          <div className='row-l'>
            <div className='margin-l10'>选择客服：</div>
            <Select disabled={!selectPermiss} value={servicerId} onChange={this.changeServicer} className='w166'>
              {
                servicerSelect.map((item, index) => {
                  return (
                    <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                  );
                })
              }
            </Select>
            <Button onClick={this.onSearch} type='primary' className='margin-lr20'>搜索</Button>
            <Button onClick={this.reset} >重置</Button>
          </div>
          <div className='row-l margin-r20 cursor-pointer' onClick={this.onShow}>
            <div className='fz32 margin-r20 bold'>
              {today.split('-')[2]}日
            </div>
            <div className='fz20 color7 margin-t10'>
              /{today.split('-')[1]}月 ({today.split('-')[0]}年)
            </div>
          </div>
        </div>
        <div className='back-color0 w100 margin-t10'>
          {
            titList.map((item, index) => {
              return (
                <div className='col vertical-lt padding-lr20' key={index}>
                  <div className='row-l margin-tb10'>
                    <Image src={panel} preview={false} className={`${styles['icon']} margin-r40`} />
                    <div className='fz24 margin-r40'>{item}</div>
                    <div className={styles['line']}></div>
                  </div>
                  <div className='row-w'>
                    {
                      dataSource[item].map((ditem, dindex) => {
                        return (
                          <div className={`${styles['box']} col-t vertical-lt`} key={dindex}>
                            <div className='row-sb w100 bottomLine2 padding-tb10'>
                              <div className='margin-l10 bold fz17 '>{ditem.label}（单）</div>
                              {ditem.isLinked &&
                                <RightOutlined onClick={this.jump.bind(this, ditem, item)} className='margin-r20 fz26 bold' />
                              }
                            </div>
                            <div className='row-sb w100'>
                              <div className='margin-l15 margin-t5 row-l w126 tc'>
                                <div className='fz35 color6 bold'>{ditem.total}</div>
                                <div className='fz13 color5 bold h50 lh65'>{item === '任务处理' ? '(当月)' : ''}</div>
                              </div>
                              <div className='row-r margin-r10'>
                                {
                                  Boolean(ditem.undo) &&
                                  <div className='col margin-r5'>
                                    <div className='fz24 bold'>{ditem.undo}</div>
                                    <div className='fz12 color5 bold'>{item === '任务处理' ? '今日' : ''}未处理</div>
                                  </div>
                                }
                                {
                                  Boolean(ditem.done) &&
                                  <div className='col'>
                                    <div className='fz24 bold color3'>{ditem.done}</div>
                                    <div className='fz12 color5 bold'>{item === '任务处理' ? '今日' : ''}已处理</div>
                                  </div>
                                }
                              </div>
                            </div>
                            {
                              Boolean(ditem.timeness) && ditem.label !== '投诉订单' &&
                              <div className='row-l margin-l15 margin-t5'>
                                <Image src={panelt} className={`${styles['icon2']} margin-r10`} />
                                <div className='fz17 color5 bold'>处理时效：</div>
                                <div className='fz18 bold color3 margin-r5'>{(ditem.timeness / ditem.total).toFixed(1)}</div>
                                <div className='fz17 color5 bold'>小时</div>
                              </div>
                            }
                          </div>
                        );
                      })
                    }
                  </div>
                </div>
              );
            })
          }
        </div>
        <Modal
          title='选择日期'
          width={450}
          visible={visible}
          cancelText='取消'
          okText='确定'
          onCancel={this.onCancel}
          onOk={this.onOk}
        >
          <div className='row-l'>
            <div className='w80 tc'>日期：</div>
            <DatePicker value={moment(selectTime)} onChange={this.changeTime} locale={locale} />
          </div>
        </Modal>
      </div>
    );
  }
  getServicer = async () => {
    // const { mine } = this.state;
    let rsp = getDepartment_Data();
    let mine = {
      id: rsp.id,
      name: rsp.name,
      roles: rsp.roles[0],
    };
    let res = await getServiceList('', '全部', 0, 200);
    if (res.status === 200) {
      let selectPermiss = true;
      let servicerSelect = [];
      if (mine.roles === 1 || mine.roles === 5 || mine.roles === 6) {
        selectPermiss = false;
        servicerSelect = res.data.filter(item => item.id === mine.id);
      } else if (mine.roles === 7) {
        servicerSelect = res.data.filter(item => item.roles[0] && (item.roles[0].role === 1 || item.id === mine.id));
      } else if (mine.roles === 8) {
        servicerSelect = res.data.filter(item => item.roles[0] && (item.roles[0].role === 5 || item.id === mine.id));
      } else if ([9, 11].includes(mine.roles)) {
        servicerSelect = res.data;
      }
      this.setState({
        mine,
        servicerName: rsp.name,
        servicerId: rsp.id,
        selectPermiss,
        servicerSelect,
        servicerAll: res.data,
      }, () => this.onSearch())
    }
  }
  onSearch = async () => {
    const { servicerId, today } = this.state;
    let res = await getCount(today, servicerId);
    if (res.status === 200) {
      let titList = [];
      for (let item in res.data) {
        titList.push(item)
      }
      this.setState({
        titList,
        dataSource: res.data,
      })
    }
  }
  changeServicer = (e) => {
    const { servicerSelect } = this.state;
    let current = servicerSelect.filter(item => item.id === e);
    this.setState({
      servicerName: current[0].name,
      servicerId: current[0].id,
    });
  }
  reset = () => {
    const { mine } = this.state;
    this.setState({
      servicerName: mine.name,
      servicerId: mine.id,
    }, () => this.onSearch());
  }
  jump = (e, category) => {
    const { servicerId } = this.state;
    const { dispatch } = this.props;
    if (['待分配', '待接单', '待预约'].includes(e.label) || category === '任务处理') {
      dispatch({
        type: 'orderRenter/panelToAllOrder',
        payload: e.label,
      });
      this.props.fun('全部订单汇总');
    } else if (['催单中', '加急订单', '今日上门订单', '明日上门订单', '超时未上门订单', '转上门'].includes(e.label)) {
      dispatch({
        type: 'orderRenter/panelToSum',
        payload: e.label,
      });
      this.props.fun('分类订单汇总');
    } else if (e.label === '数据整理汇总') {
      dispatch({
        type: 'orderRenter/panelToMeasure',
        payload: servicerId,
      });
      this.props.fun('测量数据');
    } else if (e.label === '数据理赔') {
      dispatch({
        type: 'orderRenter/panelToAfter',
      });
      this.props.fun('售后中心');
    }

  }
  changeTime = (e) => {
    let selectTime = e ? moment(e).format(dateFormat) : moment().format(dateFormat);
    this.setState({
      selectTime,
    })
  }
  onOk = () => {
    this.setState({
      today: this.state.selectTime,
    }, () => this.onSearch())
    this.onCancel()
  }
  onShow = () => {
    this.setState({
      visible: true,
    })
  }
  onCancel = () => {
    this.setState({
      visible: false,
      selectTime: date,
    })
  }
}
// export default Panels;
export default connect(({ orderRenter }) => (orderRenter))(Panels);