import React from 'react';
import { Form, Upload, message, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

class AliyunOSSUpload extends React.Component {
  state = {
  };

  onRemove = file => {
    const { value, onChange } = this.props;
    const files = value.filter(v => v.url !== file.url);
    if (onChange) {
      onChange(files);
    }

  };
  render() {
    const { value, onChange } = this.props;
    return (
      <Upload
        type='file'
        beforeUpload={() => {
          return false;
        }}
        fileList={[]}
        multiple={false}
        onChange={onChange}
      >
        <Button icon={<UploadOutlined />}>上传文件</Button>
      </Upload>
    );
  }
}


export default AliyunOSSUpload;