export default {
  namespace: 'orderRenter',
  state: {
    data: {},
    newOrderData: {},
    state: '',
    terms: '',
    type: '',
    service: 0,
  },
  subscriptions: {
  },
  effects: {
  },
  reducers: {
    setTerms(state, { payload }) {
      return { ...state, terms: payload };
    },
    delTerms(state, { payload }) {
      return { ...state, terms: payload };
    },
    getOrderRenterList(state, { payload }) {
      return { ...state, data: payload.data };
    },
    getNewOrderData(state, { payload }) {
      return { ...state, newOrderData: payload };
    },
    delNewOrderData(state, { payload }) {
      return { ...state, newOrderData: payload };
    },
    panelToAllOrder(state, { payload }) {
      return { ...state, state: payload };
    },
    panelToMeasure(state, { payload }) {
      return { ...state, service: payload };
    },
    panelToSum(state, { payload }) {
      return { ...state, type: payload };
    },
  }
};
