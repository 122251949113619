import React, { Component } from 'react';
import { message, Table, Select, Radio, Button, Modal, Input } from 'antd';
import styles from './index.module.css';
import PageTurning from '../../components/pageTurning';
import { getSignList, dealSign } from '../../services/masterSign';
import moment from 'moment';
import _ from 'lodash';

const titleList = ['申请中', '已拒绝', '已通过'];
const dateForm = 'YYYY-MM-DD';
const dateForm2 = 'YYYY-MM-DD HH:mm:ss';

class MasterSign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      state: '申请中',
      dataSource: [],
      skip: 0,
      take: 10,
      currentId: 0,
      signRemark: '',
      signModal: false,
      signState: '通过',
    };
  }
  componentDidMount() {
    this.getList();
  }
  render() {
    const { state, dataSource, signModal, signState, signRemark, } = this.state;
    this.colums = [
      {
        title: '提交时间',
        align: 'center',
        width: 180,
        dataIndex: 'apply_time',
        render: (text, record, index) => {
          return (<div>{moment(record.apply_time).format(dateForm2)}</div>);
        }
      },
      {
        title: '名称',
        align: 'center',
        width: 180,
        dataIndex: 'worker_name',
      },
      {
        title: '师傅电话',
        align: 'center',
        width: 180,
        dataIndex: 'worker_phone',
      },
      {
        title: '申请备注',
        align: 'center',
        width: 180,
        dataIndex: 'worker_remark',
      },
      {
        title: '申请状态',
        align: 'center',
        width: 180,
        dataIndex: 'state',
      },
      {
        title: '处理人',
        align: 'center',
        width: 180,
        dataIndex: 'checker_name',
      },
      {
        title: '处理人备注',
        align: 'center',
        width: 180,
        dataIndex: 'checker_remark',
      },
      {
        title: '处理时间',
        align: 'center',
        width: 180,
        dataIndex: 'deal_time',
        render: (text, record, index) => {
          return (<div>{moment(record.deal_time).format(dateForm) === '0001-01-01' ? '' : moment(record.deal_time).format(dateForm)}</div>);
        }
      },
      {
        title: '操作',
        align: 'center',
        width: 180,
        dataIndex: 'operate',
        render: (text, record, index) => {
          return (
            <div className='row'>
              {
                record.state !== '已通过' &&
                <div onClick={this.onShow.bind(this, record.id)} className={`${styles['btn2']} color0 back-color9 cursor-pointer`}>
                  处理
                </div>
              }
            </div>
          );
        }
      },

    ];
    return (
      <div className='col-t'>
        <div className='padding20 back-color0 w100'>
          <div className=' row-l '>
            <div>申请状态：</div>
            <Radio.Group onChange={this.changeState} value={state} size='large'>
              {
                titleList.map((item, index) => {
                  return <Radio value={item} key={index} className='fz16 margin-l5'>{item}</Radio>;
                })
              }
            </Radio.Group>
          </div>
        </div>
        <div className=' back-color0 margin-t10 w100'>
          <Table
            columns={this.colums}
            dataSource={dataSource}
            size={'small'}
            className='w100'
            rowKey={record => record.id}
            bordered
            pagination={false}
          />
          <PageTurning pageMaps={this.pageMaps} />
        </div>
        <Modal
          title='操作'
          visible={signModal}
          width={500}
          onCancel={this.hideModal}
          onOk={this.confirmSign}
          okText='确定'
          cancelText='取消'
        >
          <div className='row-l margin-b10'>
            <div className='w80'>处理结果:</div>
            <Radio.Group value={signState} onChange={this.changeSignState}>
              <Radio value={'通过'}>通过</Radio>
              <Radio value={'拒绝'}>拒绝</Radio>
            </Radio.Group>
          </div>
          <div className='row-l'>
            <div className='w80'>处理备注:</div>
            <Input value={signRemark} onChange={this.changeSignRemark} />
          </div>

        </Modal>
      </div>
    );
  }
  changeState = (e) => {
    this.setState({
      state: e.target.value,
      skip: 0
    }, async () => this.getList());
  }
  getList = async () => {
    const { state, skip, take } = this.state;
    let res = await getSignList(state, skip, take);
    if (res.status === 200) {
      this.setState({
        dataSource: res.data,
      })
    }
  }
  changeSignState = (e) => {
    this.setState({
      signState: e.target.value,
    });
  }
  changeSignRemark = (e) => {
    this.setState({
      signRemark: e.target.value,
    });
  }
  confirmSign = async () => {
    const { signState, signRemark, currentId } = this.state;
    let data = {
      id: currentId,
      state: '已' + signState,
      remark: signRemark,
    };
    let res = await dealSign(data);
    if (res.status === 204) {
      this.getList();
      this.hideModal();
    }
  }
  onShow = (id) => {
    this.setState({
      signModal: true,
      currentId: id,
    });
  }
  hideModal = () => {
    this.setState({
      signModal: false,
      currentId: 0,
      signRemark: '',
      signState: '通过',
    });
  }
  pageMaps = ({ skip, take }) => {
    this.setState({
      skip: skip,
    }, async () => this.getList())
  }
}
export default MasterSign;
