import React, { Component } from 'react';
import { message, Table, Select, Input, Button, Image } from 'antd';
import styles from './index.module.css';
import redmark from '../../resource/png/all-customer.png';
import bluemark from '../../resource/png/all-worker.png';
import Regin from '../../components/regioRegister';
import { getAllotMaster } from '../../services/orderCore';
import { analysisAddress } from '../../services/newOder';
import { toTitude } from '../../services/general';
import { Map, Marker, } from 'react-amap';

const rankList = ['全部', 'A', 'B', 'C', 'D'];

class MasterMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      provinces: ['北京市', '北京市', '朝阳区'],
      masterList: [],
      center: [0, 0],
      sum: 0,
      rank: '全部',
      address: '',
      customll: '',
      pagination: {
        current: 1,
        defaultPageSize: 5,
        pageSizeOptions: [5, 10, 15, 20],
        showSizeChanger: true,
      },
      customer: [0, 0],
      isCustomer: false,
      hasWorker: false,
      noneWorker: false,
      price: 0,
      minDistance: 0,
      searchLoading: false,
      openVisible: true,
    };
  }
  componentDidMount() {

    this.search();
  }
  render() {
    const { provinces, masterList, center, sum, pagination, rank, address, customer, isCustomer,
      hasWorker, noneWorker, price, minDistance, searchLoading, openVisible,
    } = this.state;
    const columns = [
      {
        title: '师傅姓名',
        align: 'center',
        width: 120,
        dataIndex: 'name',
        key: 'name',
        className: styles['space'],
      },
      {
        title: '师傅手机',
        align: 'center',
        width: 120,
        dataIndex: 'phone',
        key: 'phone',
        className: styles['space'],
      },
      {
        title: '师傅等级',
        align: 'center',
        width: 80,
        dataIndex: 'rank',
        key: 'rank',
        className: styles['space'],
      },
      {
        title: '工种',
        align: 'center',
        width: 160,
        dataIndex: 'work_type',
        key: 'rank',
        className: styles['space'],
        render: (text, record, index) => {
          let service = record.work_type.length > 1 ? (JSON.parse(record.work_type)).join(',') : '';
          return (
            <div>{service}</div>
          );
        }
      },
      {
        title: '区域',
        align: 'center',
        width: 80,
        dataIndex: 'district',
        key: 'rank',
        className: styles['space'],
      },
      {
        title: '备注信息',
        align: 'center',
        width: 400,
        dataIndex: 'remark',
        key: 'remark',
        className: styles['space'],
      },
    ];
    pagination.onChange = this.pageChange
    let currentMasterList = rank === '全部' ? masterList : masterList.filter(item => item.rank === rank)
    const uniqueArr = currentMasterList.filter((item, index) => currentMasterList.findIndex(i => i.id === item.id) === index);
    return (
      <div className='position w100 h100'>
        <div className={`${styles['search']} col-t vertical-lt`}>
          <div className='row-l margin-b5'>
            <Input value={address} className='w700 h40' allowClear onChange={this.inputAddress} />
            <Button onClick={this.recognize} type='primary' loading={searchLoading}
              className='w166 h40 fz18'>地址解析</Button>
          </div>
          <div className='row-l padding-b10'>
            <Regin value={[provinces[0], provinces[1], provinces[2]]} onChange={this.changeProvince} />
            <Select value={rank} onChange={this.changeRank} className='margin-l20 w80' size='large'>
              {
                rankList.map((item, index) => {
                  return <Option value={item} key={index} >{item}</Option>
                })
              }
            </Select>
            <div className='margin-l10 fz16'>当前区域合计</div>
            <div className='color2 bold fz20'>{sum}</div>
            <div className='fz16'>个师傅</div>
            <div onClick={this.open} className='padding-lr10 fz26 cursor-pointer'>⇕</div>
          </div>
          {
            openVisible &&
            <div className='col-t'>
              <Table
                columns={columns}
                dataSource={uniqueArr.filter(r => r.district.includes(provinces[2]))}
                size={'small'}
                rowKey={record => record.id}
                bordered
                total={uniqueArr.length}
                pagination={pagination}
              />
              <div className='row-l w100'>
                {hasWorker && price === 0 && <div className='color6 fz20 margin-l20'>该地区师傅资源充足, 请放心下单</div>}
                {hasWorker && price > 0 && <div className='color2 margin-l20'> 距离最近的师傅相差{minDistance}公里，需要收取远程费{price}元 </div>}
                {noneWorker && <div className='color2 margin-l20'> 该地区暂无师傅，请联系客服开发</div>}
              </div>
            </div>
          }
        </div>

        <div className={styles['map']}>
          <Map
            plugins={['ToolBar', 'Scale']}
            center={center}
            zoom={12}
            version='1.4.15'
          >
            {masterList ?
              masterList.map((item, index) => {
                return <Marker visiable={true} icon={bluemark}
                  //  ={this.getMasterName(item.name + '-' + item.rank + item.distance)}
                  position={[item.longitude, item.latitude]} key={index}
                  render={this.renderMark.bind(this, item)}
                />;
              })
              : ''
            }
            <Marker visiable={true} icon={redmark}
              label={{ content: '客户地址', direction: 'top' }}
              position={customer} key={masterList.length} />
          </Map>
        </div>
      </div>
    );
  }
  renderMark = (item) => {
    return (
      <div className='col'>
        <div className={`${styles['master']} ${item.distanceFee > 0 ? styles['nearby'] : ''}`}>
          {item.name}{item.rank ? '-' + item.rank : ''}{item.distance > 0 ? '-' + (item.distance).toFixed(1) + 'KM' : ''}
        </div>
        <Image src={bluemark} preview={false} />
      </div>
    );
  }
  open = () => {
    this.setState({
      openVisible: !this.state.openVisible
    })
  }
  search = async () => {
    const { provinces, customer, address, customll } = this.state
    this.setState({
      searchLoading: true
    });
    let masterList = [];
    let res = await getAllotMaster(provinces[0], provinces[1], provinces[2], customll, 0, 100);
    if (res.status === 200) {
      masterList = res.data.result
      if (res.data.total > 100) {
        let res2 = await getAllotMaster(provinces[0], provinces[1], provinces[2], customll, 100, 100);
        if (res2.status !== 200) {
          this.setState({
            searchLoading: false
          });
          message.error(res.data)
          return;
        }
        masterList = res.data.result.concat(masterList)
      }
      this.sureCenter(provinces[0] + provinces[1] + provinces[2]);
      this.setState({
        masterList,
        sum: res.data.total,
        searchLoading: false
      });
    } else {
      this.setState({
        searchLoading: false
      });
      message.error(res.data)
      return;
    }
  }
  getMasterName = (e) => {
    return {
      content: e,
      direction: 'top'
    }
  }
  changeProvince = (e) => {
    this.setState({
      provinces: e,
      hasWorker: false,
      noneWorker: false,
      price: 0,
      minDistance: 0,
    }, () => this.search());
  }
  sureCenter = async (e) => {
    let res = await toTitude(e)
    if (res.status === 200) {
      let location = res.data.geocodes[0].location;
      let longitude = Number(location.slice(0, location.indexOf(',')))
      let latitude = Number(location.slice(location.indexOf(',') + 1))
      this.setState({
        center: [longitude, latitude],
      })
    }
  }
  pageChange = (page, pageSize) => {
    let pagination = { ...this.state.pagination };
    pagination.current = page;
    pagination.pageSize = pageSize;
    this.setState({
      pagination
    });
  }
  changeRank = (e) => {
    this.setState({
      rank: e
    });
  }
  inputAddress = (e) => {
    this.setState({
      address: e.target.value,
    });
  }
  recognize = async () => {
    const { address, searchLoading } = this.state;
    if (searchLoading) {
      return;
    }
    if (address === '') {
      message.warning('自动识别客户信息不能为空')
      return;
    }
    let res = await analysisAddress(address)
    if (res.status !== 200) {
      message.error(res.data)
      return;
    }
    let customer = [0, 0];
    // let rsp = await toTitude(res.data.province + res.data.city + res.data.district + res.data.address)
    let rsp = await toTitude(address)
    if (rsp.status === 200) {
      let location = rsp.data.geocodes[0].location;
      let longitude = Number(location.slice(0, location.indexOf(',')));
      let latitude = Number(location.slice(location.indexOf(',') + 1));
      customer[0] = longitude
      customer[1] = latitude
      this.setState({
        customer,
      })
    }
    let noneWorker = !res.data.hasWorker;
    this.setState({
      provinces: [res.data.province, res.data.city, res.data.district],
      customer,
      hasWorker: res.data.hasWorker,
      price: res.data.disFee,
      minDistance: res.data.minDistance,
      noneWorker,
      customll: rsp.data.geocodes[0].location
    }, () => this.search());
  }
}
export default MasterMap;
