import React, { Component } from 'react';
import { Input, Button, Form, message, Image, Modal } from 'antd';
import styles from './LoginFrom.module.css';
import history from '../../config/history';
import logo from '../../resource/png/mmLogo.png';
import {
  postlogin, getPermiss, postMacAddress,
} from '../../services/login';
import { setDepartment_Data, setUser, setSession, setRequest, setOssBaseAuth, setOssBaseAuthNotice } from '../../config/storage';
import { LoadingOutlined, RollbackOutlined } from '@ant-design/icons';
import { getPermissInfo, getSelfMac } from '../../services/whiteList';

const { Item } = Form;
const phone = /^[1][0-9]{10}$/;
const applys = {
  id: 0,
  name: '',
  address: '',
  enable: '否',
  remark: '',
};
class LoginFrom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
      code: '',
      data: {
        department: 1154,
        macAddress: '',
        pwd: '',
        uid: '',
        code: '',
      },
      // 0C:9D:92:BE:6A:D2	
      count: 0,
      codeLoginLoad: false,

      apply: false,
      applyLoading: false,
      applyData: { ...applys },

      permisser: {
        name: '',
        phone: '',
      },
      connecting: false,
    };
  }
  componentDidMount() {
    this.getPermisser()

  }
  render() {
    const { loading, visible, data,
      code, count, apply, codeLoginLoad,
      applyData, applyLoading, permisser } = this.state;
    return (
      <div className='col'>
        <div className='row w100 title'>
          <Image src={logo} preview={false} />
          <div className='w100 tc color9 fz60 margin-l40'>墨墨管家平台订单系统</div>
        </div>
        <div className='row' >
          <div className={`${styles['container_position']} row`}>
            <Form className='row' onFinish={this.toInput}>
              <div className={`${styles['container_signin']} ${styles['container_form']} col-t`}>
                <div className={styles['form_title']}>登录</div>
                <Item
                  name="uid"
                  rules={[
                    {
                      required: true,
                      message: '请输入您的登陆账号'
                    }
                  ]}
                >
                  <Input className={styles['input']} placeholder="请输入手机号" />
                </Item>
                <Item
                  name="pwd"
                  rules={[
                    {
                      required: true,
                      message: '请输入您的登陆密码'
                    }
                  ]}
                >
                  <Input.Password className={styles['input']} placeholder="请输入密码" autoComplete='off' visibilityToggle={false} />
                </Item>
                <Item>
                  <div className='w100 row-sb'>
                    <Button onClick={this.applyShow} className={`${styles['btn']} margin-t50 margin-r20`} >
                      申请白名单
                    </Button>
                    <Button className={`${styles['btn']} margin-t50`} htmlType='submit'>
                      登录
                      {
                        loading && <LoadingOutlined style={{ fontSize: 16 }} spin />
                      }
                    </Button>
                  </div>
                </Item>
              </div>
            </Form>
          </div>
        </div >
        <Modal
          visible={visible}
          title='验证码授权登录'
          width={500}
          onCancel={this.onCancel}
          okText='确认'
          cancelText='取消'
          onOk={this.codeLogin}
          loading={codeLoginLoad}
        >
          <div className='margin-b10'>验证码将发送给{permisser.name}的手机（{permisser.phone}）</div>
          <div className='row-l'>
            <div>验证码：</div>
            <Input value={code} onChange={this.changeCode} className='w200' />
            <Button onClick={this.getCode} className={styles['code']}>{count < 1 ? '发送验证码' : `${count}s`}</Button>
          </div>
        </Modal>
        <Modal
          visible={apply}
          title='申请白名单'
          width={500}
          onCancel={this.applyClose}
          okText='确认'
          cancelText='取消'
          loading={applyLoading}
          onOk={this.uploadMac}
        >
          <div className='row-l'>
            <div>姓名：</div>
            <Input value={applyData.name} onChange={this.chagneApply.bind(this, 'name')} className='w200 margin-b10' />
            <div className='margin-l20 color1'>{data.macAddress ? data.macAddress : ''}</div>

          </div>
          <div className='row-l vertical-lt'>
            <div>说明：</div>
            <Input.TextArea value={applyData.remark} rows={3} onChange={this.chagneApply.bind(this, 'remark')} className='w400' />
          </div>
        </Modal>
      </div >
    );
  }
  //授权人、手机号
  getPermisser = async () => {
    let res = await getPermissInfo();
    if (res.status === 200) {
      let permisser = {
        name: res.data.contact,
        phone: res.data.phone,
      };
      this.setState({
        permisser
      });
    }
  }
  toInput = (data) => {
    let newData = {
      ...this.state.data,
      ...data
    };
    this.setState({
      data: newData,
      loading: true,
    }, () => this.toLogin());
  }
  toLogin = async () => {
    let data = { ...this.state.data };
    let rsp = await getSelfMac();
    if (rsp.status === 200) {
      data.macAddress = rsp.data;
      let res = await postlogin(data);
      if (res.status == 200) {
        setDepartment_Data(res.data);
        setUser(res.data.key);
        setSession(res.data.session);
        this.setState({
          loading: false
        })
        let oss = {
          host: res.data.service + '/',
          expiration: 0,
          key: '',
          policy: '',
          prefix: '',
          signature: '',
        }
        setOssBaseAuth(oss);
        setOssBaseAuthNotice(oss);
        history.push('/');
      } else if (res.status === 401) {
        this.setState({
          visible: true,
          loading: false,
        })
      } else {
        this.setState({
          loading: false,
        })
      }
    } else {
      message.error('未检测到墨墨安全组件的运行,将改为验证码登录');
      // let res = confirm('未检测到墨墨安全组件的运行，点击‘确认’将会下载组件，点击‘取消’将改为验证码登录');
      // if (res) {
      //   let plug = document.createElement('a')
      //   plug.setAttribute('href', '/MMServer.application');
      //   plug.click();
      // } else {
      this.setState({
        visible: true,
      })
      // }
      this.setState({
        loading: false,
      })
    }
  }
  //验证码弹窗
  onCancel = () => {
    this.setState({
      visible: false,
      code: '',
    })
  }
  //发送验证码
  getCode = async () => {
    const { count, } = this.state;
    if (count > 0) {
      return;
    }
    let data = { ...this.state.data };
    let res = await getPermiss();
    if (res.status === 200) {
      data.id = res.data;
      this.setState({
        counts: 300,
        data,
      }, () => this.countdown(300))
    }
  }
  //倒计时
  countdown = (max) => {
    const f1 = () => {
      if (max > 0) {
        max--;
        this.setState({
          count: max
        });
      } else {
        window.clearTimeout(t1);
      }
    };
    let t1 = window.setInterval(f1, 1000);
  }
  changeCode = (e) => {
    this.setState({
      code: e.target.value
    })
  }
  //验证码登录
  codeLogin = async () => {
    const { code } = this.state;
    let data = { ...this.state.data };
    data.code = code;
    this.setState({
      codeLoginLoad: true,
    })
    let res = await postlogin(data);
    if (res.status == 200) {
      this.setState({
        codeLoginLoad: false,
      })
      setDepartment_Data(res.data);
      setUser(res.data.key);
      setSession(res.data.session);
      let oss = {
        host: res.data.service + '/',
        expiration: 0,
        key: '',
        policy: '',
        prefix: '',
        signature: '',
      }
      setOssBaseAuth(oss);
      setOssBaseAuthNotice(oss);
      history.push('/');
    } else {
      this.setState({
        codeLoginLoad: false,
      })
    }
  }
  //申请白名单
  applyShow = async () => {
    let data = { ...this.state.data };
    let rsp = await getSelfMac();
    if (rsp.status === 200) {
      data.macAddress = rsp.data;
      this.setState({
        apply: true,
        data,
      })
    } else {
      message.error('未检测到墨墨安全组件的运行，请先下载组件');
      // let res = confirm('未检测到墨墨安全组件的运行，请先下载组件');
      // if (res) {
      //   let plug = document.createElement('a')
      //   plug.setAttribute('href', '/MMServer.application');
      //   plug.click();
      // }
    }
  }
  applyClose = () => {
    this.setState({
      apply: false,
      applyLoading: false,
      applyData: { ...applys },
    })
  }
  chagneApply = (type, e) => {
    let applyData = { ...this.state.applyData }
    applyData[type] = e.target.value;
    this.setState({
      applyData
    })
  }
  //提交白名单
  uploadMac = async () => {
    const { data } = this.state;
    let applyData = { ...this.state.applyData }
    if (applyData.name === '' || applyData.remark === '') {
      message.warn('内容不能为空');
    } else {
      this.setState({
        applyLoading: true
      })
      applyData.address = data.macAddress;
      let res = await postMacAddress(applyData);
      if (res.status === 200) {
        this.applyClose();
      } else {
        this.setState({
          applyLoading: false
        });
      }
    }
  }

}
export default LoginFrom;