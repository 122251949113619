import React, { Component } from 'react';
import { Modal, Image, Button, } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import styles from './index.module.css';
import { getDepartment_Data, } from '../../../config/storage';
import moment from 'moment';

const dateForm = 'YYYY/MM/DD-HH:mm:ss';

class SignModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      info: {
        appoint_time: '',
        sign_time: '',
        sign_time: '',
        sign_pic: [],
        sign_remark: '',
      },
    };
    this.baseUrl = getDepartment_Data().service;
  }
  render() {
    const { visible, info } = this.state;
    return (
      <Modal
        title={'签到信息'}
        width={700}
        visible={visible}
        onCancel={this.onCancel}
        cancelText='取消'
        footer={
          <div className='row-r'>
            <Button onClick={this.onCancel} type='primary'>关闭</Button>
          </div>
        }
      >
        <div className='col-t vertical-lt'>
          <div className='margin-b10'>预约时间：{info.appoint_time}</div>
          <div className='margin-b10'>签到时间：{info.sign_time}</div>
          <div className='margin-b10'>签到备注：{info.sign_remark}</div>
          <div className='row-l vertical-lt margin-b10'>
            <div >签到图片：</div>
            <div className='row-l wrap w500'>
              {
                info.sign_pic.map((item, index) => {
                  return (
                    <div key={index} className={`${styles['content']}`}>
                      <img
                        alt="暂无图片"
                        className={`${styles['pic2']}`}
                        src={`${this.baseUrl}/upload/${item}`}
                      />
                      <div className={`row ${styles['content-div2']}`}>
                        <EyeOutlined
                          className={`${styles['pic-icon-E']}`}
                          onClick={this.preview.bind(this, `${this.baseUrl}/upload/${item}`)}
                          theme="outlined"
                          title="预览"
                        />
                      </div>
                    </div>
                  );
                })
              }
            </div>
          </div>
        </div>
      </Modal>
    );
  }
  onShow = (e) => {
    let info = {
      appoint_time: moment(e.appoint_time).format(dateForm),
      sign_time: moment(e.sign_time).format(dateForm),
      sign_pic: e.sign_pic ? JSON.parse(e.sign_pic) : [],
      sign_remark: e.worker_remark
    };
    this.setState({
      visible: true,
      info,
    })
  }
  onCancel = () => {
    this.setState({
      visible: false,
      info: {
        appoint_time: '',
        sign_time: '',
        sign_time: '',
        sign_pic: [],
        sign_remark: '',
      },
    })
  }
  preview = (url) => {
    let features = "height=500, width=800, top=100, left=100, toolbar=no, menubar=no,scrollbars=no,resizable=no, location=no, status=no";
    window.open(url, "newW", features);
  }
}
export default SignModal;