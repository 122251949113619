import React, { Component } from 'react';
import styles from './index.module.css';
import { message, Table, Input, Radio, Image, Select, Modal, Button, DatePicker } from 'antd';
import * as echarts from 'echarts';
import { getServicerCapacityList, getServicerList } from '../../services/analysisCenter';
import { getDepartment_Data } from '../../config/storage';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';

moment.locale('zh-cn');
const dateFormat = 'YYYY-MM-DD'
const provincesList = ['北京市', '上海市', '天津市', '重庆市', '香港特别行政区', '澳门特别行政区', '黑龙江省', '辽宁省', '吉林省', '河北省', '河南省', '湖北省', '湖南省', '山东省', '山西省', '陕西省', '安徽省', '浙江省', '江苏省', '福建省', '广东省', '海南省', '四川省', '云南省', '贵州省', '青海省', '甘肃省', '江西省', '台湾省', '内蒙古自治区', '宁夏回族自治区', '新疆维吾尔自治区', '西藏自治区', '广西壮族自治区',];

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInfo: {
        servicer: 0,
        servicerName: '',
        start: '',
        end: '',
        provinces: [],
      },
      provinces: [],
      servicerList: [],
      newProvince: '',
      quickBtn: 0,
      modalShow: false,
      count: {
        '收入金额': 0,
        '支出金额': 0,
        '利润金额': 0,
      },
      source: [
        { amount: '浙江', '毛利润': '3619', '理赔金额': '498', '净利润': '8999', },
        { amount: '广东', '毛利润': '3619', '理赔金额': '498', '净利润': '8999', },
        { amount: '广西', '毛利润': '3619', '理赔金额': '498', '净利润': '8999', },
        { amount: '上海', '毛利润': '3619', '理赔金额': '498', '净利润': '8999', },
        { amount: '江苏', '毛利润': '3619', '理赔金额': '498', '净利润': '8999', },
        { amount: '云南', '毛利润': '3619', '理赔金额': '498', '净利润': '8999', },
        { amount: '贵州', '毛利润': '3619', '理赔金额': '498', '净利润': '8999', },

      ],
    };
  }
  componentDidMount() {
    // let res = getDepartment_Data();
    this.setState({
      provinces: provincesList,
      newProvince: provincesList[0],
    }, () => this.getServicer())
  }
  render() {
    const { source, searchInfo, servicerList, modalShow, newProvince, quickBtn, provinces, count } = this.state;
    return (
      <div className='col-t w100'>
        <div className='col-t vertical-lt padding20 back-color0 w100'>
          <div className='row-sb w100'>
            <div className='row-l'>
              <div className='margin-l20'>区域客服：</div>
              <Select value={searchInfo.servicer}
                onChange={this.changeSummary.bind(this, 'servicer')}
                className='w166'
              >
                {
                  servicerList.map((item, index) => {
                    return <Select.Option value={index} key={index}>{item.name}</Select.Option>;
                  })
                }
              </Select>
              <div className='margin-l20'>负责区域：</div>
              <div className={`${styles['provinces-box']} row-sb`}>
                <div className={`${styles['provinces']} row-w`}>
                  {
                    searchInfo.provinces.map((item, index) => {
                      return (
                        <div className={`${styles['btn']} row-sb`} key={index}>
                          <div className='oneline'>{item}</div>
                          <div onClick={this.delProvince.bind(this, index)} className='cursor-pointer padding-lr5'>X</div>
                        </div>
                      );
                    })
                  }
                </div>
                <div onClick={this.showModal} className={`${styles['add']} color6 fz15`}>添加</div>
              </div>
              <div className='margin-l20'>下单时间：</div>
              <DatePicker onChange={this.changeSummary.bind(this, 'start')} value={searchInfo.start} locale={locale} format={dateFormat} />
              <div className='margin-l10 margin-r10'>至</div>
              <DatePicker onChange={this.changeSummary.bind(this, 'end')} value={searchInfo.end} locale={locale} format={dateFormat} />
              <Button onClick={this.search} type='primary' className='margin-l10'>搜索</Button>
            </div>
            <Button onClick={this.reset} type='primary' className={`back-color0 color1 ${styles['reset']}`}>重置</Button>
          </div>
        </div>
        <div className='back-color0 w100 margin-t20 padding20' >
          <div className='row-sb w100'>
            <div className='row-l'>
              <div className='fz18 bold'>省份产能统计</div>
              <div className={`${styles['selectDate']} color7`}>快速选择：</div>
              <div onClick={this.quickSelect.bind(this, 7)} className={quickBtn === 7 ? styles['quickly-select'] : styles['quickly']}>近7天</div>
              <div onClick={this.quickSelect.bind(this, 15)} className={quickBtn === 15 ? styles['quickly-select'] : styles['quickly']}>近15天</div>
              <div onClick={this.quickSelect.bind(this, 31)} className={quickBtn === 31 ? styles['quickly-select'] : styles['quickly']}>近31天</div>
            </div>
            <div className='row-r'>
              <div className='color7'>汇总：</div>
              <div className={`${styles['summary-finance']} row`}>
                <div className='bold'>{count['收入金额']}</div>
                <div className='color5 margin-r5'> （收入）|</div>
                <div className='bold'>{count['支出金额']}</div>
                <div className='color5 margin-r5'> （支出）|</div>
                <div className='bold'>{count['利润金额']}</div>
                <div className='color5'> （利润）</div>
              </div>
            </div>
          </div>
          <div>
            <div className={`${styles['count']}`} id='main'
            />
          </div>
          <div className='w100 row margin-t20'>
            <div className={styles['box2']} />
            <div>收入金额</div>
            <div className={styles['box1']} />
            <div>支出金额</div>
            <div className={styles['box3']} />
            <div>利润金额</div>
          </div>
        </div>
        <Modal
          title='选择区域'
          width={450}
          visible={modalShow}
          cancelText='取消'
          okText='确定'
          onCancel={this.hideModal}
          onOk={this.changeSummary.bind(this, 'newProvince')}
        >
          <div className='row-l'>
            <div>新增区域：</div>
            <Select value={newProvince}
              onChange={this.changeSummary.bind(this, 'province')}
              className='w166'
            >
              {
                provinces.map((item, index) => {
                  return <Select.Option value={item} key={index}>{item}</Select.Option>;
                })
              }
            </Select>
          </div>
        </Modal>
      </div >
    );

  }
  getServicer = async () => {
    let res = await getServicerList();
    if (res.status === 200) {
      this.setState({
        servicerList: res.data
      }, () => this.reset());
    }
  }
  getData = async () => {
    const { searchInfo } = this.state;
    let start = moment(searchInfo.start).format(dateFormat)
    let end = moment(searchInfo.end).format(dateFormat)
    let res = await getServicerCapacityList(start, end, searchInfo.provinces);
    if (res.status === 200) {
      let count = {
        '收入金额': 0,
        '支出金额': 0,
        '利润金额': 0,
      };
      let source = res.data;
      source.map(item => {
        item.amount = item.province;
        item['收入金额'] = Math.abs((item.income + item.change + item.refound + item.aftersale + item.fix).toFixed(1));
        item['支出金额'] = Math.abs((item.pay + item.claim + item.rebate + item.praise + item.wchange).toFixed(1));
        item['利润金额'] = (item['收入金额'] - item['支出金额']).toFixed(1);
        count['收入金额'] += Number(item['收入金额']);
        count['支出金额'] += Number(item['支出金额']);
        count['利润金额'] += Number(item['利润金额']);
        return;
      })
      this.setState({
        source, count,
      }, () => this.create());
    }
  }
  create = () => {
    let option = {
      color: ['#FFC439', '#F41212', '#07C160'],
      tooltip: {
        trigger: 'axis',
        show: true,
        axisPointer: {
          type: 'line'
        }
      },
      xAxis: {
        type: 'category',
        axisTick: {
          alignWithLabel: true
        },
      },
      yAxis: {
        type: 'value'
      },
      grid: {
        left: '1%',
        right: '1%',
        bottom: '1%',
        containLabel: true
      },
      series: [
        {
          type: 'bar',
          barWidth: '25%',
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'top',
                textStyle: {
                  color: '#FFC439',
                  fontsize: 16
                }
              }
            }
          },
        },
        {
          type: 'bar',
          barWidth: '25%',
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'top',
                textStyle: {
                  color: '#F41212',
                  fontsize: 16
                }
              }
            }
          }
        },
        {
          type: 'bar',
          barWidth: '25%',
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'top',
                textStyle: {
                  color: '#07C160',
                  fontsize: 16
                }
              }
            }
          }
        },
      ],
      dataset: {
        dimensions: ['amount', '收入金额', '支出金额', '利润金额'],
        source: this.state.source
      },
      dataZoom: [{
        type: 'slider',//给x轴设置滚动条
        xAxisIndex: 0,
        bottom: 0,
        height: 7,
        showDetail: false,
        startValue: 0,//滚动条的起始位置
        endValue: 5 //滚动条的截止位置（按比例分割你的柱状图x轴长度）
      },]
    };
    let chartDom = document.getElementById('main');
    let myChart = echarts.init(chartDom);
    myChart.setOption(option)
  }
  changeSummary = (type, e) => {
    const { servicerList, newProvince } = this.state;
    let searchInfo = { ...this.state.searchInfo };
    switch (type) {
      case 'type':
        searchInfo.type = e;
        this.setState({
          searchInfo
        }); break;
      case 'start':
        searchInfo.start = e;
        this.setState({
          searchInfo
        }); break;
      case 'end':
        searchInfo.end = e;
        this.setState({
          searchInfo
        }); break;
      case 'servicer':
        searchInfo.servicer = servicerList[e].name;
        searchInfo.provinces = servicerList[e].areas.map(item => { return item.area; });
        this.setState({
          searchInfo
        }); break;
      case 'province':
        this.setState({
          newProvince: e
        }); break;
      case 'newProvince':
        searchInfo.provinces.push(newProvince);
        this.setState({
          searchInfo,
          modalShow: false
        }); break;

    }
  }
  search = () => {
    this.setState({
      quickBtn: 0
    }, () => this.getData())
  }
  delProvince = (e) => {
    let searchInfo = { ...this.state.searchInfo };
    searchInfo.provinces = searchInfo.provinces.filter((item, index) => index !== e)
    this.setState({
      searchInfo
    });
  }
  quickSelect = (e) => {
    let searchInfo = {
      ...this.state.searchInfo,
      end: moment(new Date()),
      start: moment(new Date()).subtract(e, 'days'),
    }
    this.setState({
      quickBtn: e,
      searchInfo,
    }, () => this.getData())
  }
  reset = () => {
    const { servicerList } = this.state;
    let searchInfo = {
      servicer: servicerList[0].name,
      provinces: servicerList[0].areas.map(item => { return item.area; }),
      end: moment(new Date()),
      start: moment(new Date()).subtract(31, 'days'),
    }
    this.setState({
      searchInfo,
      quickBtn: 0,
    }, () => this.getData());
  }
  showModal = () => {
    this.setState({
      modalShow: true
    });
  }
  hideModal = () => {
    this.setState({
      modalShow: false
    });
  }
}

export default Index;