import React, { Component } from 'react';
import styles from './index.module.css';
import { connect } from 'dva';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { message, Table, Input, Select, Image, Checkbox, Button, DatePicker } from 'antd';
import measure from '../../resource/png/measureData-measure.png';
import link from '../../resource/png/masterManagement-link.png';
import Option from '../../components/option';
import PageTurning from '../../components/pageTurning';
import { getExcel } from '../../services/orderCore';
import { getMeasureList, getMeasureList2 } from '../../services/measureData';
import { getCheckServicer, puOrderData } from '../../services/orderCore';
import { getServiceList } from '../../services/serviceManagement';
import { getDepartment_Data } from '../../config/storage';
import WorkerModal from '../orderCore/allOrder/workerModal';
import IndexModal from './indexModal';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import _ from 'lodash';

const title = ['待整理', '全部', '已整理'];
const dateForm = 'YYYY-MM-DD';
const month = moment().subtract(1, 'month').format(dateForm);
const today = moment().format(dateForm);
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      info: '',
      titleType: '待整理',
      myCheckbox: true,
      shop: {
        id: 0,
        name: '全部',
      },
      data: [],
      start: month,
      end: today,
      skip: 0,
      take: 10,
      pageIndex: 1,
      servicerId: 0,//客服
      servicerAll: [],
    }
    this.pageSize = 10;

    this.indexModal = React.createRef();
    this.workerModal = React.createRef();
    this.option = React.createRef();
  }
  componentDidMount() {
    let servicerId = 0;
    let titleType = '待整理';
    if (this.props.service > 0) {
      servicerId = this.props.service
      this.props.dispatch({
        type: 'orderRenter/panelToMeasure',
        payload: 0,
      });
      titleType = '已整理';
      this.option.current.outSideChange(2);
    }
    this.setState({
      servicerId, titleType
    }, () => this.getDataList());
    this.getServicer(this.props.service);
  }
  render() {
    const { myCheckbox, data, shop, servicerId, servicerAll, titleType, start, end } = this.state;
    let columns = [
      {
        title: '订单信息',
        align: 'center',
        dataIndex: 'orderInfo',
        width: 180,
        className: 'padding0',
        render: (text, record, index) => {
          return (
            <div className='col'>
              <div className='w100 row-l'>
                {/* <Image className={styles['img']} src={measure} preview={false} /> */}
                <div className={styles['img']} ></div>
              </div>
              {record.urge_contact === '是' && record.collate_state !== '已整理' && <div className={styles['urgeOrder-type']}><div className={styles['left-top-type-span']}>催单</div></div>}
              {record.urge_order === '是' && record.collate_state !== '已整理' && <div className={styles['urgeOrder-type-right']}><div className={styles['right-top-type-span']}>加急</div></div>}
              <div className={`${styles['order-id']} margin-b15 fz16 color3`}>{record.id}</div>
              <div className='margin-b15 row'>
                <div className='fz14'>{record.category} -</div>
                <div className='bold'>{record.type ? record.type : '正常订单'}</div>
              </div>
              <div className='margin-b20 color2'>{record.collate_state}</div>
            </div>
          );
        }
      },
      {
        title: '商户信息',
        align: 'center',
        dataIndex: 'shop',
        width: 160,
        render: (text, record, index) => {
          return (
            <div>
              <div>{record.shop}</div>
              <div className='color2'>{record.model === '新版' ? '提供成品尺' : ''}</div>
            </div>
          );
        }
      },
      // {
      //   title: '客户姓名',
      //   align: 'center',
      //   dataIndex: 'name',
      //   width: 150,
      // },
      // {
      //   title: '客户电话',
      //   align: 'center',
      //   dataIndex: 'phone',
      //   width: 150,
      // },
      {
        title: '客户地址',
        align: 'center',
        dataIndex: 'provinces',
        width: 440,
        render: (text, record, index) => {
          return (
            <div className='col'>
              <div>{record.name},{record.phone}</div>
              <div className='row'>
                <div className='margin-r5'>{record.province + record.city + record.district + record.address}</div>
                <Image className={styles['img-address']} src={link} preview={false} onClick={this.onCopyToClip.bind(this, record)} />
              </div>
            </div>
          );
        }
      },
      {
        title: '签到时间',
        align: 'center',
        dataIndex: 'sign_time',
        width: 150,
        render: (text, record, index) => {
          let sign_time = record.sign_time === '1970-01-01T00:00:00' ? '' : this.removeT(record.sign_time);
          return (<div>{sign_time}</div>);
        }
      },
      {
        title: '上传人员',
        align: 'center',
        dataIndex: 'checker',
        width: 250,
        render: (text, record, index) => {
          let upload = record.compete_time === '0001-01-01T00:00:00' ? '' : record.self_service === '是' ? '师傅自助上传' : '客服上传';
          let time = record.compete_time === '0001-01-01T00:00:00' ? '' : moment(record.compete_time).format('YYYY/MM/DD-HH:mm:ss')
          let result = record.compete_time === '0001-01-01T00:00:00' || record.sign_time === '1970-01-01T00:00:00' ? '' : (moment(record.compete_time).unix() - moment(record.sign_time).unix()) / 60 / 60
          // return (<div>{upload}</div>);
          return (
            upload ?
              <div className='col vertical-lt w100'>
                <div className='tl w100'>人员：{upload}</div>
                <div className='tl w100'>时间：{time}</div>
                <div className='tl w100'>时效：{result ? result.toFixed(2) : ''}h</div>
              </div>
              : <div></div>
          );
        }
      },
      // {
      //   title: '上传时间',
      //   align: 'center',
      //   dataIndex: 'complete_time',
      //   width: 150,
      //   render: (text, record, index) => {
      //     return (
      //       record.compete_time === '0001-01-01T00:00:00' ? '' :
      //         <div className='row'>{this.removeT(record.compete_time)}</div>
      //     );
      //   }
      // },
      // {
      //   title: '上传时效',
      //   align: 'center',
      //   dataIndex: 'upload',
      //   width: 150,
      //   render: (text, record, index) => {
      //     let result = (moment(record.compete_time).unix() - moment(record.sign_time).unix()) / 60 / 60
      //     return (
      //       record.compete_time === '0001-01-01T00:00:00' || record.sign_time === '1970-01-01T00:00:00' ? '' :
      //         <div>
      //           {result.toFixed(2)}h
      //         </div>
      //     );
      //   }
      // },
      {
        title: '整理客服',
        align: 'center',
        dataIndex: 'checker',
        width: 250,
        render: (text, record, index) => {
          let time = record.collate_time !== '0001-01-01T00:00:00' ? moment(record.collate_time).format('YYYY/MM/DD-HH:mm:ss') : '';
          let updateTime = record.update_time !== '0001-01-01T00:00:00' ? moment(record.update_time).format('YYYY/MM/DD-HH:mm:ss') : '';
          let result = record.collate_time !== '0001-01-01T00:00:00' ? (moment(record.collate_time).unix() - moment(record.compete_time).unix()) / 60 / 60 : 0;
          return (
            record.checker ?
              <div className='col vertical-lt w100'>
                <div className='tl w100'>人员：{record.checker}</div>
                <div className='tl w100'>时间：{record.collate_state === '待整理' ? updateTime : time}</div>
                <div className='tl w100'>时效：{result.toFixed(2)}h</div>
              </div>
              : <div></div>
          );
        }
      },
      {
        title: '整理备注',
        align: 'center',
        dataIndex: 'worker_remark',
        width: 150,
        render: (text, record, index) => {
          let clear = record.worker_remark.split('✱')
          return clear[1] ? clear[1] : '';
        }
      },
      {
        title: '数据备注',
        align: 'center',
        dataIndex: 'worker_remark2',
        width: 150,
        render: (text, record, index) => {
          let clear = record.worker_remark.split('✱')
          return clear[2] ? clear[2] : '';
        }
      },
      // {
      //   title: '整理时间',
      //   align: 'center',
      //   dataIndex: 'organTime',
      //   width: 150,
      //   render: (text, record, index) => {
      //     return (
      //       record.collate_time !== '0001-01-01T00:00:00' && <div className='col'>
      //         <div>{this.removeT(record.collate_time)}</div>
      //         <div className='color5'>（最新更新）</div>
      //       </div>
      //     );
      //   }
      // },
      // {
      //   title: '整理时效',
      //   align: 'center',
      //   dataIndex: 'ratio',
      //   width: 150,
      //   render: (text, record, index) => {
      //     let result = (moment(record.collate_time).unix() - moment(record.compete_time).unix()) / 60 / 60
      //     return (
      //       record.collate_time === '0001-01-01T00:00:00' ? '' :
      //         <div>
      //           {result.toFixed(2)}h
      //         </div>
      //     );
      //   }
      // },
      {
        title: '操作',
        align: 'center',
        dataIndex: 'operation',
        width: 220,
        render: (text, record, index) => {
          return (
            <div className='w100 col'>
              <div className='row margin-b5'>
                <div className={`${styles['botton']} ${styles['botton1_back_color']} w80`}
                  onClick={this.onModalClick.bind(this, record)}
                >
                  数据整理
                </div>
                <div className={`${styles['botton']} ${styles['botton2_back_color']} w80`}
                  onClick={this.onExcelData.bind(this, record.id, '测量数据详情')}
                >
                  数据查看
                </div>
              </div>
              <div className='row'>
                <div className={`${styles['botton']} ${styles['botton3_back_color']} w80`}
                  onClick={this.onExcelData.bind(this, record.id, '测量数据下载')}
                >
                  下载  ({record.down_num})
                </div>
                <div className={`${styles['botton']} ${styles['botton3_back_color']} w80`}
                  onClick={this.pushOrderData.bind(this, record)}
                >
                  发给商家
                </div>
              </div>
            </div>
          );
        }
      },
    ]
    if (titleType === '待整理') {
      columns.splice(8, 0, {
        title: '催单时间',
        align: 'center',
        dataIndex: 'urge',
        width: 150,
        render: (text, record, index) => {
          let urges = record.serviceFollows.filter(r => r.type === '催单');
          return (
            <div>{urges.length > 0 ? moment(urges[urges.length - 1].submit_time).format('YYYY/MM/DD HH:mm') : ''}</div>
          );
        }
      },)
    }
    // else if (titleType === '已整理') {
    //   columns.splice(8, 0, {
    //     title: '完成客服',
    //     align: 'center',
    //     dataIndex: 'urge',
    //     width: 150,
    //     render: (text, record, index) => {
    //       let urges = record.serviceFollows.filter(r => r.type === '催单');
    //       return (
    //         <div>{urges.length > 0 ? moment(urges[urges.length - 1].submit_time).format('YYYY/MM/DD HH:mm') : ''}</div>
    //       );
    //     }
    //   },)
    // }
    return (
      <div className='col-t'>
        <Option title={title} top={0} changeMyTop={this.changeTop} ref={this.option}
          className='margin-b10'>
          <div className='col-t vertical-lt padding25'>
            <div className='row-l margin-b10'>
              <div>选择客服：</div>
              <Select value={servicerId} onChange={this.changeServicer} className='w166 margin-r20'>
                {
                  servicerAll.map((item, index) => {
                    return (
                      <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                    );
                  })
                }
              </Select>
              <span className={styles['title_style']}>选择商户：</span>
              <Input disabled={true} className='w198' value={shop.name} />
              <Button onClick={this.openModal.bind(this, '商户')} type='primary'>选择</Button>
              {
                titleType === '已整理' &&
                <div className='row-l'>
                  <span className={`${styles['title_style']} margin-l20`}>完成时间：</span>
                  <DatePicker.RangePicker value={[moment(start), moment(end)]}
                    onChange={this.changeTime} locale={locale} allowClear={false} />
                </div>
              }
              <span className={`${styles['title_style']} margin-l20`}>我的区域：</span>
              <Checkbox checked={myCheckbox} onChange={this.onCheckBoxOnChang} />

            </div>
            <div className=' row-l'>
              <div>客户姓名：</div>
              <Input onBlur={this.onInfo} placeholder='请输入姓名或手机号码' className={styles['input-service']} />
              <div className={`${styles['btn']} tc margin-l20 back-color5 color0 fz16`} onClick={this.getDataList}>搜索</div>
              <div className={`${styles['btn']} tc margin-l20`} onClick={this.onResetClick}>重置</div>
            </div>
          </div>
        </Option>
        <div className=' back-color0 margin-t10'>
          <Table
            columns={columns}
            dataSource={data}
            size={'small'}
            rowKey={record => record.id}
            bordered
            pagination={false}
          />
          {/* <PageTurning pageMaps={this.pageMaps} /> */}

          <div className={'row-r padding10'}>
            <div className={`${styles['pageStyle']} margin-r10`} onClick={this.changePageIndex.bind(this, '-')} ><LeftOutlined />上一页</div>
            <div className={`${styles['pageStyle']} `} onClick={this.changePageIndex.bind(this, '+')} >下一页<RightOutlined /></div>
            <div className='row-l margin-l10 fz18'>
              向第
              {
                this.state.pageEdit ?
                  <input className={`${styles['width-50']} margin-r5 margin-l5`}
                    onFocus={this.inputOnFocus}
                    onBlur={this.inputOnBlur}
                    onChange={this.onChange}
                    value={this.state.pageIndex}
                  />
                  :
                  <div className={`${styles['width-50']} ${styles['inline']} margin-r5 margin-l5`} onClick={this.onChangeDiv}>{this.state.pageIndex}</div>
              }
              页跳转
            </div>
          </div>
        </div>
        <IndexModal
          ref={this.indexModal}
          updateTable={this.getDataList}
        />
        <WorkerModal
          ref={this.workerModal}
          updateTable={this.changeShop}
        />
      </div >
    );
  }
  getServicer = async () => {
    let res = await getServiceList('', '全部', 0, 200);
    if (res.status === 200) {
      let servicerAll = res.data;
      servicerAll.unshift({
        id: 0,
        name: '全部',
      });
      this.setState({
        servicerAll,
      })
    }
  }
  changeServicer = (e) => {
    const { servicerAll } = this.state;
    let current = servicerAll.filter(item => item.id === e);
    this.setState({
      servicerId: current[0].id,
    });
  }
  onModalClick = async (e) => {
    let res = await getCheckServicer(e.id);
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    if (res.data.canOpen) {
      this.indexModal.current.onShowModal(e);
    } else {
      let rsp = window.confirm(`当前订单已被${res.data.opener}打开,是否强行打开？`);
      if (rsp) {
        this.indexModal.current.onShowModal(e);
      }
    }
  }
  onResetClick = () => {
    this.setState({
      pageIndex: 1,
      info: '',
      myCheckbox: true,
    }, async () => this.getDataList())
  }
  changeTime = (e) => {
    this.setState({
      start: moment(e[0]).format(dateForm),
      end: moment(e[1]).format(dateForm)
    })
  }
  onInfo = (e) => {
    this.setState({
      info: e.target.value,
      pageIndex: 1
    })
  }
  //选择商户
  openModal = (e) => {
    this.workerModal.current.onShowModal(e);
  }
  changeShop = (e) => {
    this.setState({
      shop: e
    })
  }
  getDataList = async () => {
    const { titleType, info, myCheckbox, shop, servicerId, start, end, } = this.state;
    let pageMaps = this.pageMap()
    let starts = titleType === '已整理' ? start : '';
    let ends = titleType === '已整理' ? end : '';
    let res = await getMeasureList(
      titleType,
      info,
      myCheckbox,
      shop.id,
      servicerId,
      pageMaps.skip,
      pageMaps.take,
      starts, ends
    )
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    this.setState({
      data: res.data
    })
  }
  changeTop = (e) => {
    this.setState({
      titleType: title[e],
      skip: 0,
      pageIndex: 1
    }, async () => this.getDataList())
  }
  onCheckBoxOnChang = (e) => {
    this.setState({
      myCheckbox: e.target.checked,
      pageIndex: 1,
    })
  }
  //发给商家
  pushOrderData = async (e) => {
    let res = await puOrderData(e.id);
    if (res.status === 200) {
      message.success('发送成功');
      return;
    }
  }
  //下载
  //Excel
  onExcelData = _.throttle(async (id, type) => {
    let res = await getExcel(id);
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    if (type === '测量数据下载') {
      window.location.href = res.data
      // this.getDataList();
      let data = _.cloneDeep(this.state.data);
      data.map(item => {
        if (item.id === id) {
          item.down_num += 1;
        }
      })
      this.setState({
        data
      });

    } else {
      let newFileUrl = encodeURIComponent(res.data)
      window.open(`https://view.officeapps.live.com/op/view.aspx?src=${newFileUrl}`, '_blank')
    }
  }, 1000)
  //复制
  onCopyToClip = (data) => {
    var aux = document.createElement("textarea");
    aux.value = `${'姓名:' + data.name + '\n' + '手机号:' + data.phone + '\n' + '地址:' + data.province + data.city + data.district + data.address
      }`;
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);
    message.success('复制成功');
  }
  removeT = (e) => {
    let date = e.split('T');
    date = date.join(' ');
    return date
  }

  //换页
  // pageMaps = ({ skip, take, pageIndex }) => {
  //   this.setState({
  //     skip: skip,
  //     pageIndex: pageIndex
  //   }, async () => this.getDataList())
  // }

  //换页
  onChangeDiv = () => {
    this.setState({
      pageEdit: true,
    });
  }

  pageMap = () => {
    return ({
      skip: (this.state.pageIndex - 1) * this.pageSize,
      take: this.pageSize
    });
  }

  onChange = (e) => {
    let value = e.target.value;
    if (value === '') {
      this.setState({
        pageIndex: ''
      });
      return;
    }
    if (isNaN(value)) {
      return;
    } else {
      let number = Number(value);
      if (number < 1 || number % 1 !== 0) {
        return;
      }
    }
    this.setState({
      pageIndex: Number(e.target.value)
    });
  }

  inputOnBlur = (e) => {
    this.setState({
      pageEdit: false,
    });
    if (this.state.pageIndex === '') {
      this.setState({
        pageIndex: this.tempPageIndex,
      });
      return;
    }
    this.getDataList();
  }

  inputOnFocus = (e) => {
    this.tempPageIndex = e.target.value;
  }

  changePageIndex = async (type) => {
    let pageIndex = _.cloneDeep(this.state.pageIndex);
    if (type === '-' && pageIndex > 1) {
      pageIndex--;
      await this.setState({
        pageIndex: pageIndex
      })
    } else if (type === '+') {
      pageIndex++;
      await this.setState({
        pageIndex: pageIndex
      })
    } else {
      message.warn('当前为第一页')
    }
    this.getDataList();
  }
}

// export default Index;
export default connect(({ orderRenter }) => (orderRenter))(Index);