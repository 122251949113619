import React, { Component } from 'react';
import { Modal, Select, Radio, Image, Button, message, Input, Table, Spin, InputNumber, Checkbox } from 'antd';
import styles from './index.module.css';
import { putDealclaim, getDealclaim } from '../../services/afterSales';
import { getServiceList } from '../../services/serviceManagement';
import WorkModal from '../../components/workModal.jsx';
import _ from 'lodash';


class AfterModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      id: 0,
      records: [],
      info: {},
      shopData: {
        price: 0,
        state: '待处理',
        remark: '',
      },
      workData: {
        price: 0,
        priceType: '理赔',
        state: '待处理',
        remark: '',
        worker: 0,
        workerName: '',
      },
      platform: {
        duty: [],
        state: '待处理',
        remark: '',
        person: [],
      },
      serviceList: [],
    };
    this.workModal = React.createRef();
    this.columns = [

      {
        title: '日期',
        align: 'center',
        dataIndex: 'submit_time',
        width: 100,
      },
      {
        title: '内容',
        align: 'center',
        dataIndex: 'content',
        width: 180,
      },
      {
        title: '类型',
        align: 'center',
        dataIndex: 'type',
        width: 100,
      },
      {
        title: '操作人',
        align: 'center',
        dataIndex: 'name',
        width: 120,
      },
    ];
  }
  componentDidMount() {
    this.getServicer();
  }
  render() {
    const { visible, serviceList, records, shopData, workData, platform, info, } = this.state;
    return (
      <Modal
        title={'售后处理'}
        width={900}
        visible={visible}
        onCancel={this.onCancel}
        cancelText='取消'
        footer={
          <div className='row-r'>
            <Button onClick={this.onCancel} type='ghost'>返回</Button>
          </div>
        }
      >
        <div className='row-l'>
          <div className={`${styles['after']} minh340 col-t vertical-lt`}>
            <div className='row-l margin-b10'>
              <div className='margin-r20'>商户处理</div>
              {
                info.sale && info.sale.shop_state ?
                  <div>{info.sale.shop_state}  ￥{info.shop}</div>
                  : <div></div>
              }
            </div>
            <div className='row-l margin-b10'>
              <div>商户金额：</div>
              <InputNumber value={shopData.price} onChange={this.changeShop.bind(this, 'price')} className='w120' />
            </div>
            <div className='row-l margin-b10'>
              <div>处理状态：</div>
              <Radio.Group value={shopData.state} onChange={this.changeShop.bind(this, 'state')}>
                <Radio value={'待处理'}>未处理</Radio>
                <Radio value={'已处理'}>已处理</Radio>
              </Radio.Group>
            </div>
            <div className='margin-b10'>
              <div>商户处理备注：</div>
              <Input.TextArea value={shopData.remark} onChange={this.changeShop.bind(this, 'remark')} rows={3} className='w220' />
            </div>
            <div className='w100 row-r'>
              <Button onClick={this.confirm.bind(this, '商户处理')} type='primary'>确认</Button>
            </div>
          </div>
          <div className={`${styles['after']} minh340 col-t vertical-lt`}>
            <div className='row-l margin-b10'>
              <div className='margin-r20'>师傅处理</div>
              {
                info.sale && info.sale.worker_state ?
                  <div>{info.sale.worker_state}  ￥{info.worker}</div>
                  : <div></div>
              }
            </div>
            <div className='row-l vertical-lt margin-b10'>
              <div>师傅：</div>
              <div className='col-t vertical-lt'>
                <div className='w180 tl'>{workData.workerName}</div>
                <Button onClick={this.showWorker} type='primary'>选择师傅</Button>
              </div>
            </div>
            <div className='row-l margin-b10'>
              <div>师傅金额：</div>
              <InputNumber value={workData.price} onChange={this.changeWork.bind(this, 'price')} className='w120' />
            </div>
            <div className='row-l margin-b10'>
              <div>处理状态：</div>
              <Radio.Group value={workData.state} onChange={this.changeWork.bind(this, 'state')}>
                <Radio value={'待处理'}>未处理</Radio>
                <Radio value={'已处理'}>已处理</Radio>
              </Radio.Group>
            </div>
            <div className='row-l margin-b10'>
              <div>类型：</div>
              <Radio.Group value={workData.priceType} onChange={this.changeWork.bind(this, 'priceType')}>
                <Radio value={'理赔'}>理赔</Radio>
                <Radio value={'奖惩'}>奖惩</Radio>
              </Radio.Group>
            </div>
            <div className='margin-b10'>
              <div>师傅处理备注：</div>
              <Input.TextArea value={workData.remark} onChange={this.changeWork.bind(this, 'remark')} rows={3} className='w220' />
            </div>
            <div className='w100 row-r'>
              <Button onClick={this.confirm.bind(this, '师傅处理')} type='primary'>确认</Button>
            </div>
          </div>
          <div className={`${styles['after']} minh340 w300 col-t vertical-lt`}>
            <div>责任认定</div>
            <Checkbox.Group value={platform.duty} onChange={this.changePlat.bind(this, 'duty')}>
              <Checkbox value={'商户责任'}> 商户责任</Checkbox>
              <Checkbox value={'客户责任'}> 客户责任</Checkbox>
              <Checkbox value={'师傅责任'}> 师傅责任</Checkbox>
              <Checkbox value={'平台责任'}> 平台责任</Checkbox>
              <Checkbox value={'客服责任'}> 客服责任</Checkbox>
            </Checkbox.Group>
            {
              platform.duty.includes('客服责任') &&
              <div className='row-l vertical-lt margin-b10'>
                <div>客服：</div>
                <Select
                  mode='multiple'
                  allowClear
                  className='w220'
                  value={platform.person}
                  onChange={this.changePlat.bind(this, 'person')}
                >
                  {
                    serviceList.map((item, index) => {
                      return (
                        <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                      );
                    })
                  }
                </Select>
              </div>
            }
            <div className='row-l margin-b10'>
              <div>售后状态：</div>
              <Radio.Group value={platform.state} onChange={this.changePlat.bind(this, 'state')}>
                <Radio value={'待处理'}>未处理</Radio>
                <Radio value={'已处理'}>已处理</Radio>
              </Radio.Group>
            </div>
            <div className='margin-b10'>
              <div>售后备注：</div>
              <Input.TextArea value={platform.remark} onChange={this.changePlat.bind(this, 'remark')} rows={3} className='w220' />
            </div>
            <div className='w100 row-r'>
              <Button onClick={this.confirm.bind(this, '责任认定')} type='primary'>确认</Button>
            </div>
          </div>
        </div>
        <div>
          <div className='margin-t10'>
            <Table
              columns={this.columns}
              dataSource={records}
              size={'small'}
              rowKey={record => record.id}
              bordered
              pagination={{ defaultPageSize: 5 }}
            />
          </div>
        </div>
        <WorkModal ref={this.workModal} selectWorker={this.selectWorker} />
      </Modal>
    );
  }
  getServicer = async () => {
    let res = await getServiceList('', '全部', 0, 300);
    if (res.status === 200) {
      this.setState({
        serviceList: res.data
      })
    }
  }
  changeShop = (type, e) => {
    let shopData = _.cloneDeep(this.state.shopData);
    if (type === 'price') {
      shopData[type] = e;
    } else {
      shopData[type] = e.target.value;
    }
    this.setState({
      shopData
    });
  }
  changeWork = (type, e) => {
    let workData = _.cloneDeep(this.state.workData);
    if (type === 'price') {
      workData[type] = e;
    } else {
      workData[type] = e.target.value;
    }
    this.setState({
      workData
    });
  }
  changePlat = (type, e) => {
    let platform = _.cloneDeep(this.state.platform);
    if (type === 'duty' || type === 'person') {
      platform[type] = e;
    } else {
      platform[type] = e.target.value;
    }
    this.setState({
      platform
    });
  }
  showWorker = () => {
    this.workModal.current.onShow();
  }
  selectWorker = (selectedRows) => {
    let workData = _.cloneDeep(this.state.workData);
    workData.worker = selectedRows[0].id;
    workData.workerName = selectedRows[0].name + ',' + selectedRows[0].uid;
    this.setState({
      workData
    });
  }
  confirm = async (type) => {
    const { shopData, workData, platform, id, serviceList } = this.state;
    let data = {
      type: type,
      price: 0,
      priceType: '',
      state: '',
      remark: '',
      dutyType: [],
      person: [],
      worker: 0,
      workerName: '',
    };
    if (type === '商户处理') {
      data.price = shopData.price;
      data.state = shopData.state;
      data.remark = shopData.remark;
    } else if (type === '师傅处理') {
      data.price = workData.price;
      data.state = workData.state;
      data.priceType = workData.priceType;
      data.remark = workData.remark;
      data.worker = workData.worker;
      data.workerName = workData.workerName;

    } else {
      data.dutyType = platform.duty;
      data.state = platform.state;
      data.remark = platform.remark;
      data.person = platform.person.map(item => {
        let index = serviceList.findIndex(r => r.id === item);
        return ({
          id: item,
          name: serviceList[index].name
        });
      });
    }
    let res = await putDealclaim(id, data);
    if (res.status === 204) {
      this.onCancel();
      this.props.update();
    }
  }
  getAfterInfo = async (id) => {
    let res = await getDealclaim(id);
    if (res.status === 200) {
      let workData = {
        price: 0,
        priceType: '理赔',
        state: '待处理',
        remark: '',
        worker: 0,
        workerName: '',
      };
      workData.worker = res.data.sale.worker;
      workData.workerName = res.data.sale.worker_name;
      let records = [];
      let info = res.data;
      info.shop = 0;
      info.worker = 0;
      res.data.shopPrice.map(item => {
        info.shop += item.price;
      });
      res.data.workerPrice.map(item => {
        info.worker += item.price;
      });
      records = res.data.record;
      this.setState({
        info, records, workData
      });
    }
  }
  onShowModal = (id) => {
    this.getAfterInfo(id);
    this.setState({
      visible: true,
      id,
    });
  }
  onCancel = () => {
    this.setState({
      visible: false,
      id: 0,
      records: [],
      shopData: {
        price: 0,
        state: '待处理',
        remark: '',
      },
      workData: {
        price: 0,
        priceType: '理赔',
        state: '待处理',
        remark: '',
        worker: 0,
        workerName: '',
      },
      platform: {
        duty: [],
        state: '待处理',
        remark: '',
        person: [],
      },
    });
  }
}
export default AfterModal;