import React, { useEffect, useState, Component } from 'react';
import { message, Modal, Input, Radio, Upload, Button, Select, Checkbox, Drawer, DatePicker, Table, InputNumber, Popconfirm, } from 'antd';

class MasterRankDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      list: [],
      provinces: ['', '', ''],
    };
    this.columns = [

      {
        title: '师傅名称',
        align: 'center',
        width: 120,
        className: 'padding7',
        dataIndex: 'name',
      },
      {
        title: '电话',
        align: 'center',
        width: 120,
        className: 'padding7',
        dataIndex: 'uid',
      },
    ];
  }
  render() {
    const { visible, list, provinces } = this.state;
    return (
      <Drawer
        title={provinces[0] + '，' + provinces[1] + '，' + provinces[2]}
        visible={visible}
        onClose={this.hideDrawer}
        width={1000}
      >
        <Table
          rowKey={record => record.province + record.city + record.district + record.worker + record.rank}
          dataSource={list}
          columns={this.columns}
          size={'small'}
          pagination={{ defaultPageSize: 20 }}
          bordered />

      </Drawer>
    );
  }
  onShow = (e, provinces) => {
    this.setState({
      visible: true,
      list: e, provinces,
    });
  }
  hideDrawer = () => {
    this.setState({
      visible: false,
    });
  }
}
export default MasterRankDrawer;