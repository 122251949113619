import React, { Component } from 'react';
import { Button, DatePicker, Input, message, Radio, Tree, Table } from 'antd';
import Region from '../../components/regioRegister';
import { getMasterAreaRank } from '../../services/masterManagement';
import MasterRankDrawer from './masterRankDrawer';

class MasterAreaRank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      provinces: ['全部', '全部', '全部'],
      rankData: {},
      data: [],
    };
    this.masterRankDrawer = React.createRef();
    this.columns = [
      {
        title: '省份',
        align: 'center',
        class: 200,
        dataIndex: 'province',
      },
      {
        title: '城市',
        align: 'center',
        class: 200,
        dataIndex: 'city',
      },
      {
        title: '区域',
        align: 'center',
        class: 200,
        dataIndex: 'district',
      },
      {
        title: 'A级',
        align: 'center',
        class: 200,
        dataIndex: 'classA',
        render: (text, record, index) => {
          return (
            <div onClick={this.showList.bind(this, record.classA, [record.province, record.city, record.district])} className='color3 underline fz20 cursor-pointer'>{record.classA.length}</div>
          );
        }
      },
      {
        title: 'B级',
        align: 'center',
        class: 200,
        dataIndex: 'classB',
        render: (text, record, index) => {
          return (
            <div onClick={this.showList.bind(this, record.classB, [record.province, record.city, record.district])} className='color3 underline fz20 cursor-pointer'>{record.classB.length}</div>
          );
        }
      },
    ];
  }
  componentDidMount() {
    this.getAreaRank();
  }
  render() {
    const { data, provinces } = this.state;
    let newData = data;
    if (provinces[0] !== '全部') {
      newData = newData.filter(r => r.province === provinces[0]);
      if (provinces[1] !== '全部') {
        newData = newData.filter(r => r.city === provinces[1]);
        if (provinces[2] !== '全部') {
          newData = newData.filter(r => r.district === provinces[2]);
        }
      }
    }
    return (
      <div>
        <div className='w100 row-l bar margin-b10'>
          <div>省市区选择：</div>
          <Region allPermiss={true} value={provinces} onChange={this.changeSummary}></Region>
        </div>
        <div>
          <Table
            rowKey={record => record.key}
            dataSource={newData}
            columns={this.columns}
            size={'small'}
            pagination={false}
            bordered
          />
        </div>
        <MasterRankDrawer ref={this.masterRankDrawer} />
      </div>
    );
  }
  changeSummary = (e) => {
    this.setState({
      provinces: e
    });
  }
  getAreaRank = async () => {
    let res = await getMasterAreaRank('', '', '');
    if (res.status === 200) {
      this.setState({
        rankData: res.data
      }, () => this.getTable())
    }
  }

  getTable = () => {
    const { rankData, } = this.state;
    let data = [];
    Object.keys(rankData).forEach(function (key1, index1) {
      Object.keys(rankData[key1]).forEach(function (key2, index2) {
        Object.keys(rankData[key1][key2]).forEach(function (key3, index3) {
          let single = {
            province: key1,
            city: key2,
            district: key3,
            classA: rankData[key1][key2][key3].filter(r => r.rank == 'A'),
            classB: rankData[key1][key2][key3].filter(r => r.rank == 'B'),
            key: key1 + key2 + key3,
          };
          data.push(single);
        })
      })
    })
    this.setState({
      data,
    });
  }

  showList = (e, provinces) => {
    this.masterRankDrawer.current.onShow(e, provinces);
  }
}


export default MasterAreaRank;