import { Input, Modal, Radio, Button, message, Image } from 'antd';
import React, { Component } from 'react';
import WorkerModal from '../orderCore/allOrder/workerModal';
import { putTask } from '../../services/task';
import { getDepartment_Data, } from '../../config/storage';
import styles from './index.module.css';

class IndexModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      id: 0,
      checker: 0,
      chkName: '',
      radioType: '待处理',
      kefuData: {
        id: 0,
        name: ''
      },
      pic: [],
      textAreaValue: '',
      confirmLoading: false
    }
    this.workerModal = React.createRef();
    this.baseUrl = getDepartment_Data().service;
  }
  render() {
    let { visible, radioType, kefuData, confirmLoading, pic } = this.state;
    return (
      <Modal
        title={'跟进处理'}
        visible={visible}
        width={650}
        onCancel={this.onCancel}
        onOk={this.onOk}
        okText='确定'
        cancelText='取消'
        confirmLoading={confirmLoading}
      >
        <div className='row-l'>
          <span className='margin-r10'>已上传备注图片:</span>

          {
            pic.map((item, index) => {
              return (
                <Image src={this.baseUrl + '/upload/' + item} key={index} className={`${styles['img']}`} />
              );
            })
          }
        </div>
        <div className='margin-t10'>
          <span className='margin-r10'>处理状态:</span>
          <Radio.Group onChange={this.onRadio} value={radioType}>
            <Radio value={'待处理'}>待处理</Radio>
            <Radio value={'已处理'}>已处理</Radio>
          </Radio.Group>
        </div>
        <div className='row-l vertical-lt margin-t10'>
          <div className='margin-r10'>处理备注:</div>
          <Input.TextArea rows={4} className={'w486'} placeholder="输入处理备注" value={this.state.textAreaValue} onChange={this.getInputTextAteaValue} />
        </div>
        <div className='row-l vertical-lt margin-t10'>
          <span className='margin-r10'>选择人员:</span>
          <Input disabled={true} className='w198' value={kefuData.name} />
          <Button type='primary' className='margin-l10' onClick={this.openModal.bind(this, '客服')} disabled={radioType === '已处理' ? true : false}>选择</Button>
        </div>
        <WorkerModal
          ref={this.workerModal}
          updateTable={this.updateTable}
        />
      </Modal>
    );
  }
  //备注
  getInputTextAteaValue = (e) => {
    this.setState({
      textAreaValue: e.target.value
    })
  }
  updateTable = (row, type) => {
    if (row) {
      this.setState({
        kefuData: row
      })
    }
  }
  //打开modal
  openModal = (e) => {
    this.workerModal.current.onShowModal(e);
  }
  //处理状态
  onRadio = (e) => {
    let kefuData = {
      id: this.state.checker,
      name: this.state.chkName
    }
    this.setState({
      radioType: e.target.value,
      kefuData: kefuData
    })
  }
  onShowModal = (e) => {
    let kefuData = {
      id: e.checker,
      name: e.chkName
    }
    let pic = e.pic ? JSON.parse(e.pic) : []
    this.setState({
      id: e.id,
      checker: e.checker,
      chkName: e.chkName,
      kefuData: kefuData,
      visible: true,
      pic,
    });
  }
  onOk = async () => {
    this.setState({
      confirmLoading: true
    });
    let { id, radioType, textAreaValue, kefuData } = this.state;
    let res = await putTask(id, textAreaValue, radioType, kefuData.id);
    this.setState({
      confirmLoading: false,
    })
    if (res.status !== 204) {
      message.error(res.data);
      return;
    }
    this.props.updateTable(id, radioType, textAreaValue, kefuData);
    this.onCancel();
  }
  onCancel = () => {
    this.setState({
      visible: false,
      textAreaValue: '',
      radioType: '待处理',
      pic: [],
    })
  }
}

export default IndexModal;