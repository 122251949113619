import React, { Component } from 'react';
import { Button, Input, DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment';

const dateFormat = 'YYYY-MM-DD';
moment.locale('zh-cn');

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
    }
  }
  render() {

    const { inputValue } = this.state;
    const { startDates, endDates } = this.props;
    return (
      <div className='col back-color0 w100 padding20 vertical-lt'>
        <div className='row-l'>
          <div>师傅信息:</div>
          <Input placeholder={'请输入姓名或手机号码'} className={'margin-l10 margin-r20 w295'} value={inputValue} onChange={this.onInputValue} />
          {this.props.children}
          <Button type='primary' className={'margin-l20 margin-r20'} onClick={this.onSearch}>搜索</Button>
          <Button onClick={this.onReset}>重置</Button>
        </div>
        {
          this.props.dateName && <div className='row-l margin-t10'>
            <div className='margin-r10'>{this.props.dateName}:</div>
            <DatePicker placeholder='开始时间' className='w198' value={moment(startDates, dateFormat)} onChange={this.onChangeDate.bind(this, 'start')} locale={locale} />
            <div className='margin-l5 margin-r5'>至</div>
            <DatePicker placeholder='结束时间' className='w198' value={moment(endDates, dateFormat)} onChange={this.onChangeDate.bind(this, 'end')} locale={locale} />
          </div>
        }
      </div>
    );
  }
  onInputValue = (e) => {
    this.setState({
      inputValue: e.target.value
    })
  }
  onSearch = () => {
    const { onSearch } = this.props;
    if (onSearch && typeof onSearch === 'function') {
      onSearch(this.state.inputValue)
    }
  }
  onReset = () => {
    this.setState({
      inputValue: '',
    })
    const { onReset } = this.props;
    if (onReset && typeof onReset === 'function') {
      onReset()
    }
    this.onChangeDate('重置')
  }

  //时间
  onChangeDate = (type, dates, dateStrings) => {
    const { onChangeDate } = this.props;
    if (onChangeDate && typeof onChangeDate === 'function') {
      onChangeDate(dateStrings, type)
    }
  }
}

export default Index;