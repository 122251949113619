import request from '../utils/request';
//获取客服列表
export async function getMaster(state, info, skip, take) {
  return await request(`platbiz/workerpromote?state=${state}&info=${info}&skip=${skip}&take=${take}`, 'get');
}

//获取商户列表
export async function getShop(manager, info, enable, rank, check, skip, take, sort) {
  return await request(`platbiz/shoplist?manager=${manager}&info=${info}&enable=${enable}&rank=${rank}&check=${check}&skip=${skip}&take=${take}&sort=${sort}`, 'get');
}

//修改店铺信息
export async function editShop(shopid, body) {
  return await request(`platbiz/mdyshop?shopid=${shopid}`, 'put', body);
}

//新增店铺
export async function addShop(body) {
  return await request(`platbiz/addshop`, 'post', body);
}
//修改好评
export async function putBonus(id, body) {
  return await request(`platbiz/mdybonus?id=${id}`, 'put', body);
}

//商家客服列表
export async function getShopServicer(shopid) {
  return await request(`platbiz/shopuser?shopid=${shopid}`, 'get');
}

//获取余额
export async function getShopBalance(shop) {
  return await request(`platbiz/shopblance?shop=${shop}`, 'get');
}

//获取财务流水
export async function getShopBill(search, shop, info, type, start, end, isexcel, skip, take) {
  return await request(`platbiz/shopfinancelist?search=${search}&shop=${shop}&info=${info}&type=${type}&start=${start}&end=${end}&isexcel=${isexcel}&skip=${skip}&take=${take}`, 'get');
}
//财务调整
export async function adjustBalance(type, shop, price, remark, orderid) {
  return await request(`platbiz/adjshopfin?type=${type}&shop=${shop}&price=${price}&remark=${remark}&orderid=${orderid}`, 'put');
}

//获取优惠券类型
export async function getShopDiscount(shop) {
  return await request(`platbiz/coupontype?shop=${shop}`, 'get');
}
//获取优惠券列表
export async function getShopDiscountList(shop, skip, take) {
  return await request(`platbiz/couponlist?shop=${shop}&skip=${skip}&take=${take}`, 'get');
}

//删除优惠券
export async function delShopDiscount(shopid, body) {
  return await request(`platbiz/delcoupon?shopid=${shopid}`, 'delete', body);
}
//分发优惠券
export async function addShopDiscount(shopid, body) {
  return await request(`platbiz/addcoupon?shopid=${shopid}`, 'post', body);
}

//回访记录
export async function getShopFollow(shopid, skip, take) {
  return await request(`platbiz/shopvisitlist?shopid=${shopid}&skip=${skip}&take=${take}`, 'get');
}
//新增回访记录
export async function addShopFollow(shopid, content) {
  return await request(`platbiz/addshopvisit?shopid=${shopid}&content=${content}`, 'post');
}
//操作记录
export async function getShopOperate(shopid, skip, take) {
  return await request(`platbiz/shopchangelist?shopid=${shopid}&skip=${skip}&take=${take}`, 'get');
}
//获取商户余额合计
export async function getShopCount() {
  return await request(`platbiz/shoptblance`, 'get');
}
//获取冻结金额
export async function getShopFreeze() {
  return await request(`analysis/undoprice`, 'get');
}

//订单转化
export async function getShopTransfer(shop, state, type, start, end, skip, take, info) {
  return await request(`platbiz/transordershop?shop=${shop}&state=${state}&type=${type}&start=${start}&end=${end}&skip=${skip}&take=${take}&info=${info}`, 'get');
}
export async function putShopTransfer(data) {
  return await request('platbiz/transorder', 'put', data);
}


