import React, { useEffect, useState, Component } from 'react';
import { Button, Modal, Table, Input, Select, InputNumber, Popover, Radio, Popconfirm, message, Dropdown, Menu, Upload, Tooltip, Checkbox } from 'antd';
import { getReduce, delReduce } from '../../services/sizeManagement';
import SizeDrawer from './sizeDrawer';

const productType = ['罗马杆', '直轨', '电动直轨', '弯轨', '电动弯轨', '隐形轨道', '成品帘', '手动梦幻帘', '电动梦幻帘', '磁控百叶'];
const installation1 = ['顶装', '侧装', '已安装'];
const installation2 = ['内顶装', '外顶装', '内两头装', '外两头装', '外侧装', '内侧装', '内嵌式安装', '免打孔'];
const installation3 = ['内嵌式安装', '玻璃内框尺寸'];
const installation4 = ['顶装', '侧装', '两头装', '已安装'];
const installation5 = ['顶装', '已安装'];

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: productType[0],
      install: installation1[0],
      currentInstall: installation4,
      dataSource: [],
    };
    this.sizeDrawer = React.createRef();
    this.columns = [
      {
        title: '名称',
        align: 'center',
        dataIndex: 'content',
        width: 250,
      },
      {
        title: '产品',
        align: 'center',
        dataIndex: 'product',
        width: 100,
      },
      {
        title: '安装方式',
        align: 'center',
        dataIndex: 'methed',
        width: 100,
      },
      {
        title: '高度(米)',
        align: 'center',
        dataIndex: 'height',
        width: 100,
      },
      {
        title: '备注',
        align: 'center',
        dataIndex: 'remark',
        width: 200,
      },
      {
        title: '编辑',
        align: 'center',
        dataIndex: 'edit',
        width: 100,
        render: (text, record, index) => {
          return (
            <div className='row'>
              <Button onClick={this.toEdit.bind(this, record)} type='primary' className='margin-r10'>编辑</Button>
              <Popconfirm
                title="你确定要删除此条扣尺?"
                onConfirm={this.toDel.bind(this, record)}
                okText="删除"
                cancelText="返回"
              >

                <Button type='danger'>删除</Button>
              </Popconfirm>
            </div>
          );
        }
      },
    ];
    this.columns2 = [
      {
        title: '名称',
        align: 'center',
        dataIndex: 'content',
        width: 250,
      },
      {
        title: '产品',
        align: 'center',
        dataIndex: 'product',
        width: 100,
      },
      {
        title: '安装方式',
        align: 'center',
        dataIndex: 'methed',
        width: 100,
      },
      {
        title: '宽度(米)',
        align: 'center',
        dataIndex: 'width',
        width: 100,
      },
      {
        title: '备注',
        align: 'center',
        dataIndex: 'remark',
        width: 200,
      },
      {
        title: '编辑',
        align: 'center',
        dataIndex: 'edit',
        width: 100,
        render: (text, record, index) => {
          return (
            <div className='row'>
              <Button onClick={this.toEdit.bind(this, record)} type='primary' className='margin-r10'>编辑</Button>
              <Popconfirm
                title="你确定要删除此条扣尺?"
                onConfirm={this.toDel.bind(this, record)}
                okText="删除"
                cancelText="返回"
              >

                <Button type='danger'>删除</Button>
              </Popconfirm>
            </div>
          );
        }
      },
    ];
  }
  componentDidMount() {
    this.getReduce();
  }
  render() {
    const { category, install, currentInstall, dataSource, } = this.state;
    return (
      <div className='col-t vertical-lt w100'>
        <div className='bar col-t vertical-lt w100'>
          <div className='row-l vertical-lt'>
            <div className='w90'>产品：</div>
            <Radio.Group value={category} onChange={this.changeCategory}>
              {
                productType.map((item, index) => {
                  return (
                    <Radio value={item} key={index}>{item}</Radio>
                  );
                })
              }
            </Radio.Group>
          </div>
          <div className='row-l vertical-lt margin-t10'>
            <div className='w90'>安装方式：</div>
            <Radio.Group value={install} onChange={this.changeInstall}>
              {
                currentInstall.map((item, index) => {
                  return (
                    <Radio value={item} key={index}>{item}</Radio>
                  );
                })
              }
            </Radio.Group>
            <Button onClick={this.toEdit.bind(this, { id: 0, product: category, methed: install })} className='margin-l20' type='primary'>新增</Button>
          </div>
        </div>
        <div className='bar w100'>
          <Table
            columns={category === '成品帘' ? this.columns2 : this.columns}
            dataSource={dataSource}
            size={'small'}
            rowKey={record => record.id}
            bordered
            className='w100'
          />
        </div>
        <SizeDrawer ref={this.sizeDrawer} update={this.getReduce} />
      </div>
    );
  }
  changeCategory = (e) => {
    let install = '';
    let currentInstall = [];
    switch (e.target.value) {
      case '直轨':
      case '弯轨':
      case '电动直轨':
      case '电动弯轨':
      case '手动梦幻帘':
      case '电动梦幻帘':
        currentInstall = installation1; break;
      case '成品帘':
        currentInstall = installation2; break;
      case '罗马杆':
        currentInstall = installation4; break;
      case '隐形轨道':
        currentInstall = installation5; break;
      case '磁控百叶':
        currentInstall = installation3; break;
    }
    install = currentInstall[0];
    this.setState({
      category: e.target.value,
      currentInstall, install
    }, () => this.getReduce());
  }
  changeInstall = (e) => {
    this.setState({
      install: e.target.value
    }, () => this.getReduce());
  }
  getReduce = async () => {
    const { category, install } = this.state;
    let res = await getReduce(category, install);
    if (res.status === 200) {
      this.setState({
        dataSource: res.data,
      });
    }
  }
  toEdit = (e) => {
    this.sizeDrawer.current.onShow(e);
  }
  toDel = async (e) => {
    let res = await delReduce(e.id);
    if (res.status === 204) {
      this.getReduce()
    }
  }
}
export default Index;
