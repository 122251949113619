import XLSX from 'xlsx';
import moment from 'moment';

export function exportExcel(headers, data, fileName) {
  if (!fileName) {
    const DateFormat = 'YYYY-MM-DD';
    let name = moment(new Date()).format(DateFormat);
    fileName = `${name}.xlsx`;
  }
  const _headers = headers
    .map((item, i) => Object.assign({}, { key: item.key, title: item.title, position: String.fromCharCode(65 + i) + 1 }))
    .reduce((prev, next) => Object.assign({}, prev, { [next.position]: { key: next.key, v: next.title } }), {});

  const _data = data
    .map((item, i) => headers.map((key, j) => Object.assign({}, { content: item[key.key], position: String.fromCharCode(65 + j) + (i + 2) })))
    // 对刚才的结果进行降维处理（二维数组变成一维数组）
    .reduce((prev, next) => prev.concat(next))
    // 转换成 worksheet 需要的结构
    .reduce((prev, next) => Object.assign({}, prev, { [next.position]: { v: next.content, t: 'n' } }), {});

  // 合并 headers 和 data
  const output = Object.assign({}, _headers, _data);
  // 获取所有单元格的位置
  const outputPos = Object.keys(output);
  // 计算出范围 ,["A1",..., "H2"]
  const ref = `${outputPos[0]}:${outputPos[outputPos.length - 1]}`;

  // 构建 workbook 对象
  const wb = {
    SheetNames: ['mySheet'],
    Sheets: {
      mySheet: Object.assign(
        {},
        output,
        {
          '!ref': ref,
          '!cols': [{ wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }, { wpx: 100 }],
        },
      ),
    },
  };

  // 导出 Excel
  XLSX.writeFile(wb, fileName);
}


export function jsToExcel(title, data, fileName = '', titleName = '', sheetName = '') {
  let str = `<tb align='center'>${titleName}</tb><tr>`;
  const base64 = s => window.btoa(unescape(encodeURIComponent(s)));
  title.forEach(item => {
    return str += `<td>${item.title}</td>`;
  });
  str += '</tr>';
  for (let i = 0; i < data.length; i++) {
    str += '<tr>';
    for (let key in data[i]) {
      if (key !== 'component') {
        str += `<td>${data[i][key] + '\t'}</td>`;
      }
    }
    str += '</tr>';
  }
  const worksheet = sheetName ? sheetName : 'Sheet1';
  const uri = 'data:application/vnd.ms-excel;base64,';
  const template = `<html xmlns:o="urn:schemas-microsoft-com:office:office" 
        xmlns:x="urn:schemas-microsoft-com:office:excel" 
        xmlns="http://www.w3.org/TR/REC-html40">
        <head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
        <x:Name>${worksheet}</x:Name>
        <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>
        </x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
        </head><body><table>${str}</table></body></html>`;

  var link = document.createElement('a');
  link.href = uri + base64(template);
  link.download = fileName + '.xls';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
export function jsToExcel2(title, data, fileName = '', titleName = '', sheetName = '') {
  let str = `<tb align='center'>${titleName}</tb><tr>`;
  const base64 = s => window.btoa(unescape(encodeURIComponent(s)));
  title.forEach(item => {
    return str += `<td>${item.title}</td>`;
  });
  str += '</tr>';
  for (let i = 0; i < data.length; i++) {
    str += '<tr>';
    for (let key in data[i]) {
      str += `<td>${data[i][key] + '\t'}</td>`;
    }
    str += '</tr>';
  }
  const worksheet = sheetName ? sheetName : 'Sheet1';
  const uri = 'data:application/vnd.ms-excel;base64,';
  const template = `<html xmlns:o="urn:schemas-microsoft-com:office:office" 
        xmlns:x="urn:schemas-microsoft-com:office:excel" 
        xmlns="http://www.w3.org/TR/REC-html40">
        <head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
        <x:Name>${worksheet}</x:Name>
        <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>
        </x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
        </head><body><table>${str}</table></body></html>`;

  var link = document.createElement('a');
  link.href = uri + base64(template);
  link.download = fileName + '.xlsx';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
export function correctExcel(title, data, name, widfit) {
  let header = title.map(r => r.key);
  let topTit = {};
  // eslint-disable-next-line array-callback-return
  title.map(item => {
    topTit[item.dataIndex] = item.title;
  });
  data.unshift(topTit);
  var worksheet = XLSX.utils.json_to_sheet(data, { header: header, skipHeader: true });
  // worksheet['']
  if (widfit) {
    worksheet['!cols'] = widfit.map(r => {
      return {
        wch: r
      };
    });
  }
  var workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  XLSX.writeFile(workbook, name + '.xlsx');
}
