import request from '../utils/request';
//获取服务条目列表
export async function getServiceList() {
  return await request(`data/servicelist`, 'get');
}
//获取服务条目列表,第一层
export async function getProductList(service, type, group) {
  return await request(`data/productlist?service=${service}&type=${type}&group=${group}`, 'get');
}
//新增产品
export async function addProduct(body) {
  return await request(`data/addproduct`, 'post', body);
}
//修改产品
export async function editProduct(id, body) {
  return await request(`data/mdyproduct?id=${id}`, 'put', body);
}
//获取服务产品叠加
export async function getAdds(id, group) {
  return await request(`data/productadds?id=${id}&group=${group}`, 'get');
}

//新增叠加产品
export async function addAdds(add, body) {
  return await request(`data/addproduct?add=${add}`, 'post', body);
}





