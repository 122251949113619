import request from '../utils/request';
//获取通知及资料列表
export async function getNotice(enable, ascription, skip, take) {
  return await request(`data/noticelist?enable=${enable}&ascription=${ascription}&skip=${skip}&take=${take}`, 'get');
}
//新增通知或资料
export async function addNotice(body) {
  return await request(`data/addnotice`, 'post', body);
}
//修改通知或资料
export async function editNotice(id, body) {
  return await request(`data/mdynotice?id=${id}`, 'put', body);
}
//删除通知或资料
export async function delNotice(id) {
  return await request(`data/delnotice?id=${id}`, 'delete');
}






