import request from '../utils/request';

//订单数量
export async function getOrderCount(myarea) {
  return await request(`platbiz/statenum?myarea=${myarea}`, 'get');
}
//订单列表
export async function getOrderList(data) {
  return await request('platbiz/search', 'post', data);
  // return await request(`platbiz/search?search=${data.search}&addition=${data.addition}&info=${data.info}&category=${data.category}&service=${data.service}&type=${data.type}&state=${data.state}&start=${data.start}&end=${data.end}&worker=${data.worker}&shop=${data.shop}&user=${data.user}&mode=${data.mode}&myarea=${data.myarea}&skip=${data.skip}&take=${data.take}&isexport=${data.isexport}&label=${data.label}`, 'get');
}
// 订单标签列表
export async function getLabels() {
  return await request('data/orderlabels', 'get')
}
// 师傅列表
export async function getWorker(data) {
  return await request(`data/worker?name=${data.name}&city=${data.city}&skip=${data.skip}&take=${data.take}`, 'get')
}
// 商户列表
export async function getShop(data) {
  return await request(`data/shop?name=${data.name}&skip=${data.skip}&take=${data.take}`, 'get')
}
// 客服列表
export async function getManager(data) {
  return await request(`data/manager?name=${data.name}&skip=${data.skip}&take=${data.take}`, 'get')
}
// 订单详情
export async function getOrder(orderid) {
  return await request(`platbiz?orderid=${orderid}`, 'get')
}
//订单详情2（客服重复上传数据问题）
export async function getOrder2(orderid) {
  return await request(`platbiz/getmorder?orderid=${orderid}`, 'get')
}
//整理数据确认是否有客服在操作
export async function getCheckServicer(orderid) {
  return await request(`platbiz/checkopen?orderid=${orderid}`, 'get')
}


// 订单详情
export async function putOrder(orderid, data) {
  return await request(`platbiz?orderid=${orderid}`, 'put', data)
}
//服务记录
export async function getOrderRecord(orderid) {
  return await request(`platbiz/srecord?orderid=${orderid}`, 'get')
}


export async function getCategory(category, shop) {
  return await request(`data?category=${category}&shop=${shop}`, 'get');
}
//算价
export async function getPrice(itemid, workid, shopid) {
  return await request(`data/price?itemid=${itemid}&workid=${workid}&shopid=${shopid}`, 'get');
}
//订单跟进
export async function postFollow(orderid, remark) {
  return await request(`platbiz/follow?orderid=${orderid}&remark=${remark}`, 'post');
}
//重新分配
export async function putAllocate(orderid, workerid) {
  return await request(`platbiz/allocate?orderid=${orderid}&workerid=${workerid}`, 'put');
}
//订单推进
export async function putPushorder(orderid, apptime, remark, success) {
  return await request(`platbiz/pushorder?orderid=${orderid}&apptime=${apptime}&remark=${remark}&success=${success}`, 'put');
}
//终止
export async function putCancelorder(orderid, remark) {
  return await request(`platbiz/cancelorder?orderid=${orderid}&remark=${remark}`, 'put');
}
//催单
export async function putUrgecontact(data) {
  return await request('platbiz/urgecontact', 'put', data);
}
//好评返现
export async function putCheckbonus(orderid, state, remark, price_s, price_w) {
  return await request(`platbiz/checkbonus?orderid=${orderid}&state=${state}&remark=${remark}&price_s=${price_s}&price_w=${price_w}`, 'put');
}
//申请好评
export async function putBonusApply(orderid, data) {
  return await request(`workerBiz/applybonus?orderid=${orderid}`, 'put', data);
}
//转待开发
export async function putOrderDevelop(orderid) {
  return await request(`platbiz/devorder?orderid=${orderid}`, 'put');
}
//推送测量数据
export async function puOrderData(orderid) {
  return await request(`platbiz/sendorder?orderid=${orderid}`, 'put');
}

//审核
export async function putCheckorder(data) {
  return await request('platbiz/checkorder', 'put', data);
}
//添加订单标记
export async function putAddlabel(orderid, data) {
  return await request(`platbiz/follow?orderid=${orderid}`, 'post', data);
}
//删除订单标记
export async function delLabel(id) {
  return await request(`platbiz/dellabel?id=${id}`, 'delete');
}

export async function getExcel(orderid) {
  return await request(`data/excelurl?orderid=${orderid}`, 'get')
}

//获取分配师傅列表
export async function getAllotMaster(province, city, district, dest, skip, take) {
  return await request(`data/allotworker?province=${province}&city=${city}&district=${district}&dest=${dest}&skip=${skip}&take=${take}`, 'get')
}

//获取师傅投诉记录
export async function getAppeal(orderid, workerid) {
  return await request(`platbiz/workerappeal?orderid=${orderid}&workerid=${workerid}`, 'get')
}
//新增师傅回访/投诉培训/评价记录
export async function postAppeal(orderid, workerid, type, content) {
  return await request(`platbiz/addworkervisit?orderid=${orderid}&workerid=${workerid}&type=${type}&content=${content}`, 'post')
}
//获取师傅负责人
export async function getMasterManager(workerid) {
  return await request(`platbiz/workermanager?workerid=${workerid}`, 'get')
}

//获取订单相关操作人员信息
export async function getShopManager(orderid) {
  return await request(`platbiz/ordermanagers?orderid=${orderid}`, 'get')
}

//获取商户，师傅负责人
export async function getOrderManager(type, id) {
  return await request(`platbiz/getmanager?type=${type}&id=${id}`, 'get')
}

//二次上门/售后订单
export async function putTwice(orderid, remark, type) {
  return await request(`platbiz/second?orderid=${orderid}&remark=${remark}&type=${type}`, 'post')
}

//安装效果图
export async function getInstallPic(orderid) {
  return await request(`platbiz/inspic?orderid=${orderid}`, 'get')
}
export async function putInstallPic(orderid, data) {
  return await request(`platbiz/updateinspic?orderid=${orderid}`, 'put', data)
}
//修改预约
export async function putAppointTime(orderid, apptime) {
  return await request(`platbiz/mdyapptime?apptime=${apptime}&orderid=${orderid}`, 'put')
}

//获取商户信息
export async function getShopInfo(id) {
  return await request(`platbiz/shopinfo?id=${id}`, 'get')
}

//催单批量处理
export async function delReminder(data) {
  return await request(`platbiz/finishurge`, 'put', data)
}

//驳回订单
export async function putBackOrder(orderid) {
  return await request(`platbiz/backorder?orderid=${orderid}`, 'put')
}

//测距
export async function getDistance(orderid, workerid) {
  return await request(`platbiz/getdistance?orderid=${orderid}&workerid=${workerid}`, 'get')
}

//发短信
export async function sendMessage(orderid, phone) {
  return await request(`platbiz/sendmsg?orderid=${orderid}&phone=${phone}`, 'get')
}
//申请理赔
export async function postOrderClaim(data) {
  return await request('platbiz/claim', 'post', data)
}
//投诉
export async function postAllAppeal(data) {
  return await request('platbiz/appeal', 'post', data)
}