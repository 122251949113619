import React, { Component } from 'react';
import TableList from '../tableList';
import { Checkbox, Radio, Modal, Input, Button } from 'antd';
import { getOrderList, putCheckbonus, getMasterManager, getShopManager } from '../../../services/orderCore';
import Option from '../../../components/option';
import PageTurning from '../../../components/pageTurning';
import styles from '../noAppointmentOrder/index.module.css';
import moment from 'moment';

const dateFormat = 'YYYY-MM-DD';
// let startDate = new Date(new Date() - 30 * 24 * 60 * 60 * 1000).toLocaleDateString().split('/').join('-');
// let endDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toLocaleDateString().split('/').join('-');
let startDate = moment(new Date()).subtract(1, 'months').format(dateFormat);
let endDate = moment(new Date()).add(1, 'days').format(dateFormat);
const stateList = ['待审核', '已通过', '已拒绝'];
const types = [
  {
    title: '好评返现订单',
    value: '好评',
  },
  {
    title: '测量12小时回传',
    value: '测量12小时回传',
  },
  {
    title: '安装24小时完成',
    value: '安装24小时完成',
  },
  {
    title: '售后12小时完成',
    value: '售后12小时完成',
  },
  {
    title: '售后48小时未完成',
    value: '售后48小时未完成',
  },
];
// 好评返现订单,'售后48小时未完成','售后12小时完成','安装24小时完成','测量12小时回传',
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDates: startDate,
      endDates: endDate,
      data: [],
      info: '',
      skip: 0,
      take: 20,
      type: '好评',
      orderState: '待审核',
      selectedRowKeys: [],
      myCheckbox: true,
      state: true,
      visible: false,
    }
  }
  componentDidMount() {
    this.getDataList();
  }
  render() {
    const { data, info, type, orderState, myCheckbox, state, visible, } = this.state;
    return (
      <div className='w100'>
        <div className='bar'>
          <div className='row-l margin-b20'>
            <div className='fz16 tr'>订单类型：</div>
            <div className='row-l'>
              {
                types.map((item, index) => {
                  return (
                    <div className={`bar-item fz16 ${item.value === type ? 'bar-item-color' : ''}`}
                      key={index} onClick={this.changeType.bind(this, item.value)}
                    >
                      {item.title}
                    </div>
                  );
                })
              }
            </div>
          </div>
          <div className='row-l margin-b20'>
            <div className='fz16 tr'>审核状态：</div>
            <div className='row-l'>
              {
                stateList.map((item, index) => {
                  return (
                    <div className={`bar-item fz16 ${item === orderState ? 'bar-item-color' : ''}`}
                      key={index} onClick={this.changeOrderState.bind(this, item)}
                    >
                      {item}
                    </div>
                  );
                })
              }
            </div>
          </div>

          {/* <Option title={stateList} top={0} changeMyTop={this.changeTop} className='margin-b10'> */}
          <div className='row-l'>
            {/* <div className={styles['reminder_styles']} onClick={this.onShow}>{`批量审核好评返现(${selectedRowKeys.length})`}</div> */}
            <div className='fz16'>搜索：</div>
            <Input value={info} onChange={this.changeInfo} className='w200' />
            <Button onClick={this.getDataList} type='primary' className='margin-r20'>搜索</Button>
            <div className='fz16'>我的区域：</div>
            <Checkbox checked={myCheckbox} onChange={this.onCheckboxChange} />
          </div>

        </div>
        {/* </Option> */}
        <div className='w100 margin-t10 back-color0 '>
          <TableList setDisplay={this.setDisplay}
            data={data} onType={'好评'}
            updateTable={this.updateTables}
            delLabelData={this.delLabelData}
            updateTipData={this.updateTipData}
            onHoverSet={this.onHoverSet}
            getDataList={this.getDataList}
          />
          <PageTurning pageMaps={this.pageMaps} pageSize={20} />
        </div>
        <Modal
          title={'审核结果选择'}
          width={400}
          visible={visible}
          onCancel={this.onCancel}
          onOk={this.onReminder}
          cancelText='取消'
          okText='确认'
        >
          <div className='row-l'>
            <div>审核结果：</div>
            <Radio.Group value={state} onChange={this.changeState}>
              <Radio value={true}>审核通过</Radio>
              <Radio value={false}>审核失败</Radio>
            </Radio.Group>
          </div>
        </Modal>
      </div>
    );
  }
  changeType = (e) => {
    let orderState = e === '好评' ? '待审核' : '已通过';
    this.setState({
      type: e,
      skip: 0,
      orderState,
    }, async () => this.getDataList());
  }
  changeOrderState = (e) => {
    this.setState({
      orderState: e,
      skip: 0,
    }, async () => this.getDataList());
  }
  changeTop = (e) => {
    this.setState({
      orderState: stateList[e],
      skip: 0,
    }, async () => this.getDataList())
  }
  changeInfo = (e) => {
    this.setState({
      info: e.target.value,
    })
  }
  setDisplay = (index) => {
    let data = _.cloneDeep(this.state.data);
    data[index].workerDisplay = !data[index].workerDisplay;
    this.setState({
      data: data
    })
  }
  //我的区域
  onCheckboxChange = (e) => {
    this.setState({
      myCheckbox: e.target.checked
    }, async () => this.getDataList());
  }
  onReminder = async () => {
    const { state } = this.state;
    let selectedRowKeys = this.state.selectedRowKeys;
    let currentstate = state ? '已通过' : '已拒绝';
    let res = await putCheckbonus(currentstate, selectedRowKeys)
    if (res.status !== 204) {
      message.error(res.data);
      return;
    }
    this.setState({
      selectedRowKeys: [],
      visible: false,
    })
    this.getDataList()
  }
  updateTipData = () => {
    this.getDataList();
  }
  delLabelData = (index, indexs) => {
    let data = _.cloneDeep(this.state.data);
    data[index].serviceLabels.splice(indexs, 1);
    this.setState({
      data: data
    })
  }
  //修改订单
  updateTables = (row) => {
    let data = [...this.state.data];
    let index = data.findIndex(r => r.id === row.id);
    data[index] = row;
    this.setState({
      data: data
    })
  }
  getDataList = async () => {
    let { startDates, endDates, type, skip, take, info, myCheckbox, orderState } = this.state;
    let res = await getOrderList({
      search: type,
      addition: '全部',
      info: info,
      category: '全部',
      service: '全部',
      type: '全部',
      state: orderState,
      start: startDates,
      end: endDates,
      worker: 0,
      shop: 0,
      user: 0,
      mode: '下单时间',
      myarea: myCheckbox,
      trans: '全部',
      label: '全部',
      skip: skip,
      take: take,
    })
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    let data = res.data;
    data.forEach(item => {
      item.workerDisplay = false;
    })
    this.setState({
      data: data,
    })
  };
  onHoverSet = (index, props, value) => {
    let data = [...this.state.data];
    data[index][props] = value;
    this.setState({
      data
    });
  }
  //换页
  pageMaps = ({ skip, take }) => {
    this.setState({
      skip: skip,
    }, async () => this.getDataList())
  }
  changeState = (e) => {
    this.setState({
      state: e.target.value,
    });
  }
  onCancel = () => {
    this.setState({
      visible: false,
    });
  }
  onShow = () => {
    this.setState({
      visible: true,
    });
  }
}

export default Index;