import React, { Component, } from 'react';
import { Input, Drawer, Switch, Button } from 'antd';
import moment from 'moment';
import { putMac } from '../../services/whiteList';

class MacDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: {},
    };
  }
  render() {
    const { visible, data } = this.state;
    return (
      <Drawer
        title='申请人信息'
        style={{ position: 'fixed', right: 0 }}
        visible={visible}
        onClose={this.hideDrawer}
        width={600}
        footer={
          <div className='w100 row-r'>
            <Button onClick={this.hideDrawer} type='ghost' className='margin-r20'>取消</Button>
            <Button onClick={this.onOk} type='primary'>确认</Button>
          </div>
        }
      >
        <div className='col-t vertical-lt'>
          <div className='row-l margin-b10'>
            <div className='w80'>申请人：</div>
            <Input value={data.name} className='w200' onChange={this.changeInfo.bind(this, 'name')} />
          </div>
          <div className='row-l margin-b10'>
            <div className='w80'>申请说明：</div>
            <Input.TextArea value={data.remark} onChange={this.changeInfo.bind(this, 'remark')} className='w400' rows={4} />
          </div>
          <div className='row-l margin-b10'>
            <div className='w80'>是否通过：</div>
            <Switch checkedChildren="是" unCheckedChildren="否" checked={data.enable === '是' ? true : false}
              onChange={this.changeInfo.bind(this, 'enable')} />
          </div>
          <div className='row-l margin-b10'>
            <div className='w80'>申请时间：</div>
            <div>{moment(data.time).format('YYYY/MM/DD-HH:mm:ss')}</div>
          </div>

        </div>
      </Drawer>

    );
  }
  changeInfo = (type, e) => {
    let data = { ...this.state.data };
    if (type === 'enable') {
      data[type] = e ? '是' : '否';
    } else {
      data[type] = e.target.value;
    }
    this.setState({
      data
    });
  }
  onShow = (e) => {
    this.setState({
      visible: true,
      data: e,
    })
  }
  hideDrawer = () => {
    this.setState({
      visible: false,
      data: {},
    })
  }
  onOk = async () => {
    let data = { ...this.state.data };
    let res = await putMac(data);
    if (res.status === 200) {
      this.props.update();
      this.hideDrawer();
    }
  }
}
export default MacDrawer;