import React, { useEffect, useState, Component } from 'react';
import styles from './modal.module.css';
import { message, Modal, Input, Radio, Image, Button, Select, Checkbox, Drawer, DatePicker, Table, InputNumber, Divider, } from 'antd';
import Options from '../../components/option';
import { getAdds, addAdds, editProduct } from '../../services/optionManagement';

import moment from 'moment';
const { TextArea } = Input;
const { Option } = Select;

const typeList = ['品类', '独立', '其他'];
const enableList = ['启用', '禁用'];
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerShow: false,
      currentService: {},
      currentProduct: {},
      navigation: '',
      navigationList: [],
      addsList: [],
      currentAdds: {
        id: 0,
        service: 0,
        type: '叠加',
        group: '',
        name: '',
        enable: '是',
        remark: '',
        price_app: 0,
        price_oms: 0,
      },
      addsModal: false,
      modalType: 'add',
    };
    this.optionRef = React.createRef();
  }
  componentDidMount() {
  }

  render() {
    const { drawerShow, navigationList, navigation, addsList, addsModal, currentAdds } = this.state;
    const columns = [
      {
        title: '序号',
        align: 'center',
        dataIndex: 'index',
        width: 150,
        render: (text, record, index) => {
          return <div>{index + 1}</div>;
        }
      },
      {
        title: '名称',
        align: 'center',
        dataIndex: 'name',
        width: 220,
      },
      {
        title: '启用状态',
        align: 'center',
        dataIndex: 'enable',
        width: 220,
        render: (text, record, index) => {
          return <div>{record.enable === '是' ? '启用' : '禁用'}</div>;
        }
      },
      {
        title: '备注',
        align: 'center',
        dataIndex: 'remark',
        width: 300,
      },
      {
        title: '商户价格',
        align: 'center',
        dataIndex: 'price_app',
        width: 220,
      },
      {
        title: '师傅价格',
        align: 'center',
        dataIndex: 'price_oms',
        width: 220,
      },

      {
        title: '操作',
        align: 'center',
        dataIndex: 'operation',
        width: 220,
        render: (text, record, index) => {
          return (
            <div className='w100 row'>
              <div onClick={this.editAddsModal.bind(this, record, 'edit')} className={`${styles['btn-search']} tc back-color8 color0 fz16 cursor-pointer`} >编辑</div>

            </div>
          );
        }
      },
    ]

    return (
      <Drawer
        title='    产品信息'
        style={{ position: 'fixed', right: 0 }}
        visible={drawerShow}
        onClose={this.hideDrawer}
        width={1300}
        footer={
          <div className='w100 row-r'>
            <div onClick={this.hideDrawer} className={`${styles['btn']} border tc color6 fz17 margin-r20 cursor-pointer`} key='return'>返回</div>
          </div>
        }
      >
        <div className='col-t w100'>
          <Options title={navigationList} changeMyTop={this.changeSummary.bind(this, 'navigation')} ref={this.optionRef} className='margin-b10 '>
            <div className='row-l w100 margin-t10'>
              <div onClick={this.showAddsModal} className={`${styles['btn-search']} tc margin-l20 back-color5 color0 fz18 cursor-pointer`} >新增</div>
            </div>
          </Options>
          <div className='margin-t10'>
            <Table
              columns={columns}
              dataSource={addsList}
              size={'small'}
              rowKey={record => record.id}
              bordered
              pagination={false}
            />
          </div>
        </div>
        <Modal
          title='产品信息'
          visible={addsModal}
          width={500}
          cancelText='取消'
          okText='确认'
          onCancel={this.hideAddsModal}
          onOk={this.uploadAdds}
        >
          <div className='col w100'>
            <div className='row-l margin-t20 w100'>
              <div className='w80 tc'>名称 ：</div>
              <Input onChange={this.changeSummary.bind(this, 'name')} value={currentAdds.name} className={styles['input']} />
            </div>
            <div className='row-l margin-t20 w100'>
              <div className='w80 tc'>种类：</div>
              <div className={`${styles['select']} tl padding-lr10`}>{currentAdds.type}</div>
            </div>
            <div className='row-l margin-t20 w100'>
              <div className='w80 tc'>类型：</div>
              <Select disabled={true} onSelect={this.changeSummary.bind(this, 'addGroup')} value={currentAdds.group} className={styles['select']} bordered={false}>
                {
                  navigationList.map((item, indexs) => {
                    return (
                      <Option key={indexs} value={item}>{item}</Option>
                    );
                  })
                }
              </Select>
            </div>
            <div className='row-l margin-t20 w100'>
              <div className='w80 tc'>启用状态：</div>
              <Select onSelect={this.changeSummary.bind(this, 'enable')} value={currentAdds.enable === '是' ? '启用' : '禁用'} className={styles['select']} bordered={false}>
                {
                  enableList.map((item, indexs) => {
                    return (
                      <Option key={indexs} value={item}>{item}</Option>
                    );
                  })
                }
              </Select>
            </div>
            <div className='row-l margin-t20 w100'>
              <div className='w80 tc'>商户价格 ：</div>
              <InputNumber onChange={this.changeSummary.bind(this, 'price_app')} value={currentAdds.price_app} className={styles['input']} />
            </div>
            <div className='row-l margin-t20 w100'>
              <div className='w80 tc'>师傅价格 ：</div>
              <InputNumber onChange={this.changeSummary.bind(this, 'price_oms')} value={currentAdds.price_oms} className={styles['input']} />
            </div>
            <div className='row-l margin-t20 w100'>
              <div className='w80 tc'>备注 ：</div>
              <Input onChange={this.changeSummary.bind(this, 'remark')} value={currentAdds.remark} className={styles['input']} />
            </div>
          </div>
        </Modal>
      </Drawer >
    );
  }
  getAddsList = async () => {
    const { currentProduct, navigation } = this.state;
    let res = await getAdds(currentProduct.id, navigation);
    if (res.status === 200) {
      this.setState({
        addsList: res.data
      });
    }
  }
  changeSummary = (type, e) => {
    const { navigationList } = this.state;
    let currentAdds = { ...this.state.currentAdds };
    switch (type) {
      case 'navigation':
        currentAdds.group = navigationList[e];
        this.setState({
          navigation: navigationList[e],
          currentAdds,
        }, () => {
          this.getAddsList()
        });
        break;
      case 'addGroup':
        currentAdds.group = e;
        this.setState({
          currentAdds
        });
        break;
      case 'name':
        currentAdds.name = e.target.value;
        this.setState({
          currentAdds
        });
        break;
      case 'enable':
        currentAdds.enable = e === '启用' ? '是' : '否';
        this.setState({
          currentAdds
        });
        break;
      case 'price_app':
        currentAdds.price_app = e;
        this.setState({
          currentAdds
        });
        break;
      case 'price_oms':
        currentAdds.price_oms = e;
        this.setState({
          currentAdds
        });
        break;
      case 'remark':
        currentAdds.remark = e.target.value;
        this.setState({
          currentAdds
        });
        break;

    }
  }

  uploadAdds = async () => {
    const { currentAdds, currentService, currentProduct, modalType } = this.state;
    if (modalType === 'add') {
      let res = await addAdds(currentProduct.id, currentAdds);
      if (res.status === 200) {
        this.hideAddsModal()
        this.getAddsList()
      }
    } else {
      let res = await editProduct(currentAdds.id, currentAdds)
      if (res.status === 204) {
        this.hideAddsModal()
        this.getAddsList()
      }
    }
  }


  showDrawer = (currentService, currentProduct) => {
    let navigationList = [];
    let currentAdds = { ...this.state.currentAdds };
    if (currentService.name === '窗帘安装') {
      navigationList = ['层数', '四角钩', '墙钩', '窗幔', '超高', '其他'];
      currentAdds.group = navigationList[0]
    } else if (currentService.name === '墙布墙纸安装') {
      navigationList = ['规格', '施工方式', '施工工艺', '拆旧'];
      currentAdds.group = navigationList[0]
    } else if (currentService.name === '晾衣架安装') {
      navigationList = ['驱动方式', '安装面', '拆旧', '超高'];
      currentAdds.group = navigationList[0]
    }
    this.setState({
      navigationList,
      currentService,
      currentProduct,
      drawerShow: true,
      navigation: navigationList[0],
      currentAdds,
    }, () => {
      this.getAddsList()
    });
  }
  hideDrawer = () => {
    this.optionRef.current.returnFirst();
    this.setState({
      drawerShow: false,
    });
  }
  editAddsModal = (currentAdds, modalType) => {
    this.setState({
      currentAdds,
      addsModal: true,
      modalType,
    });
  }
  showAddsModal = () => {
    const { navigationList, currentService } = this.state;
    let currentAdds = { ...this.state.currentAdds };
    currentAdds.service = currentService.id;
    this.setState({
      currentAdds,
      addsModal: true,
      modalType: 'add',
    });
  }
  hideAddsModal = () => {
    const { navigation } = this.state;
    let currentAdds = {
      id: 0,
      service: 0,
      type: '叠加',
      group: navigation,
      name: '',
      enable: '是',
      remark: '',
      price_app: 0,
      price_oms: 0,
    };
    this.setState({
      addsModal: false,
      currentAdds,
    });
  }
}

export default Index;