import React, { Component } from 'react';
import styles from './index.module.css';
import { message, Table, Input, Radio, Image, Select, Modal, Button, Popconfirm, Tag } from 'antd';
import Options from '../../components/option';
import { CloseOutlined } from '@ant-design/icons';
import PageTurning from '../../components/pageTurning';
import { getLabel, addLabel, editLabel } from '../../services/labelManagement';
const { Option } = Select;
import _ from 'lodash';

const statusList = ['启用', '禁用'];
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: '启用',
      skip: 0,
      take: 10,
      labelList: [],
      currentLabel: {
        id: 0,
        name: '',
        enable: '是',
      },
      modalType: 'add',
      modal: false,
    };
    this.pageModal = React.createRef();
  }
  componentDidMount() {
    this.getData()
  }
  render() {
    const { labelList, currentLabel, modal, modalType, } = this.state
    const colums = [
      {
        title: '标签名称',
        align: 'center',
        width: 600,
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '启用状态',
        align: 'center',
        width: 600,
        dataIndex: 'enable',
        key: 'enable',
        render: (text, record, index) => {
          return (<div>{record.enable === '是' ? '启用' : '禁用'}</div>)
        }
      },
      {
        title: '操作',
        align: 'center',
        width: 600,
        dataIndex: 'operate',
        key: 'operate',
        render: (text, record, index) => {
          return (
            <div className='row'>
              <div onClick={this.editModal.bind(this, record)} className={styles['columns2-copy-button']} >编辑</div>
            </div>
          );
        }
      },
    ];
    return (
      <div className='col-t w100'>
        <Options title={statusList} top={0} changeMyTop={this.changeSummary.bind(this, 'status')} className='margin-b10 '>
          <div className='row-l vertical-lt padding20 w100'>
            <div onClick={this.showModal.bind(this, 'add')} className={`${styles['btn']} tc margin-l20 cursor-pointer`}>新增</div>
          </div>
        </Options>
        <Table
          columns={colums}
          dataSource={labelList}
          size={'small'}
          rowKey={record => record.id}
          bordered
          pagination={false}
        />
        <div className='back-color0 w100'>
          <PageTurning pageMaps={this.pageMaps} ref={this.pageModal} />
        </div>
        <Modal
          title='标签'
          visible={modal}
          width={500}
          onCancel={this.hideModal}
          onOk={this.editLabel}
          okText='确定'
          cancelText='取消'
        >
          <div className='col-t vertical-lt w100'>
            <div className='row-l'>
              <div className='margin-l20 w126'>标签名称:</div>
              <Input disabled={modalType === 'edit'} onChange={this.changeSummary.bind(this, 'name')} value={currentLabel.name} className='w160' placeholder='请输入标签名称' />
            </div>
            <div className='row-l margin-t20'>
              <div className='margin-l20 w126'>启用状态:</div>
              <Radio.Group disabled={modalType === 'add' || ['开发订单', '新师傅订单', '预约更改', '投诉订单'].includes(currentLabel.name)} onChange={this.changeSummary.bind(this, 'enable')} value={currentLabel.enable === '是' ? '启用' : '禁用'} size='large'>
                {
                  statusList.map((item, index) => {
                    return <Radio value={item} key={index} className='fz16 margin-l5'>{item}</Radio>;
                  })
                }
              </Radio.Group>
            </div>

          </div>
        </Modal>
      </div >
    );

  }
  //换页
  pageMaps = ({ skip, take }) => {
    this.setState({
      skip: skip,
    }, async () => this.getData())
  }
  getData = async () => {
    const { status, skip, take } = this.state;
    let myStatus = status === '启用' ? '是' : '否';
    let res = await getLabel(myStatus, '订单', skip, take);
    if (res.status === 200) {
      this.setState({
        labelList: res.data
      })
    }
  }
  changeSummary = (type, e) => {
    let currentLabel = { ...this.state.currentLabel };
    switch (type) {
      case 'status':
        this.setState({
          status: statusList[e],
          skip: 0,
        }, () => {
          this.getData();
          this.pageModal.current.returnFirst();
        });
        break;
      case 'name':
        currentLabel.name = e.target.value;
        this.setState({
          currentLabel
        });
        break;
      case 'enable':
        currentLabel.enable = e.target.value === '启用' ? '是' : '否';
        this.setState({
          currentLabel
        });
        break;
    }
  }
  editLabel = async () => {
    const { currentLabel, modalType } = this.state
    if (modalType === 'add') {
      let res = await addLabel(currentLabel.name, '订单');
      if (res.status === 200) {
        this.hideModal();
        this.getData();
      }
    } else {
      let res = await editLabel(currentLabel.id, currentLabel.enable, currentLabel.name);
      if (res.status === 204) {
        this.hideModal();
        this.getData();
      }
    }
  }

  editModal = (e) => {
    this.setState({
      currentLabel: e,
      modalType: 'edit',
      modal: true,
    })
  }
  showModal = (e) => {
    this.setState({
      modal: true,
      modalType: e,
    })
  }
  hideModal = () => {
    this.setState({
      modal: false,
      currentLabel: {
        id: 0,
        name: '',
        enable: '是',
      },
    })
  }

}

export default Index;