import request from '../utils/request';

export async function getServiceList(info, enable, skip, take) {
  return await request(`platbiz/stafflist?info=${info}&enable=${enable}&skip=${skip}&take=${take}`, 'get');
}
//修改信息
export async function modifyInfo(id, mdypwd, body) {
  return await request(`platbiz/mdystaff?staffid=${id}&mdypwd=${mdypwd}`, 'put', body)
}
//删除负责区域
export async function delArea(id, areaId) {
  return await request(`platbiz/delarea?staffid=${id}&id=${areaId}`, 'delete')
}
//新增负责区域
export async function addArea(id, area) {
  return await request(`platbiz/addarea?staffid=${id}`, 'put', area)
}
//删除权限
export async function delPermission(id, permissionId) {
  return await request(`platbiz/delrole?staffid=${id}&id=${permissionId}`, 'delete')
}
//新增权限
export async function addPermission(id, permissionId) {
  return await request(`platbiz/addrole?staffid=${id}&roleid=${permissionId}`, 'put')
}

//新增客服
export async function addService(body) {
  return await request(`platbiz/addstaff`, 'post', body)
}

//获取权限
export async function getPermission() {
  return await request(`data/roles`, 'get')
}


