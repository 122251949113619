import React, { Component } from 'react';
import { Modal, Input, Table, message } from 'antd';
import { getWorker, getShop, getManager } from '../../../services/orderCore';
import { getTemplate } from '../../../services/templateManagement';
import PageTurning from '../../../components/pageTurning';

const { Search } = Input;

class WorkerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: '师傅',
      visible: false,
      confirmLoading: false,
      skip: 0,
      take: 10,
      data: [],
      selectedRowKeys: [],
      selectedRows: [],
      city: '',
    }
    this.pageSize = 10;
    this.columns = [
      {
        title: '名称',
        dataIndex: 'name',
        align: 'center',
        width: 100,
      },
    ]
  }
  render() {
    const { type, visible, data, selectedRowKeys, confirmLoading } = this.state;
    const rowSelection = {
      columnTitle: '选择',
      selectedRowKeys: selectedRowKeys,
      type: 'checkbox',
      onChange: this.onSelectChange,
    };
    return (
      <Modal
        title={`${type}选择`}
        visible={visible}
        onCancel={this.onCancel}
        onOk={this.onOk}
        okText='确定'
        cancelText='取消'
        confirmLoading={confirmLoading}
      >
        <Search
          placeholder={`请输入${type}信息`}
          allowClear
          enterButton="搜索"
          // size="large"
          onSearch={this.onSearch}
        />
        <div className='w100 margin-t10 back-color0 '>
          <Table
            rowSelection={rowSelection}
            columns={this.columns}
            dataSource={data}
            size={'small'}
            rowKey={record => record.id}
            bordered
            pagination={false}
          />
          <PageTurning pageMaps={this.pageMaps} />
        </div>
      </Modal>
    );
  }

  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({
      selectedRowKeys: selectedRowKeys,
      selectedRows: selectedRows,
    });
  }
  //搜索
  onSearch = (e, flag) => {
    if (flag !== 'true') {
      this.setState({
        skip: 0
      }, async () => this.getData(e))
    } else {
      this.getData(e)
    }
  }
  onShowModal = (type, city) => {
    let citys = city || '';
    this.setState({
      type: type,
      visible: true,
      city: citys,
    }, async () => this.onSearch('', 'true'));
  }
  getData = async (name) => {
    let { skip, take, } = this.state;
    let res;
    if (this.state.type === '师傅') {
      res = await getWorker({ name, city: this.state.city, skip, take })
    } else if (this.state.type === '商户') {
      res = await getShop({ name, skip, take })
    } else if (this.state.type === '模板') {
      res = await getTemplate('是', skip, take)
    } else {
      res = await getManager({ name, skip, take })
    }
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    let data = res.data;
    this.setState({
      data: res.data,
    })
  }
  // 完成
  onOk = () => {
    this.setState({
      confirmLoading: true,
    })
    let { type, selectedRows } = this.state;
    this.props.updateTable(selectedRows, type);
    this.onCancel();
    this.setState({
      confirmLoading: false,
    })
  }
  // 关闭modal
  onCancel = () => {
    this.setState({
      visible: false,
      selectedRowKeys: [],
      selectedRows: [],
      skip: 0
    })
  }


  //换页
  pageMaps = ({ skip, take }) => {
    this.setState({
      skip: skip,
    }, async () => this.getData('', 'true'))
  }
}

export default WorkerModal;