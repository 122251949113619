import React, { Component, } from 'react';
import { Modal, Input, message, InputNumber, Radio, Image, Button, Select, Checkbox, Drawer, DatePicker, Table, } from 'antd';
import { putReduce, postReduce, } from '../../services/sizeManagement';

const init = {
  id: 0,
  product: '罗马杆',
  methed: '顶装',
  content: '',
  height: 0,
  width: 0,
  remark: '',
};
const productType = ['罗马杆', '直轨', '电动直轨', '弯轨', '电动弯轨', '隐形轨道', '成品帘', '手动梦幻帘', '电动梦幻帘', '磁控百叶'];
const installation1 = ['顶装', '侧装', '已安装'];
const installation2 = ['内顶装', '外顶装', '内两头装', '外两头装', '外侧装', '内侧装', '内嵌式安装', '免打孔'];
const installation3 = ['内嵌式安装', '玻璃内框尺寸'];
const installation4 = ['顶装', '侧装', '两头装', '已安装'];
const installation5 = ['顶装', '已安装'];

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      size: { ...init },
      currentInstall: installation4,
    };
  }
  render() {
    const { visible, size, currentInstall, } = this.state;
    return (
      <Drawer
        title='扣尺维护'
        visible={visible}
        onClose={this.onHide}
        width={700}
        footer={
          <div className='row-r'>
            <Button onClick={this.onHide} type='ghost' className='margin-r20'>取消</Button>
            <Button onClick={this.onOk} type='primary'>确认</Button>
          </div>
        }
      >
        <div className='col-t vertical-lt w100'>
          <div className='row-l vertical-lt'>
            <div className='w80 tc'>产品:</div>
            <Radio.Group value={size.product} onChange={this.changeInfo.bind(this, 'product')} className='w500'>
              {
                productType.map((item, index) => {
                  return (
                    <Radio value={item} key={index}>{item}</Radio>
                  );
                })
              }
            </Radio.Group>
          </div>
          <div className='row-l vertical-lt margin-t10'>
            <div className='w80 tc'>安装方式：</div>
            <Radio.Group value={size.methed} onChange={this.changeInfo.bind(this, 'methed')} className='w500'>
              {
                currentInstall.map((item, index) => {
                  return (
                    <Radio value={item} key={index}>{item}</Radio>
                  );
                })
              }
            </Radio.Group>
          </div>
          <div className='row-l vertical-lt margin-t10'>
            <div className='row w80'>
              <div className='color2'>*</div>
              <div className=''>名称：</div>
            </div>
            <Input.TextArea value={size.content} className='w500' rows={2}
              onChange={this.changeInfo.bind(this, 'content')} />
          </div>
          {
            size.product === '成品帘' ?
              <div className='row-l vertical-lt margin-t10'>
                <div className='w80 tc'>扣减宽度：</div>
                <InputNumber value={size.height} className='w200' addonAfter="米"
                  onChange={this.changeInfo.bind(this, 'width')} />
              </div>
              :
              <div className='row-l vertical-lt margin-t10'>
                <div className='w80 tc'>扣减高度：</div>
                <InputNumber value={size.height} className='w200' addonAfter="米"
                  onChange={this.changeInfo.bind(this, 'height')} />
              </div>
          }
          <div className='row-l vertical-lt margin-t10'>
            <div className='w80 tc'>备注：</div>
            <Input.TextArea value={size.remark} className='w500' rows={3}
              onChange={this.changeInfo.bind(this, 'remark')} />
          </div>
        </div>
      </Drawer>
    );
  }
  changeInfo = (type, e) => {
    let size = { ...this.state.size };
    let currentInstall = [...this.state.currentInstall];
    if (type === 'product') {
      switch (e.target.value) {
        case '直轨':
        case '弯轨':
        case '电动直轨':
        case '电动弯轨':
        case '手动梦幻帘':
        case '电动梦幻帘':
          currentInstall = installation1; break;
        case '成品帘':
          currentInstall = installation2; break;
        case '罗马杆':
          currentInstall = installation4; break;
        case '隐形轨道':
          currentInstall = installation5; break;
        case '磁控百叶':
          currentInstall = installation3; break;
      }
      size.methed = currentInstall[0];
    }
    size[type] = type === 'height' || type === 'width' ? e ? e : 0 : e.target.value;
    this.setState({
      size, currentInstall,
    });
  }
  onShow = (e) => {
    let size = {
      ...init,
      ...e
    };
    let currentInstall = [];
    switch (e.product) {
      case '直轨':
      case '弯轨':
      case '电动直轨':
      case '电动弯轨':
      case '手动梦幻帘':
      case '电动梦幻帘':
        currentInstall = installation1; break;
      case '成品帘':
        currentInstall = installation2; break;
      case '罗马杆':
        currentInstall = installation4; break;
      case '隐形轨道':
        currentInstall = installation5; break;
      case '磁控百叶':
        currentInstall = installation3; break;
    }
    this.setState({
      visible: true,
      size, currentInstall,
    });
  }
  onHide = () => {
    this.setState({
      visible: false,
      size: { ...init },
    });
  }
  onOk = async () => {
    const { size } = this.state;
    if (size.content === '') {
      message.warn('名称不能为空');
      return;
    }
    let res;
    if (size.id === 0) {
      res = await postReduce(size);
    } else {
      res = await putReduce(size);
    }
    if (res.status === 204) {
      this.props.update();
      this.onHide();
    }
  }
}
export default Index;