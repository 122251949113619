import React, { Component } from 'react';
import { connect } from 'dva';
import { Button, Checkbox, DatePicker, Input, message, Radio, Select, Table, Modal } from 'antd';
import PageTurning from '../../components/pageTurning';
import Option from '../../components/option';
import { getServiceList } from '../../services/afterSales';
import { addMasterFollow } from '../../services/masterManagement';

import { jsToExcel2 } from '../../utils/exportExcel';
import styles from './index.module.css';
import IndexModal from './indexModal';
import AfterModal from './afterModal';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';

const typeList = ['申请理赔', '售后服务', '外部投诉', '内部投诉', '其他'];
const dateForm = 'YYYY-MM-DD';
const month = moment().subtract(1, 'month').format(dateForm);
const today = moment().add(1, 'day').format(dateForm);

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: {
        type: '申请理赔',
        sort: '提交',
        inputValue: '',
        radioType: '待处理',
        start: month,
        end: today,
        workt: '全部',
        shopt: '全部',
        skip: 0,
        take: 10,
        myarea: true,
      },
      data: [],
      download: false,
      workComplain: {
        visible: false,
        id: 0,
        info: '',
        order: 0,
      },
    }
    this.columns = [
      {
        title: '编号',
        align: 'center',
        width: 120,
        dataIndex: 'id',
        render: (text, record, index) => {
          return (
            <div className={'col'}>
              <div className={`${styles['columns3-type-span']} color1`}>{record.id}</div>
              <div className={`${styles['columns3-type-span']} margin-t5 color2`}>【 {record.order_type} 】</div>
              <div className={`${styles['columns3-type-span']} margin-t5 color2`}>【 {record.state} 】</div>
              <div>【师傅:{record.worker_state}】</div>
              <div>【商户:{record.shop_state}】</div>
            </div>
          );
        }

      },
      {
        title: '订单信息',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return (
            <div className={'col'}>
              <div className={`${styles['columns-span']}`}>{record.order}</div>
              <div className={`${styles['columns-span']}`}>{record.name}-{record.phone}</div>
              <div className={`${styles['columns-span']}`}>{record.province + record.city + record.district}</div>
              <div className={`${styles['columns-span']}`}>{record.address}</div>
            </div>
          )
        }
      },
      {
        title: '商户信息',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return (
            <div className={'col'}>
              <div className={`${styles['columns-span']}`}>{record.shop_name}</div>
              <div className={`${styles['columns-span']} margin-t5`}>{this.removeT(record.submit_time)}</div>
              <div className={styles['columns1-date-span']}>(申请时间)</div>
              <div className={`${styles['columns-span']} margin-t20`}>申请人: {record.submitter_name}</div>
            </div>
          )
        }
      },
      {
        title: '师傅信息',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return (
            <div className={'col'}>
              <div className={`${styles['columns-span']}`}>{record.worker_name.split(',')[0]}</div>
              <div className={`${styles['columns-span']} margin-t5`}>{record.worker_name.split(',')[1]}</div>
              {record.worker_name !== '' && <div className={styles['columns1-date-span']}>(绑定手机)</div>}
            </div>
          )
        }
      },
      {
        title: '商户金额',
        dataIndex: 'price_app',
        align: 'center',
        width: 100,
      },
      {
        title: '师傅金额',
        dataIndex: 'price_oms',
        align: 'center',
        width: 100,
      },
      {
        title: '售后原因',
        dataIndex: 'content',
        align: 'center',
        width: 100,
      },
      {
        title: '处理时间',
        dataIndex: 'compete_time',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return (
            <div className={`${styles['columns-span']} margin-t5`}>
              {this.removeT(record.compete_time)}
            </div>
          )
        }
      },
      {
        title: '责任认定',
        dataIndex: 'duty_type',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return (
            <div className={'col'}>
              <div >{record.duty_type}</div>
              {record.duty_type === '' && <div className={styles['columns1-date-span']}>(待认定)</div>}
            </div>
          )
        }
      },
      {
        title: '平台备注',
        dataIndex: 'remark',
        align: 'center',
        width: 100,
      },
      {
        title: '回复商户',
        dataIndex: 'service_shop',
        align: 'center',
        width: 100,
      },
      {
        title: '回复师傅',
        dataIndex: 'service_worker',
        align: 'center',
        width: 100,
      },
      {
        title: '处理售后',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return (
            <div className={'row'}>
              {/* {record.state === '待处理' &&  */}
              <div className={styles['handle']} onClick={this.toDeal.bind(this, record.id)}>去处理</div>
              {/* } */}
            </div>
          )
        }
      },
    ];
    this.columns2 = [
      {
        title: '编号',
        align: 'center',
        width: 70,
        dataIndex: 'id',
      },
      {
        title: '订单信息',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return (
            <div className={'col'}>
              <div className={`${styles['columns-span']}`}>{record.order}-{record.shop_name}</div>
              <div className={`${styles['columns-span']}`}>{record.name}-{record.phone}</div>
              <div className={`${styles['columns-span']}`}>{record.province + record.city + record.district}</div>
              <div className={`${styles['columns-span']}`}>{record.address}</div>
              <div className={`${styles['columns3-type-span']} margin-t5 color2`}>【 {record.state} 】</div>
              <div className={`${styles['columns3-type-span']} margin-t5 color2`}>【 {record.order_type} 】</div>
            </div>
          )
        }
      },
      {
        title: '投诉人',
        align: 'center',
        width: 70,
        dataIndex: '',
        render: (text, record, index) => {
          return (
            <div>
              <div>{record.submitter_name}</div>
              <div>{moment(record.submit_time).format('YYYY/MM/DD-HH:mm:ss')}</div>
            </div>
          );
        }
      },
      {
        title: '投诉对象',
        align: 'center',
        width: 70,
        dataIndex: 'obj',
        render: (text, record, index) => {
          return (
            <div>
              <div>{record.order_type === '投诉师傅' ? record.worker_name : record.manager_name}</div>
            </div>
          );
        }
      },
      {
        title: '投诉内容',
        align: 'center',
        width: 140,
        dataIndex: 'content',
      },
      {
        title: '处理完成时间',
        align: 'center',
        width: 140,
        dataIndex: 'deal_time',
        render: (text, record, index) => {
          return (
            <div className='table-line'>
              {
                record.changes.map((item, index) => {
                  return (
                    <div key={index}>
                      <div>{item.name}</div>
                      <div>{moment(item.submit_time).format('YYYY/MM/DD-HH:mm:ss')}</div>
                    </div>
                  );
                })
              }
            </div>
          );
        }
      },
      {
        title: '处罚结果',
        align: 'center',
        width: 110,
        dataIndex: 'deal_content',
        render: (text, record, index) => {
          return (
            <div className='table-line'>
              {
                record.changes.map((item, index) => {
                  return (
                    <div key={index}>{item.content ? item.content : '无'}</div>
                  );
                })
              }
            </div>
          );
        }
      },
      {
        title: '操作',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return (
            <div className={'col'}>
              <div onClick={this.toDealComplain.bind(this, record)} className={`${styles['handle']} margin-b10`}>去处理</div>
              {
                record.order_type === '投诉师傅' &&
                <div onClick={this.toWorkComplain.bind(this, record)} className={`${styles['handle']} margin-b10`}>新增回访</div>
              }
            </div>
          )
        }
      },
    ];
    this.pageModal = React.createRef();
    this.indexModal = React.createRef();
    this.afterModal = React.createRef();
  }

  componentDidMount() {
    this.getDataList();
  }

  render() {
    const { search, data, download, workComplain } = this.state;
    return (
      <div>
        <Option title={typeList} top={0} changeMyTop={this.changeTop} className='margin-b10'>
          <div className='col-t vertical-lt padding25 back-color0'>
            <div className='row-l'>
              <span className='margin-r10'>处理状态:</span>
              <Radio.Group onChange={this.search.bind(this, 'radioType')} value={search.radioType}>
                <Radio value={'待处理'}>待处理</Radio>
                <Radio value={'已处理'}>已处理</Radio>
                <Radio value={'全部'}>全部</Radio>
              </Radio.Group>
              <div className='margin-r20'>时间范围:</div>
              <Select value={search.sort} onChange={this.search.bind(this, 'sort')}>
                <Select.Option value={'提交'}>提交时间</Select.Option>
                <Select.Option value={'处理'}>处理时间</Select.Option>
              </Select>
              <DatePicker.RangePicker value={[moment(search.start), moment(search.end)]}
                onChange={this.search.bind(this, 'times')} locale={locale} allowClear={false} />
              {
                ['申请理赔'].includes(search.type) &&
                <div className='row-l'>
                  <div className='margin-lr20'>师傅处理状态:</div>
                  <Select value={search.workt} onChange={this.search.bind(this, 'workt')} className='w120'>
                    {
                      ['待处理', '已处理', '全部'].map((item, index) => {
                        return (
                          <Select.Option value={item} key={index}>{item}</Select.Option>
                        );
                      })
                    }
                  </Select>
                  <div className='margin-lr20'>商户处理状态:</div>
                  <Select value={search.shopt} onChange={this.search.bind(this, 'shopt')} className='w120'>
                    {
                      ['待处理', '已处理', '全部'].map((item, index) => {
                        return (
                          <Select.Option value={item} key={index}>{item}</Select.Option>
                        );
                      })
                    }
                  </Select>
                </div>
              }
              {
                ['外部投诉', '内部投诉'].includes(search.type) &&
                <div className='row-l'>
                  <div className='margin-lr20'>与我相关:</div>
                  <Checkbox checked={search.myarea} onChange={this.search.bind(this, 'myarea')} />
                </div>
              }
            </div>
            <div className='row-sb margin-t10 w100'>
              <div className='row-l'>
                <span className='margin-r10'>客户信息:</span>

                <Input placeholder='客户信息' className='margin-r20 w295' value={search.inputValue} onChange={this.search.bind(this, 'inputValue')} />
                <Button type='primary' className='margin-l20' onClick={this.getDataList}>搜索</Button>
                <Button className='margin-l20 margin-r10' onClick={this.onResetClick}>重置</Button>
              </div>
              {
                ['外部投诉', '内部投诉'].includes(search.type) ?
                  <Button loading={download} onClick={this.toExcel2} type='primary'>表格导出</Button>
                  : <Button loading={download} onClick={this.toExcel} type='primary'>表格导出</Button>
              }
            </div>
          </div>
        </Option>
        <div className=' back-color0 margin-t10'>
          <Table
            columns={['外部投诉', '内部投诉'].includes(search.type) ? this.columns2 : this.columns}
            dataSource={data}
            size={'small'}
            rowKey={record => record.id}
            bordered
            pagination={false}
          />
          <PageTurning pageMaps={this.pageMaps} ref={this.pageModal} pageSize={search.take} />
        </div>
        <IndexModal
          ref={this.indexModal}
          updateTable={this.updateTable}
        />
        <AfterModal ref={this.afterModal} update={this.getDataList} />
        <Modal
          title='新增回访'
          visible={workComplain.visible}
          width={700}
          onCancel={this.hideComplain}
          okText='确认'
          cancelText='取消'
          onOk={this.newWorkComplain}
        >
          <div className='row-l vertical-lt margin-t20'>
            <div>说明：</div>
            <Input.TextArea onChange={this.changeComplain} value={workComplain.info} className='w500' rows={3} />
          </div>
        </Modal>
      </div>
    );
  }
  removeT = (e) => {
    let date = e.split('T');
    date = date.join(' ');
    return date
  }
  changeTop = (e) => {
    this.pageModal.current.returnFirst();
    let search = { ...this.state.search };
    search.type = typeList[e];
    search.skip = 0;
    this.setState({
      search
    }, () => this.getDataList())
  }
  updateTable = () => {
    this.getDataList()
  }
  //处理售后
  toDeal = (id) => {
    this.afterModal.current.onShowModal(id);
  }
  toDealComplain = (e) => {
    this.indexModal.current.onShowModal(e);

  }
  search = (type, e) => {
    let search = { ...this.state.search };
    switch (type) {
      case 'sort':
      case 'workt':
      case 'shopt':
        search[type] = e;
        break;
      case 'inputValue':
      case 'radioType':
        search[type] = e.target.value;
        break;
      case 'myarea':
        search[type] = e.target.checked;
        break;

      case 'times':
        search.start = moment(e[0]).format(dateForm);
        search.end = moment(e[1]).format(dateForm);
        break;
    }
    this.setState({
      search,
    })
  }
  toSearch = () => {
    this.pageModal.current.returnFirst();
    let search = { ...this.state.search };
    search.skip = 0;
    this.setState({
      search
    }, () => this.getDataList())
  }
  //重置
  onResetClick = () => {
    this.pageModal.current.returnFirst();
    this.setState({
      search: {
        type: '申请理赔',
        sort: '提交',
        inputValue: '',
        radioType: '待处理',
        start: month,
        end: today,
        workt: '全部',
        shopt: '全部',
        skip: 0,
        take: 10,
        myarea: true,
      },
    }, () => this.getDataList())
  }
  getDataList = async () => {
    let { search, } = this.state;
    let currentType = search.type === '外部投诉' ? '投诉师傅' : search.type === '内部投诉' ? '投诉客服' : search.type;
    let data = {
      info: search.inputValue,
      state: search.radioType,
      type: currentType,
      sort: search.sort,
      workt: search.workt,
      shopt: search.shopt,
      myarea: search.myarea,
      start: search.start,
      end: search.end,
      skip: search.skip,
      take: search.take,
    };
    let res = await getServiceList(data);
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    this.setState({
      data: res.data
    })
  }
  pageMaps = ({ skip, take }) => {
    let search = { ...this.state.search };
    search.skip = skip;
    this.setState({
      search,
    }, () => this.getDataList())
  }
  toExcel = async () => {
    let { search } = this.state;
    this.setState({
      download: true,
    })
    let currentType = search.type === '外部投诉' ? '投诉师傅' : search.type === '内部投诉' ? '投诉客服' : search.type;
    let down = true;
    let dataSource = [];
    let skip = 0;
    let take = 1000;
    let excelData = [];
    while (down === true) {
      let data = {
        info: search.inputValue,
        state: search.radioType,
        type: currentType,
        sort: search.sort,
        workt: search.workt,
        shopt: search.shopt,
        myarea: search.myarea,
        start: search.start,
        end: search.end,
        skip: skip,
        take: take,
      };
      let res = await getServiceList(data);
      if (res.status === 200) {
        dataSource = dataSource.concat(res.data)
        down = res.data.length < take ? false : true;
        skip = skip + res.data.length;
      } else {
        down = false;
      }
    }
    let title = [
      {
        title: '编号',
        key: 'id',
      },
      {
        title: '订单信息',
        key: 'orderInfo',
      },
      {
        title: '客户信息',
        key: 'shopInfo',
      },
      {
        title: '师傅信息',
        key: 'workInfo',
      },
      {
        title: '商户金额',
        key: 'price_app',
      },
      {
        title: '师傅金额',
        key: 'price_oms',
      },
      {
        title: '售后原因',
        key: 'content',
      },
      {
        title: '处理时间',
        key: 'compete_time',
      },
      {
        title: '责任认定',
        key: 'duty_type',
      },
      {
        title: '平台备注',
        key: 'remark',
      },
      {
        title: '回复商户',
        key: 'service_shop',
      },
      {
        title: '回复师傅',
        key: 'service_worker',
      },
    ];
    excelData = dataSource.map(item => {
      let time = this.removeT(item.submit_time);
      let single = {
        id: item.id,
        orderInfo: ` ${item.order} ${item.name}-${item.phone} 
          ${item.province + item.city + item.district + item.address}
          【 ${item.state} 】 【 ${item.order_type} 】`,
        shopInfo: `${item.shop_name}${time}(申请时间)申请人: ${item.submitter_name}`,
        workInfo: item.worker_name,
        price_app: item.price_app,
        price_oms: item.price_oms,
        content: item.content,
        compete_time: item.compete_time,
        duty_type: item.duty_type ? item.duty_type : '(待认定)',
        remark: item.remark,
        service_shop: item.service_shop,
        service_worker: item.service_worker,
      };
      return single;
    })
    let name = `${search.type}-${search.radioType}`
    jsToExcel2(title, excelData, name);
    this.setState({
      download: false
    });
  }
  toExcel2 = async () => {
    let { search } = this.state;
    this.setState({
      download: true,
    })
    let currentType = search.type === '外部投诉' ? '投诉师傅' : search.type === '内部投诉' ? '投诉客服' : search.type;
    let down = true;
    let dataSource = [];
    let skip = 0;
    let take = 1000;
    let excelData = [];
    while (down === true) {
      let data = {
        info: search.inputValue,
        state: search.radioType,
        type: currentType,
        sort: search.sort,
        workt: search.workt,
        shopt: search.shopt,
        myarea: search.myarea,
        start: search.start,
        end: search.end,
        skip: skip,
        take: take,
      };
      let res = await getServiceList(data);
      if (res.status === 200) {
        dataSource = dataSource.concat(res.data)
        down = res.data.length < take ? false : true;
        skip = skip + res.data.length;
        // } else {
        down = false;
      }
    }
    let title = [
      {
        title: '编号',
        key: 'id',
      },
      {
        title: '订单信息',
        key: 'orderInfo',
      },
      {
        title: '投诉人',
        key: 'submitter_name',
      },
      {
        title: '投诉对象',
        key: 'obj',
      },
      {
        title: '投诉内容',
        key: 'content',
      },
      {
        title: '处理完成时间',
        key: 'deal_time',
      },
      {
        title: '处罚结果',
        key: 'deal_content',
      },



    ];
    excelData = dataSource.map(item => {
      let time = this.removeT(item.submit_time);
      let single = {
        id: item.id,
        orderInfo: ` ${item.order} ${item.name}-${item.phone}
          ${item.province + item.city + item.district + item.address}
          【 ${item.state} 】 【 ${item.order_type} 】`,
        submitter_name: item.submitter_name + ',' + item.submit_time,
        obj: item.order_type === '投诉师傅' ? item.worker_name : item.manager_name,
        content: item.content,
        deal_time: item.changes.map(r => r.name + ':' + r.submit_time + '；'),
        deal_content: item.changes.map(r => r.content + '；'),
      };
      return single;
    })
    let name = `${search.type}-${search.radioType}`
    jsToExcel2(title, excelData, name);
    this.setState({
      download: false
    });
  }

  toWorkComplain = async (e) => {
    let workComplain = {
      visible: true,
      id: e.worker,
      info: '',
      order: e.order,
    };
    this.setState({
      workComplain,
    });
  }
  hideComplain = () => {
    this.setState({
      workComplain: {
        visible: false,
        id: 0,
        info: '',
        order: 0,
      },
    });

  }
  changeComplain = (e) => {
    let workComplain = { ...this.state.workComplain };
    workComplain.info = e.target.value;
    this.setState({
      workComplain,
    });
  }
  newWorkComplain = async () => {
    let workComplain = { ...this.state.workComplain };
    let res = await addMasterFollow(workComplain.order, workComplain.id, '回访', workComplain.info)
    if (res.status === 200) {
      this.hideComplain()
    }
  }
}

// export default Index;
export default connect(({ orderRenter }) => (orderRenter))(Index);