import React, { Component } from 'react';
import { Input, InputNumber, Modal } from 'antd';
import { postAddwidthdraw, putAdjworkerfin } from '../../../services/orderFinance'

class IndexModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            confirmLoading: false,
            type: '',
            price: 0,
            remark: ' ',
            id: 0
        }
    }
    render() {
        const { visible, type, confirmLoading, price } = this.state;
        return (
            <Modal
                title={`${type}`}
                visible={visible}
                onCancel={this.onCancel}
                onOk={this.onOk}
                okText='确定'
                cancelText='取消'
                confirmLoading={confirmLoading}
                width={600}
            >
                <div className='row-l'>
                    <div className='w60'>{type}金额:</div>
                    <InputNumber className={'w126 margin-l5'} onChange={this.onInputValue.bind(this, 'price')} value={price}/>
                </div>
                <div className='row-l vertical-lt margin-t10'>
                    <div className='w60 tr'>备注:</div>
                    <Input.TextArea rows={4} placeholder='请输入备注' className={'margin-l5 w486'} onBlur={this.onInputValue.bind(this, 'remark')}/>
                </div>
            </Modal>
        );
    }
    onInputValue=(type, e)=>{
        if(type === 'price'){
            this.setState({
                price: e
            })
        }else{
            this.setState({
                remark: e.target.value
            })
        }

    }
    onShowModal = (type, id) => {
        this.setState({
            type: type,
            id: id,
            visible: true
        });
    }
    onOk = async () => {
        this.setState({
            confirmLoading: true
        })
        const {id, type, price, remark} = this.state;
        let res;
        if(type === '调整'){
            res = await putAdjworkerfin(id, price, remark);
        }else{
            res = await postAddwidthdraw(id, {price,remark})
        }
        if (res.status !== 204) {
            message.error(res.data);
            return;
        }
        this.setState({
            confirmLoading: false
        });
        this.props.updateTable();
        this.onCancel();
    }
    onCancel = () => {
        this.setState({
            visible: false,
            price: 0,
            remark: ''
        })
    }
}

export default IndexModal;