import request from '../utils/request';

//扣减选项 
export async function getReduce(product, methed) {
  return await request(`data/reduce?product=${product}&methed=${methed}`, 'get');
}
export async function putReduce(data) {
  return await request('data/reduce', 'put', data);
}
export async function postReduce(data) {
  return await request('data/reduce', 'post', data);
}
export async function delReduce(id) {
  return await request(`data/reduce?id=${id}`, 'delete');
}
