import React, { Component } from 'react';
import { Button, message, Checkbox } from 'antd';
import TableList from '../tableList';
import { getOrderList, } from '../../../services/orderCore';
import PageTurning from '../../../components/pageTurning';
import styles from './index.module.css';

class TimeoutOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      skip: 0,
      take: 20,
      myCheckbox: true,
    }
  }
  componentDidMount() {
    this.getDataList();
  }
  render() {
    const { data, myCheckbox } = this.state;
    return (
      <div className='padding10 back-color0'>
        <div className='margin-l20 padding10'>
          <span className={styles['title_style']}>我的区域:</span>
          <Checkbox checked={myCheckbox} onChange={this.onCheckboxChange} />
        </div>
        <TableList setDisplay={this.setDisplay}
          data={data}
          updateTable={this.updateTables}
          delLabelData={this.delLabelData}
          updateTipData={this.getDataList}
          getDataList={this.getDataList}
        />
        <PageTurning pageMaps={this.pageMaps}
          pageSize={20}
          onHoverSet={this.onHoverSet}
        />
      </div>
    );
  }
  //我的区域
  onCheckboxChange = (e) => {
    this.setState({
      myCheckbox: e.target.checked
    }, async () => this.getDataList());
  }
  getDataList = async () => {
    let { startDates, endDates, skip, take, myCheckbox } = this.state;
    let res = await getOrderList({
      search: '超时',
      addition: '全部',
      info: '',
      category: '全部',
      service: '测量',
      type: '全部',
      state: '全部',
      start: startDates,
      end: endDates,
      worker: 0,
      shop: 0,
      user: 0,
      mode: '下单时间',
      myarea: myCheckbox,
      trans: '全部',
      skip,
      take,
    })
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }

    let data = res.data;
    data.forEach(item => {
      item.workerDisplay = false;
    })
    this.setState({
      data: data,
    })
  };
  setDisplay = (index) => {
    let data = _.cloneDeep(this.state.data);
    data[index].workerDisplay = !data[index].workerDisplay;
    this.setState({
      data: data
    })
  }
  //修改订单
  updateTables = (row) => {
    let data = [...this.state.data];
    let index = data.findIndex(r => r.id === row.id);
    data[index] = row;
    this.setState({
      data: data
    })
  }
  delLabelData = (index, indexs) => {
    let data = _.cloneDeep(this.state.data);
    data[index].serviceLabels.splice(indexs, 1);
    this.setState({
      data: data
    })
  }
  onHoverSet = (index, props, value) => {
    let data = [...this.state.data];
    data[index][props] = value;
    this.setState({
      data
    });
  }
  //换页
  pageMaps = ({ skip, take }) => {
    this.setState({
      skip: skip,
    }, async () => this.getDataList())
  }
}
export default TimeoutOrder;