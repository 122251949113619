import React, { Component } from 'react';
import { Input, Modal, Radio, message } from 'antd';
import { putWithdraw } from '../../../services/orderFinance';

class IndexModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            confirmLoading: false,
            remark: ' ',
            id: 0,
            type: '已支付'
        }
    }
    render() {
        const { visible, confirmLoading, type } = this.state;
        return (
            <Modal
                title={'处理提现'}
                visible={visible}
                onCancel={this.onCancel}
                onOk={this.onOk}
                okText='确定'
                cancelText='取消'
                confirmLoading={confirmLoading}
                width={600}
            >
                <div className='row-l vertical-lt margin-t10'>
                    <div className='w60 tr margin-r5'>处理状态:</div>
                    <Radio.Group value={type} onChange={this.onInputValue.bind(this, 'radio')}>
                        <Radio value={'已支付'}>已支付</Radio>
                        <Radio value={'已作废'}>已作废</Radio>
                    </Radio.Group>
                </div>
                <div className='row-l vertical-lt margin-t10'>
                    <div className='w60 tr'>备注:</div>
                    <Input.TextArea rows={4} placeholder='请输入备注' className={'margin-l5 w486'} onBlur={this.onInputValue.bind(this, 'remark')} />
                </div>
            </Modal>
        );
    }
    onInputValue = (type, e) => {
        if (type === 'radio') {
            this.setState({
                type: e.target.value
            })
        } else {
            this.setState({
                remark: e.target.value
            })
        }

    }
    onShowModal = (id) => {
        this.setState({
            id: id,
            visible: true
        });
    }
    onOk = async () => {
        this.setState({
            confirmLoading: true
        })
        let {id, type, remark} = this.state;
        let res = await putWithdraw(id, type, remark);
        if(res.status !== 204){
            message.error(res.data);
            return;
        }
        this.setState({
            confirmLoading: false
        })
        this.props.updateTable(id, type);
        this.onCancel()
    }
    onCancel = () => {
        this.setState({
            visible: false
        })
    }
}

export default IndexModal;