import React, { Component } from 'react';
import { Modal, Button, InputNumber, Radio, Input, message } from 'antd';
import { putShopTransfer } from '../../services/shopManagement';

const status = [
  {
    title: '已转化',
    value: true,
  },
  {
    title: '未转化',
    value: false,
  },
];
class TransferInfoModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      info: {
        isTrans: true,
        transId: null,
        remark: '',
      },
    };
  }
  render() {
    const { visible, info } = this.state;
    return (
      <Modal
        title={'转化结果'}
        width={700}
        visible={visible}
        onCancel={this.onCancel}
        cancelText='取消'
        okText='确认'
        onOk={this.onOk}
      >
        <div className='row-l margin-b10'>
          <div className='w80'>转化结果：</div>
          <Radio.Group value={info.isTrans} onChange={this.changeSum.bind(this, 'isTrans')}>
            {
              status.map((item, index) => {
                return (
                  <Radio value={item.value} key={index}>{item.title}</Radio>
                );
              })
            }
          </Radio.Group>
        </div>
        {
          info.isTrans &&
          <div className='row-l margin-b10'>
            <div className='w80'>关联订单：</div>
            <InputNumber value={info.transId} onChange={this.changeSum.bind(this, 'transId')} className='w200' />
          </div>
        }
        <div className='row-l vertical-lt'>
          <div className='w80'>说明：</div>
          <Input.TextArea value={info.remark} onChange={this.changeSum.bind(this, 'remark')} rows={3} className='w400' />
        </div>
      </Modal>
    )
  }
  changeSum = (type, e) => {
    let info = { ...this.state.info };
    if (type === 'isTrans' || type === 'remark') {
      info[type] = e.target.value;
    } else {
      info[type] = e ? e : null;
    }
    this.setState({
      info,
    });
  }
  onOk = async () => {
    const { currentOrder, info } = this.state;
    if (info.isTrans && info.transId === null) {
      message.warn('关联订单不能为空');
      return;
    }
    let data = {
      id: currentOrder.id,
      isTrans: info.isTrans,
      transId: info.isTrans ? info.transId : 0,
      remark: info.remark,
    };
    let res = await putShopTransfer(data);
    if (res.status === 204) {
      this.props.update();
      this.onCancel();
    }
  }
  onShow = (e) => {
    this.setState({
      visible: true,
      currentOrder: e,
    });
  }
  onCancel = () => {
    this.setState({
      visible: false,
      currentOrder: {},
      info: {
        isTrans: true,
        transId: null,
        remark: '',
      },
    });
  }
}
export default TransferInfoModal;