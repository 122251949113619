import { Checkbox, message, Radio, Table, Modal, Input, Button, Select } from 'antd';
import React, { Component } from 'react';


class DetailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      records: [],
    };
  }
  render() {
    const { visible, records } = this.state;
    return (
      <Modal
        title={'跟进记录'}
        width={800}
        visible={visible}
        onCancel={this.onCancel}
        cancelText='取消'
        footer={
          <div className='w100 row-r'>
            <Button onClick={this.onCancel} type='primary'>关闭</Button>
          </div>
        }
      >
        <div></div>
        <div className='col-t vertical-lt'>
          {
            records.map((item, index) => {
              return (
                <div className='margin-b10' key={index}>{index + 1}：{item}</div>
              );
            })
          }
        </div>
      </Modal>
    );
  }
  onShow = (e) => {
    let records = e.split(';');
    records = records.filter(r => r);
    this.setState({
      visible: true,
      records
    })
  }
  onCancel = () => {
    this.setState({
      visible: false, records: [],
    })
  }
}
export default DetailModal;