import request from '../utils/request';
//标签列表
export async function getLabel(enable, type, skip, take) {
  return await request(`data/labellist?enable=${enable}&type=${type}&skip=${skip}&take=${take}`, 'get');
}

//新增标签
export async function addLabel(name, type) {
  return await request(`data/addlabel?name=${name}&type=${type}`, 'put');
}
//修改标签
export async function editLabel(id, enable, name) {
  return await request(`data/mdylabel?id=${id}&enable=${enable}&name=${name}`, 'put');
}

//师傅标签
export async function getMasterLabel() {
  return await request('data/workerlabels', 'get');
}