import React, { useState, Component } from 'react';
import styles from './index.module.css';

const numberList = ['①', '②', '③', '④', '⑤', '⑥', '⑦', '⑧', '⑨', '⑩'];
class Option extends Component {
  constructor(props) {
    super(props);
    this.state = {
      option: 0,
    };
  }
  render() {
    const { option } = this.state;
    const { title, children, amount, number, style } = this.props;
    return (
      <div className='w100 col-t'>
        <div className={`${styles['title']} row-l w100`}>
          {
            title.map((item, index) => {
              return <div onClick={this.onChangeTop.bind(this, index)}
                key={index} className={`${styles['menu']} ${index === option ? styles[`menu-select${style ? style : 1}`] : ''} tc fz15`}>
                {number ? numberList[index] : ''}{item}{amount && amount[index] ? '（' + amount[index] + '）' : ''}
              </div>;
            })
          }
        </div>
        <div className={`${styles['menu-content']} w100`}>
          {children}
        </div>
      </div>
    );
  }
  outSideChange = (e) => {
    this.setState({
      option: e,
    })
  }
  onChangeTop = (e) => {
    const { changeMyTop } = this.props;
    if (changeMyTop && typeof changeMyTop === 'function') {
      changeMyTop(e)
      this.setState({
        option: e,
      })
    }
  }
  returnFirst = () => {
    this.setState({
      option: 0,
    })
  }

}

export default Option;