import request from '../utils/request';
//任务总览
export async function getTaskbase() {
  return await request('platbiz/taskbase', 'get');
}
//任务数据
export async function getTaskList({ label, service, state, mytask, info, start, end, skip, take }) {
  return await request(`platbiz/tasklist?label=${label}&service=${service}&state=${state}&mytask=${mytask}&info=${info}&start=${start}&end=${end}&skip=${skip}&take=${take}`, 'get');
}
//提交任务

export async function putTask(id, remark, state, checker) {
  return await request(`platbiz/dealtask?id=${id}&remark=${remark}&state=${state}&checker=${checker}`, 'put');
}