import React, { Component } from 'react';
import { message, Table, Input, Button, InputNumber, Popconfirm, DatePicker } from 'antd';
import Search from '../../../components/financeSearch';
import PageTurning from '../../../components/pageTurning';
import { getCollectlist, putCollect } from '../../../services/orderFinance';
import styles from './index.module.css';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const dateFormat = 'YYYY-MM-DD';
moment.locale('zh-cn');

let endDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toLocaleDateString().split('/').join('-');
let startDate = new Date(new Date() - 30 * 24 * 60 * 60 * 1000).toLocaleDateString().split('/').join('-');

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      skip: 0,
      take: 10,
      searchType: '',
      startDates: startDate,
      endDates: endDate,
      orderId: 0,
      pageIndex: 1,
    }
    this.pageSize = 10;
    this.columns = [
      {
        title: '客户信息',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return (
            <div>
              <div className='tl'>
                <div className={`${styles['columns-span']}`}><span className='margin-r20'>{record.name}</span>{record.phone}</div>
                <div className={`${styles['columns-span']} margin-t5`}>{record.province + record.city + record.district + record.address}</div>
              </div>
              <div className='row w100'><div className={styles['columns2-copy-button']} onClick={this.onCopyToClip.bind(this, record)}>复制客户信息</div></div>
            </div>
          )
        }
      },
      {
        title: '订单信息',
        align: 'center',
        width: 100,
        // dataIndex: 'price',
        render: (text, record, index) => {
          return (
            <div>
              <div className={`${styles['columns-span']}`}>{record.id}</div>
              <div className={`${styles['left-top-type']}`}><div className={styles['left-top-type-span']}>{record.service}</div></div>
              <div className={`${styles['columns-span']} margin-t5`}>{record.category + '-'}<span className='fw600 color4'>{record.type}</span></div>
              <div className={`${styles['columns3-type-span']} margin-t5 color2`}>【 {record.state} 】</div>
            </div>
          )
        }
      },
      {
        title: '商户信息',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return (
            <div className=''>
              <div className={`${styles['columns-span']} `}>{this.removeT(record.create_time)}</div>
              <div className={styles['columns1-date-span']}>(下单时间)</div>
              <div className={`${styles['columns-span']} margin-t5`}>下单人: {record.operater}</div>
            </div>
          )
        }
      },
      {
        title: '商户费用',
        align: 'center',
        width: 100,
        dataIndex: 'price_app',
        render: (text, record, index) => {
          return (
            <div className='row'>
              <div className={`${styles['columns-span']} `}>{text}</div>
            </div>
          )
        }
      },
      {
        title: '师傅姓名',
        align: 'center',
        width: 100,
        dataIndex: 'wname',
        render: (text, record, index) => {
          return (
            <div className='row'>
              <div className={`${styles['columns-span']} `}>{text}</div>
            </div>
          )
        }
      },
      {
        title: '师傅手机',
        align: 'center',
        width: 100,
        dataIndex: 'uid',
        render: (text, record, index) => {
          return (
            <div className='row'>
              <div className={`${styles['columns-span']} `}>{text}</div>
            </div>
          )
        }
      },
      {
        title: '师傅费用',
        align: 'center',
        width: 100,
        dataIndex: 'price_oms',
        render: (text, record, index) => {
          return (
            <div className='row'>
              <div className={`${styles['columns-span']} `}>{text}</div>
            </div>
          )
        }
      },
      {
        title: '完成时间',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return (
            <div className='row'>
              <div className={`${styles['columns-span']} `}>{this.removeT(record.service_time)}</div>
            </div>
          )
        }
      },
      {
        title: '订单操作',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return (
            <div className='row'>
              <Popconfirm
                title='是否确认收款'
                onConfirm={this.confirm.bind(this, record.id)}
                okText='确认'
                cancelText='返回'
              >
                <div className={`${styles['button']}`} >确认收款</div>
              </Popconfirm>
            </div>
          )
        }
      },
    ]
  }

  componentDidMount() {
    this.onSearch('')
  }

  render() {
    const { startDates, endDates, data, orderId, searchType } = this.state;
    return (
      <div>
        <div className='col back-color0 w100 padding20 vertical-lt'>
          <div className='row-l'>
            <div>师傅信息:</div>
            <Input placeholder={'请输入姓名或手机号码'} className={'margin-l10 margin-r20 w295'} value={searchType} onChange={this.onInputValue} />
            <span className={`${styles['title_style']}`}>订单编号:</span>
            <InputNumber className='w198 margin-l10 margin-r20' value={orderId} placeholder={'请输入订单编号'} onChange={this.onOrderID} />
            <Button type='primary' className={'margin-l20 margin-r20'} onClick={this.onSearch}>搜索</Button>
            <Button onClick={this.onReset}>重置</Button>
          </div>
          <div className='row-l margin-t10'>
            <div className='margin-r10'>打款日期:</div>
            <DatePicker placeholder='开始时间' className='w198' value={moment(startDates, dateFormat)} onChange={this.onChangeDate.bind(this, 'start')} locale={locale} />
            <div className='margin-l5 margin-r5'>至</div>
            <DatePicker placeholder='结束时间' className='w198' value={moment(endDates, dateFormat)} onChange={this.onChangeDate.bind(this, 'end')} locale={locale} />
          </div>
        </div>
        <div className='margin-t10 back-color0'>
          <Table
            columns={this.columns}
            dataSource={data}
            size={'small'}
            rowKey={record => record.id}
            bordered
            pagination={false}
          />
          {/* <PageTurning pageMaps={this.pageMaps} /> */}
          <div className={'row-r padding10'}>
            <div className={`${styles['pageStyle']} margin-r10`} onClick={this.changePageIndex.bind(this, '-')} ><LeftOutlined />上一页</div>
            <div className={`${styles['pageStyle']} `} onClick={this.changePageIndex.bind(this, '+')} >下一页<RightOutlined /></div>
            <div className='row-l margin-l10 fz18'>
              向第
              {
                this.state.pageEdit ?
                  <input className={`${styles['width-50']} margin-r5 margin-l5`}
                    onFocus={this.inputOnFocus}
                    onBlur={this.inputOnBlur}
                    onChange={this.onChange}
                    value={this.state.pageIndex}
                  />
                  :
                  <div className={`${styles['width-50']} ${styles['inline']} margin-r5 margin-l5`} onClick={this.onChangeDiv}>{this.state.pageIndex}</div>
              }
              页跳转
            </div>
          </div>
        </div>
      </div>

    );
  }
  onInputValue = (e) => {
    this.setState({
      searchType: e.target.value,
      pageIndex: 1,
    })
  }
  onReset = () => {
    this.setState({
      searchType: '',
      pageIndex: 1,
      startDates: startDate,
      endDates: endDate,
    }, async () => this.onSearch(''))
  }
  confirm = async (id) => {
    let res = await putCollect(id);
    if (res.status !== 204) {
      message.error(res.data);
      return;
    }
    let data = _.cloneDeep(this.state.data);
    let index = data.findIndex(r => r.id === id);
    data.splice(index, 1);
    this.setState({
      data: data
    })
    message.success('收款完成');
  }
  onOrderID = (e) => {
    this.setState({
      orderId: e,
      pageIndex: 1,
    }, async () => this.onSearch())
  }
  onSearch = async () => {
    let { searchType, skip, take, orderId, startDates, endDates } = this.state;
    let res = await getCollectlist({ info: searchType, order: orderId, start: startDates, end: endDates, ...this.pageMap() });
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    this.setState({
      data: res.data,
    })
  }

  onChangeDate = (type, dates, dateStrings) => {
    if (type === 'start') {
      this.setState({
        startDates: dateStrings
      })
    } else if (type === 'end') {
      this.setState({
        endDates: dateStrings
      })
    } else if (type === '重置') {
      this.setState({
        startDates: startDate,
        endDates: endDate,
        searchType: '',
        orderId: 0,
      })
    }
  }
  //换页
  // pageMaps = ({ skip, take, pageIndex }) => {
  //   this.setState({
  //     skip: skip,
  //     pageIndex: pageIndex
  //   }, async () => this.getDataList())
  // }

  //换页
  onChangeDiv = () => {
    this.setState({
      pageEdit: true,
    });
  }

  pageMap = () => {
    return ({
      skip: (this.state.pageIndex - 1) * this.pageSize,
      take: this.pageSize
    });
  }

  onChange = (e) => {
    let value = e.target.value;
    if (value === '') {
      this.setState({
        pageIndex: ''
      });
      return;
    }
    if (isNaN(value)) {
      return;
    } else {
      let number = Number(value);
      if (number < 1 || number % 1 !== 0) {
        return;
      }
    }
    this.setState({
      pageIndex: Number(e.target.value)
    });
  }

  inputOnBlur = (e) => {
    this.setState({
      pageEdit: false,
    });
    if (this.state.pageIndex === '') {
      this.setState({
        pageIndex: this.tempPageIndex,
      });
      return;
    }
    this.onSearch('');
  }

  inputOnFocus = (e) => {
    this.tempPageIndex = e.target.value;
  }

  changePageIndex = async (type) => {
    let pageIndex = _.cloneDeep(this.state.pageIndex);
    if (type === '-' && pageIndex > 1) {
      pageIndex--;
      await this.setState({
        pageIndex: pageIndex
      })
    } else if (type === '+') {
      pageIndex++;
      await this.setState({
        pageIndex: pageIndex
      })
    } else {
      message.warn('当前为第一页')
    }
    this.onSearch('');
  }

  removeT = (e) => {
    let date = e.split('T');
    date = date.join(' ');
    return date
  }
  //复制
  onCopyToClip = (data) => {
    var aux = document.createElement("textarea");
    aux.value = `${'姓名:' + data.name + '\n' + '手机号:' + data.phone + '\n' + '地址:' + data.province + data.city + data.district + data.address
      }`;
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);
    message.success('复制成功');
  }
}

export default Index;