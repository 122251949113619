import React, { Component } from 'react';
import styles from './index.module.css';
import { message, Table, Input, Radio, Image, Select, Modal, Button, DatePicker } from 'antd';
import { getBusinessDevelopList } from '../../services/analysisCenter';
import * as echarts from 'echarts';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/zh_CN';
import 'moment/locale/zh-cn';

moment.locale('zh-cn');
const dateFormat = 'YYYY-MM-DD'

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchInfo: {
        start: '',
        end: '',
      },
      source: [],
      baseDate: {
        devShop: [],
        devWorker: [],
        visitShop: [],
      },
      quickBtn: 0,
      count: [0, 0],
    };
    this.columsShop = [
      {
        title: '注册时间',
        align: 'center',
        width: 200,
        dataIndex: 'join_time',
        render: (text, record, index) => {
          return <div>{moment(record.join_time).format(dateFormat)}</div>;
        },
        sorter: {
          compare: (a, b) => new Date(b.join_time) - new Date(a.join_time)
        },
      },
      {
        title: '商家名称',
        align: 'center',
        width: 300,
        dataIndex: 'name',
      },
    ];
    this.columsMaster = [
      {
        title: '注册时间',
        align: 'center',
        width: 200,
        dataIndex: 'join_time',
        render: (text, record, index) => {
          return <div>{moment(record.join_time).format(dateFormat)}</div>;
        },
        sorter: {
          compare: (a, b) => new Date(b.join_time) - new Date(a.join_time)
        },
      },
      {
        title: '师傅姓名',
        align: 'center',
        width: 300,
        dataIndex: 'name',
      },
    ];
    this.columsFollow = [
      {
        title: '商家名称',
        align: 'center',
        width: 200,
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '回访次数',
        align: 'center',
        width: 300,
        dataIndex: 'times',
        key: 'times',
      },
    ];
  }
  componentDidMount() {
    this.reset()
  }
  render() {
    const { option, source, searchInfo, quickBtn, count, baseDate, } = this.state;
    return (
      <div className='col-t w100'>
        <div className='col-t vertical-lt padding20 back-color0 w100'>
          <div className='row-sb w100'>
            <div className='row-l'>
              <div>下单时间：</div>
              <DatePicker onChange={this.changeSummary.bind(this, 'start')} value={searchInfo.start} locale={locale} format={dateFormat} allowClear={false} />
              <div className='margin-l10 margin-r10'>至</div>
              <DatePicker onChange={this.changeSummary.bind(this, 'end')} value={searchInfo.end} locale={locale} format={dateFormat} allowClear={false} />
              <Button onClick={this.search} type='primary' className='margin-l20'>搜索</Button>
            </div>
            <Button onClick={this.reset} type='primary' className={`back-color0 color1 ${styles['reset']}`}>重置</Button>
          </div>
        </div>
        <div className='back-color0 w100 margin-t20 padding20' >
          <div className='row-sb w100'>
            <div className='row-l'>
              <div className='fz18 bold'>每日业务统计</div>
              <div className={`${styles['selectDate']} color7`}>快速选择：</div>
              <div onClick={this.quickSelect.bind(this, 7)} className={quickBtn === 7 ? styles['quickly-select'] : styles['quickly']}>近7天</div>
              <div onClick={this.quickSelect.bind(this, 15)} className={quickBtn === 15 ? styles['quickly-select'] : styles['quickly']}>近15天</div>
              <div onClick={this.quickSelect.bind(this, 31)} className={quickBtn === 31 ? styles['quickly-select'] : styles['quickly']}>近31天</div>
            </div>
            <div className='row-r'>
              <div className='color7'>汇总：</div>
              <div className={`${styles['summary-finance']} row`}>
                <div className='bold'>{count[0]}</div>
                <div className='color5 margin-r5'> （商户）|</div>
                <div className='bold'>{count[1]}</div>
                <div className='color5 margin-r5'> （师傅）</div>
              </div>
            </div>
          </div>
          <div>
            <div className={`${styles['count']}`} id='main'
            ></div>
          </div>
          <div className='w100 row margin-t20'>
            <div className={styles['box1']} />
            <div>新商户</div>
            <div className={styles['box4']} />
            <div>新师傅</div>
          </div>
        </div>
        <div className='back-color0 w100 margin-t20 padding20 row-sb'>
          <div className='h480'>
            <Table
              columns={this.columsShop}
              dataSource={baseDate.devShop}
              size={'small'}
              rowKey={record => record.id}
              bordered
            />
          </div>
          <div className='h480'>
            <Table
              columns={this.columsMaster}
              dataSource={baseDate.devWorker}
              size={'small'}
              rowKey={record => record.id}
              bordered
            />
          </div>
          <div className='h480'>
            <Table
              columns={this.columsFollow}
              dataSource={baseDate.visitShop}
              size={'small'}
              rowKey={record => record.id}
              bordered
            />
          </div>
        </div>
      </div >
    );

  }
  create = () => {
    let option = {
      color: ['#F41212', '#B400F2'],
      tooltip: {
        trigger: 'axis',
        show: true,
        axisPointer: {
          type: 'line'
        }
      },
      xAxis: {
        type: 'category',
        axisTick: {
          alignWithLabel: true
        },
      },
      yAxis: {
        type: 'value',
        max: 100
        // max: this.state.count[0] > this.state.count[1] ? this.state.count[0] : this.state.count[1]
      },
      grid: {
        left: '1%',
        right: '1%',
        bottom: '1%',
        containLabel: true
      },
      series: [
        {
          type: 'bar',
          barWidth: '25%',
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'top',
                textStyle: {
                  color: '#F41212',
                  fontsize: 16
                }
              }
            }
          },
        },
        {
          type: 'bar',
          barWidth: '25%',
          itemStyle: {
            normal: {
              label: {
                show: true,
                position: 'top',
                textStyle: {
                  color: '#B400F2',
                  fontsize: 16
                }
              }
            }
          }
        },
      ],
      dataset: {
        dimensions: ['amount', '新商家', '新师傅'],
        source: this.state.source
      },
      dataZoom: [{
        type: 'slider',//给x轴设置滚动条
        xAxisIndex: 0,
        bottom: 0,
        height: 7,
        showDetail: false,
        startValue: 0,//滚动条的起始位置
        endValue: 31 //滚动条的截止位置（按比例分割你的柱状图x轴长度）
      },]
    };
    let chartDom = document.getElementById('main');
    let myChart = echarts.init(chartDom);
    myChart.setOption(option)
  }
  getData = async () => {
    const { searchInfo } = this.state;
    let start = moment(searchInfo.start).format(dateFormat)
    let end = moment(searchInfo.end).format(dateFormat)
    let days = searchInfo.end.diff(searchInfo.start, 'day') + 1;
    let source = [];
    let count = [0, 0];
    let res = await getBusinessDevelopList(start, end);
    if (res.status === 200) {
      for (let i = 0; i < days; i++) {
        let tomorrow = moment(start).add(i, 'd').format('MM-DD')
        let newDay = {
          amount: tomorrow,
          '新商家': 0,
          '新师傅': 0,
        }
        res.data.devShop.map(item => {
          let time = moment(item.join_time).format('MM-DD');
          if (newDay.amount === time) {
            newDay['新商家'] += 1
          }
          return;
        })
        res.data.devWorker.map(item => {
          let time = moment(item.join_time).format('MM-DD');
          if (newDay.amount === time) {
            newDay['新师傅'] += 1
          }
          return;
        })
        count[0] += newDay['新商家'];
        count[1] += newDay['新师傅'];
        source.push(newDay);
      }
      this.setState({
        source, count,
        baseDate: res.data,
      }, () => this.create())
    }
  }
  changeSummary = (type, e) => {
    let searchInfo = { ...this.state.searchInfo };
    switch (type) {
      case 'start':
        searchInfo.start = e;
        this.setState({
          searchInfo
        }); break;
      case 'end':
        searchInfo.end = e;
        this.setState({
          searchInfo
        }); break;
    }
  }
  search = () => {
    this.setState({
      quickBtn: 0
    }, () => this.getData())
  }
  quickSelect = (e) => {
    let searchInfo = {
      end: moment(new Date()),
      start: moment(new Date()).subtract(e, 'days'),
    }
    this.setState({
      quickBtn: e,
      searchInfo,
    }, () => this.getData())
  }
  reset = () => {
    let searchInfo = {
      end: moment(new Date()),
      start: moment(new Date()).subtract(31, 'days'),
    }
    this.setState({
      searchInfo,
      quickBtn: 0,
    }, () => this.getData());
  }
}

export default Index;