import React, { Component } from 'react';
import { Button, Modal, Radio, Table, Input } from 'antd';
import PageTurning from '../../../components/pageTurning';
import { getAppeal, postAppeal } from '../../../services/orderCore.js';
import moment from 'moment';

const types = ['回访', '投诉', '培训', '评价'];
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      record: {},
      modal: false,
      addModal: {
        visible: false,
        type: '回访',
        remark: '',
      },
    }
    this.pageModal = React.createRef();
  }
  render() {
    const { data, modal, addModal, } = this.state;
    const columsFollow = [
      {
        title: '编号',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return index + 1;
        }
      },
      {
        title: '客服',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
        width: 120,
      },
      {
        title: '类型',
        dataIndex: 'type',
        key: 'type',
        align: 'center',
        width: 100,
      },
      {
        title: '时间',
        dataIndex: 'submit_time',
        key: 'submit_time',
        align: 'center',
        width: 150,
        render: (text, record, index) => {
          return (<div >{moment(record.submit_time).format('YYYY-MM-DD HH:mm')}</div>)
        }
      },
      {
        title: '内容',
        dataIndex: 'content',
        key: 'content',
        align: 'center',
        width: 300,
      },
      {
        title: '关联订单',
        dataIndex: 'order',
        key: 'order',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return (<div>{record.type === '培训' ? '/' : record.order}</div>)
        }
      },
      {
        title: '关联客户信息',
        dataIndex: 'info',
        key: 'order',
        align: 'center',
        width: 300,
        className: 'padding0',
        render: (text, record, index) => {
          return (
            <div>
              {
                record.order ?
                  <div>
                    <div>{record.cname}-{record.phone}</div>
                    <div>{record.province}{record.city}{record.district}{record.address}</div>
                  </div>
                  : <div></div>
              }
            </div>
          );
        }
      },
    ];
    return (
      <Modal
        title='关联记录'
        visible={modal}
        width={1000}
        onCancel={this.hideModal}
        footer={null}
      >
        <div className='w100 margin-t10 back-color0 '>
          <div className='row-l margin-b10'>
            <Button onClick={this.newRecord} type='primary'>新增记录</Button>
          </div>
          <Table
            rowKey={record => record.id}
            dataSource={data}
            columns={columsFollow}
            bordered
          />
        </div>
        <Modal
          title='新增记录'
          visible={addModal.visible}
          width={500}
          onCancel={this.onCancel}
          footer={
            <div>
              <Button onClick={this.onCancel} type='ghost'>取消</Button>
              <Button onClick={this.addRecord} type='primary'>确认</Button>
            </div>
          }
        >
          <div className='row-l'>
            <div className='w60'>类型：</div>
            <Radio.Group value={addModal.type} onChange={this.changeType}>
              {
                types.map((item, index) => {
                  return (
                    <Radio value={item} key={index}>{item}</Radio>
                  );
                })
              }
            </Radio.Group>
          </div>
          <div className='row-l margin-t10'>
            <div className='w60'>说明：</div>
            <Input value={addModal.remark} className='w300' onChange={this.changeRemark} />
          </div>
        </Modal>
      </Modal>
    );
  }
  onShowModal = (e) => {
    this.setState({
      record: e,
      modal: true,
    }, () => this.getcomplaints())
  }
  getcomplaints = async () => {
    const { record, } = this.state;
    let res = await getAppeal(record.id, record.workerId,);
    if (res.status === 200) {
      this.setState({
        data: res.data
      })
    }
  }
  hideModal = () => {
    this.setState({
      modal: false,
      data: [],
      skip: 0,
    })
  }

  newRecord = () => {
    let addModal = { ...this.state.addModal };
    addModal.visible = true;
    this.setState({
      addModal,
    });
  }
  changeType = (e) => {
    let addModal = { ...this.state.addModal };
    addModal.type = e.target.value;
    this.setState({
      addModal,
    });
  }
  changeRemark = (e) => {
    let addModal = { ...this.state.addModal };
    addModal.remark = e.target.value;
    this.setState({
      addModal,
    });
  }
  addRecord = async () => {
    const { addModal, record } = this.state;
    let res = await postAppeal(record.id, record.workerId, addModal.type, addModal.remark);
    if (res.status === 200) {
      this.getcomplaints();
      this.onCancel();
    }
  }
  onCancel = () => {
    this.setState({
      addModal: {
        visible: false,
        type: '回访',
        remark: '',
      },
    });
  }
}

export default Index;